import { Injectable } from '@angular/core';

@Injectable()
export class AppGlobals {
  /* LOCAL */
  // public static get baseURL(): string { return ''; }
  //	public static get url(): string { return 'http://localhost:4200'; }

  /** Server */
  public static get baseURL(): string {
    return 'https://on.coffeemk.mx/rest/index.php/';
  }
  public static get url(): string {
    return 'https://organicnails.mx';
  }
}
