import { Component, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';

import { Usuarios } from '../models/usuarios';
import { Usuario } from '../models/usuario';
import { LoginService } from '../services/login.service';
import { UsuariosService } from '../services/usuarios.service';

@Component({
	selector: 'usuarios',
	templateUrl: './usuarios.component.html',
	styleUrls: ['./usuarios.component.css'],
	providers: [LoginService, UsuariosService, Md5]

})
export class UsuariosComponent {
	public usuarioName: string;
	public usuarios: Usuarios;
	public usuario: Usuario;
	public roles;
	public emailPattern = "^[_a-z0-9-]+(.[_a-z0-9-]+)*@[a-z0-9-]+(.[a-z0-9-]+)*(.[a-z]{2,4})$";
	public dtOptions: any = {};
	public baseURL = "https://on.coffeemk.mx/rest/index.php/";

	constructor(
		private _loginService: LoginService,
		private _usuariosService: UsuariosService,
		private _route: ActivatedRoute,
		private _router: Router,
		private titleService: Title,
		private _md5: Md5
	) {
		this.usuarios = new Usuarios("", "", "", "");
		this.usuario = new Usuario("", "", "", "", "", "");
	}
	ngOnInit() {
		this.titleService.setTitle('Usuarios - Organic Nails');
		this._route.params.forEach((params: Params) => {
			let parametro = params['page'];
			this._loginService.validate(parametro).subscribe(
				result => {
					if (result == "false") {
						this._router.navigate(['/login']);
					}
				},
				error => {
					var errorMessage = <any>error;
					console.log(errorMessage);
				}
			);
		});
		this._usuariosService.getRoles().subscribe(
			result => {
				if (result) {
					this.roles = result;
				}
			},
			error => {
				var errorMessage = <any>error;
				console.log(errorMessage);
			}
		);
		this.dtOptions = {
			ajax: this.baseURL + '/Usuario/getUsuarios',
			columns: [
				{ title: 'ID', data: 'id', visible: false },
				{ title: 'Nombre', data: 'nombre' },
				{ title: 'Email', data: 'email' },
				{ title: 'Rol', data: 'rol' },
				{
					title: 'Status',
					data: 'status',
					mRender: function (data, type, full) {
						if (data == 1) {
							return '<span id="status"><i class="far fa-check-circle"></i></span>';
						} else {
							return '<span id="status" (click)="changeStatus()"><i class="far fa-circle"></i></span>';
						}
					}
				},
				{
					title: 'Acciones',
					data: 'email',
					bSortable: false,
					mRender: function (data, type, full) {
						return '<span class="accion" id="editar"  data-toggle="modal" data-target="#modalEditar"><i class="fa fa-pencil-alt"></i></span> <span class="accion" id="eliminar" data-toggle="modal" data-target="#modalDelete"><i class="fa fa-trash-alt"></i></span>';
					}
				}
			],
			language: {
				"sProcessing": "Procesando...",
				"sLengthMenu": "Mostrar _MENU_ registros",
				"sZeroRecords": "No se encontraron resultados",
				"sEmptyTable": "Ningún dato disponible en esta tabla",
				"sInfo": "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
				"sInfoEmpty": "Mostrando registros del 0 al 0 de un total de 0 registros",
				"sInfoFiltered": "(filtrado de un total de _MAX_ registros)",
				"sInfoPostFix": "",
				"sSearch": "Buscar:",
				"sUrl": "",
				"sInfoThousands": ",",
				"sLoadingRecords": "Cargando...",
				"oPaginate": {
					"sFirst": "Primero",
					"sLast": "Último",
					"sNext": "Siguiente",
					"sPrevious": "Anterior"
				},
				"oAria": {
					"sSortAscending": ": Activar para ordenar la columna de manera ascendente",
					"sSortDescending": ": Activar para ordenar la columna de manera descendente"
				}
			},
			rowCallback: (row: Node, data, index: number) => {
				const self = this;
				$('td>span#status', row).unbind('click');
				$('td>span#status', row).bind('click', () => {
					this.usuario = data;
					this.status();
				});
				$('td>span#editar', row).unbind('click');
				$('td>span#editar', row).bind('click', () => {
					this.usuario = data;
				});
				$('td>span#eliminar', row).unbind('click');
				$('td>span#eliminar', row).bind('click', () => {
					this.usuario = data;
				});
				return row;
			}
			// Declare the use of the extension in the dom parameter
			//dom: 'Bfrtip',
			// Configure the buttons
		    /*buttons: [
		        'columnsToggle',
		        'colvis',
		        'copy',
		        'print',
		        'excel',
		        {
		          text: 'Some button',
		          key: '1',
		          action: function (e, dt, node, config) {
		            alert('Button activated');
		          }
		        }
		    ]*/
		};
	}
	saveUser() {
		this._usuariosService.save(this.usuarios).subscribe(
			result => {
				if (result) {
					this.recargarTable();
				}
			},
			error => {
				var errorMessage = <any>error;
				console.log(errorMessage);
			}
		);
	}
	resetSelect() {
		this.usuarios.id_rol = -1;
	}
	resetUsuario() {
		this.usuario = new Usuario("", "", "", "", "", "");
	}
	status() {
		if (this.usuario.status == 1) {
			let hash = Md5.hashStr(this.usuario.email);
			this._usuariosService.desactive(hash).subscribe(
				result => {
					if (result) {
						this.resetUsuario();
						this.recargarTable();
					}
				},
				error => {
					var errorMessage = <any>error;
					console.log(errorMessage);
				}
			);
		} else {
			let hash = Md5.hashStr(this.usuario.email);
			this._usuariosService.active(hash).subscribe(
				result => {
					if (result) {
						this.resetUsuario();
						this.recargarTable();
					}
				},
				error => {
					var errorMessage = <any>error;
					console.log(errorMessage);
				}
			);
		}
	}
	recargarTable() {
		$("#tableUsuarios").DataTable().ajax.reload();
	}
	editUser() {
		let editUser = { nombre: this.usuario.nombre, email: this.usuario.email, id_rol: this.usuario.id_rol };
		this._usuariosService.edit(editUser).subscribe(
			result => {
				if (result) {
					this.resetUsuario();
					this.recargarTable();
				}
			},
			error => {
				var errorMessage = <any>error;
				console.log(errorMessage);
			}
		);
	}
	deleteUser() {
		let hash = Md5.hashStr(this.usuario.email);
		this._usuariosService.delete(hash).subscribe(
			result => {
				if (result) {
					this.resetUsuario();
					this.recargarTable();
				}
			},
			error => {
				var errorMessage = <any>error;
				console.log(errorMessage);
			}
		);
	}
}
