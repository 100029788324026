import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { LoginService } from '../services/login.service';

import { AppGlobals } from '../app.global';

@Component({
  selector: 'admin-menu',
  templateUrl: './adminMenu.component.html',
  styleUrls: ['./adminMenu.component.css'],
  providers: [LoginService]

})
export class AdminMenuComponent {
	public base = AppGlobals.url;
	public identificador;
	public nameUser;

	constructor(
		private _loginService: LoginService,
		private _route: ActivatedRoute,
		private _router: Router

	){}
	ngOnInit(){
		this._route.params.forEach((params: Params) => {
			let parametro = params['page'];
			this._loginService.validate(parametro).subscribe(
				result => {
					if(result=="false"){
						this._router.navigate(['/login']);
					}else{
						this.identificador = result[0].identificador;
						this.nameUser = result[0].nombre
					}
				},
				error => {
					var errorMessage = <any>error;
					console.log(errorMessage);
				}
			);
		});
	}
}
