export class Categoria{
	constructor(
		public id,
		public id_usuario,
		public fecha_creacion,
		public fecha_edicion,
		public id_catalogo,
		public nombre,
		public descripcion,
		public img_desk,
		public img_movil,
		public img_tablet,
		public miniatura,
		public subtitulo,
		public info_subtitulo,
		public identificador,
		public color,
		public nombre_catalogo,
		public identificador_catalogo,
		public page_title,
		public meta_description,
		public new_order,
		public orden,
		public status,
		public status_delete
	){}
}