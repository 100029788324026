import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";

import { AppGlobals } from "../app.global";

@Injectable()
export class CcService {
  public baseUrl: string;
  public urlGetCentros: string;
  public urlGetCentro: string;
  public urlActive: string;
  public urlInactive: string;
  public urlDelete: string;
  public urlGetCentroMes: string;

  public headers = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }),
  };

  constructor(public http: HttpClient) {
    this.baseUrl = AppGlobals.baseURL;
    this.urlGetCentros = "Centro_capacitacion/getCentrosFront";
    this.urlGetCentro = "Centro_capacitacion/getCentro";
    this.urlActive = "Centro_capacitacion/activeCentro";
    this.urlInactive = "Centro_capacitacion/inactiveCentro";
    this.urlDelete = "Centro_capacitacion/deleteCentro";
    this.urlGetCentroMes = "Centro_capacitacion/getCentrosFrontPorMes";
  }

  save(centro) {
    return this.http
      .post(this.baseUrl + "Centro_capacitacion/addCentro", centro)
      .pipe(map((res) => res));
  }

  edit(centro) {
    return this.http
      .post(this.baseUrl + 'Centro_capacitacion/editCentro', centro)
      .pipe(map((res) => res));
  }

  deleteCentro(centro) {
    const json = encodeURIComponent(JSON.stringify(centro));
    const params = "json=" + json;

    return this.http
      .post(this.baseUrl + this.urlDelete, params, this.headers)
      .pipe(map((res) => res));
  }

  getCentro(centro) {
    const json = encodeURIComponent(JSON.stringify(centro));
    const params = "json=" + json;

    return this.http
      .post(this.baseUrl + this.urlGetCentro, params, this.headers)
      .pipe(map((res) => res));
  }

  getCentros() {
    return this.http
      .get(this.baseUrl + this.urlGetCentros, this.headers)
      .pipe(map((res) => res));
  }

  desactive(id) {
    const json = JSON.stringify(id);
    const params = "json=" + json;

    return this.http
      .post(this.baseUrl + this.urlInactive, params, this.headers)
      .pipe(map((res) => res));
  }
  active(id) {
    const json = JSON.stringify(id);
    const params = "json=" + json;

    return this.http
      .post(this.baseUrl + this.urlActive, params, this.headers)
      .pipe(map((res) => res));
  }
}
