import { Component, TemplateRef  } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { NotifierService } from 'angular-notifier';

import { LoginService } from '../services/login.service';
import { CcService } from '../services/cc.service';

import { Cc } from '../models/cc';

import { AppGlobals } from '../app.global';


@Component({
  selector: 'ccAdmin',
  templateUrl: './ccAdmin.component.html',
  styleUrls: ['./ccAdmin.component.css'],
  providers: [LoginService, Md5, CcService]

})
export class CcAdminComponent {

	public dtOptions: any = {};
	public baseURL = AppGlobals.baseURL;
	public identificador;
	public base = AppGlobals.url;
	public CC: Cc;
	private readonly notifier: NotifierService;

	constructor(
		private loginService: LoginService,
		private ccService: CcService,
		private route: ActivatedRoute,
		private router: Router,
		private titleService: Title,
		notifierService: NotifierService
	) {
		this.CC = new Cc('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '');
		this.notifier = notifierService;
	}

	ngOnInit() {
		this.titleService.setTitle('Centros de capacitación - Organic Nails');
		this.route.params.forEach((params: Params) => {
			const parametro = localStorage.getItem('token');
			this.identificador = parametro;
			this.loginService.validate(parametro).subscribe(
				result => {
					if (result === 'false') {
						this.router.navigate(['/login']);
					}
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
		});
		this.dtOptions = {
			ajax: this.baseURL + '/Centro_capacitacion/getCentrosCMS',
	        columns: [
	        	{ title: 'Nombre', data: 'nombre' },
	        	{ title: 'Lugar', data: 'zona' },
	        	{ title: 'Teléfono', data: 'telefono' },
				{ title: 'Email', data: 'email' },
				{
					title: 'Estatus',
					data: 'status',
					mRender(data, type, full) {
						if (data == 1) {
								return '<span id="status"><i class="far fa-check-circle"></i></span>';
						} else {
							return '<span id="status"><i class="far fa-circle"></i></span>';
						}
					}
				},
				{
					title: 'Acciones',
					data: 'id',
					bSortable: false,
					mRender(data, type, full) {
						return '<span class="accion" id="editar"><i class="fa fa-pencil-alt"></i></span> <span class="accion" id="eliminar" data-toggle="modal" data-target="#modalDelete"><i class="fa fa-trash-alt"></i></span>';
					}
				}
	        ],
	        pagingType: 'simple',
			stateSave: true,
	        language: {
		        'sProcessing':    'Procesando...',
		        'sLengthMenu':    'Mostrar _MENU_ registros',
		        'sZeroRecords':   'No se encontraron resultados',
		        'sEmptyTable':    'Ningún dato disponible en esta tabla',
		        'sInfo':          'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
		        'sInfoEmpty':     'Mostrando registros del 0 al 0 de un total de 0 registros',
		        'sInfoFiltered':  '(filtrado de un total de _MAX_ registros)',
		        'sInfoPostFix':   '',
		        'sSearch':        'Buscar:',
		        'sUrl':           '',
		        'sInfoThousands':  ',',
		        'sLoadingRecords': 'Cargando...',
		        'oPaginate': {
		            'sFirst':    'Primero',
		            'sLast':    'Último',
		            'sNext': '<i class=\'fa  fa-arrow-right\'></i>',
					sPrevious: '<i class=\'fa fa-arrow-left\'></i>'
		        },
		        'oAria': {
		            'sSortAscending':  ': Activar para ordenar la columna de manera ascendente',
		            'sSortDescending': ': Activar para ordenar la columna de manera descendente'
		        }
		    },
		    rowCallback: (row: Node, data, index: number) => {
		        const self = this;
		        $('td>span#status', row).unbind('click');
				      $('td>span#status', row).bind('click', () => {
					this.CC = data;
					this.status();
				});
				      $('td>span#editar', row).unbind('click');
				      $('td>span#editar', row).bind('click', () => {
						localStorage.setItem('element', data.id);
						this.router.navigate(['/ccedit']);
				});
				      $('td>span#eliminar', row).unbind('click');
				      $('td>span#eliminar', row).bind('click', () => {
					this.CC = data;
				});
		        return row;
		    },
		    infoCallback( settings, start, end, max, total, pre ) {
		       if (total == 0) { return 'Sin registros disponibles'; }
		       return 'Mostrando registros del ' + start + ' al ' + end + ' de un total de ' + total + ' registros';
		    }
	        // Declare the use of the extension in the dom parameter
		    // dom: 'Bfrtip',
		    // Configure the buttons
		    /*buttons: [
		        'columnsToggle',
		        'colvis',
		        'copy',
		        'print',
		        'excel',
		        {
		          text: 'Some button',
		          key: '1',
		          action: function (e, dt, node, config) {
		            alert('Button activated');
		          }
		        }
		    ]*/
	    };
	}

	resetCC() {
		this.CC = new Cc('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '');
	}
	recargarTable() {
		$('#tableCC').DataTable().ajax.reload();
	}

	status() {
		if (this.CC.status == 1) {
			this.ccService.desactive(this.CC.id).subscribe(
				result => {
					if (result) {
						this.resetCC();
						this.recargarTable();
					}
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
		} else {
			this.ccService.active(this.CC.id).subscribe(
				result => {
					if (result) {
						this.resetCC();
						this.recargarTable();
					}
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
		}
	}
	delete() {
		this.ccService.deleteCentro(this.CC.id).subscribe(
			result => {
				if (result) {
					this.notifier.notify( 'success', '¡Exito! La operación se realizo exitosamente' );
					this.resetCC();
					this.recargarTable();
				} else {
					this.notifier.notify( 'error', '¡Error! La operación no se realizo exitosamente' );
				}
			},
			error => {
				this.notifier.notify( 'error', '¡Error! La operación no se realizo exitosamente' );
				const errorMessage =  error as any;
				console.log(errorMessage);
			}
		);
	}

}
