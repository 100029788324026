export class Seo{
	constructor(
		public id,
		public fecha_creacion,
		public fecha_edicion,
		public id_usuario,
		public nombre_pagina,
		public page_title,
		public meta_description,
		public identificador,
		public status,
		public status_delete,
	){}
}