import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppGlobals } from '../app.global';

@Injectable()
export class LoginService {
	public baseUrl: string;
	public urlLogin: string;
	public urlLoginValidate: string;
	public urlCerrarSesion: string;
	public urlLoginDistribuidores: string;
	public urlLoginValidateDistribuidor: string;
	public urlCambioContrasenaDistribuidor: string;
	public urlGenerarNewPass: string;

	public headers = {
		headers: new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded',
		})
	};

	constructor(
		public http: HttpClient
	) {
		this.baseUrl = AppGlobals.baseURL;
		this.urlLogin = 'Login/checkLogin';
		this.urlCerrarSesion = 'Login/cerrarSesion';
		this.urlLoginValidate = 'Login/checkHash';

		this.urlLoginDistribuidores = 'Distribuidor/checkLogin';
		this.urlLoginValidateDistribuidor = 'Distribuidor/checkHash';
		this.urlCambioContrasenaDistribuidor = 'Distribuidor/newPassword';

		this.urlGenerarNewPass = 'Distribuidor/generarLink';
	}
	login(user) {
		const json = encodeURIComponent(JSON.stringify(user));
		const params = 'json=' + json;
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.post(this.baseUrl + this.urlLogin, params, this.headers)
			.pipe(map(res => res));
	}
	loginDistribuidor(user) {
		const json = encodeURIComponent(JSON.stringify(user));
		const params = 'json=' + json;
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.post(this.baseUrl + this.urlLoginDistribuidores, params, this.headers)
			.pipe(map(res => res));
	}
	logoutDistribuidor(hash) {
		const json = JSON.stringify(hash);
		const params = 'json=' + json;
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.post(this.baseUrl + this.urlCerrarSesion, params, this.headers)
			.pipe(map(res => res));
	}
	logout(hash) {
		const json = JSON.stringify(hash);
		const params = 'json=' + json;
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.post(this.baseUrl + this.urlCerrarSesion, params, this.headers)
			.pipe(map(res => res));
	}
	validate(hash) {
		const json = JSON.stringify(hash);
		const params = 'json=' + json;
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.post(this.baseUrl + this.urlLoginValidate, params, this.headers)
			.pipe(map(res => res));
	}
	valDistribuidor(hash) {
		const json = JSON.stringify(hash);
		const params = 'json=' + json;
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.post(this.baseUrl + this.urlLoginValidateDistribuidor, params, this.headers)
			.pipe(map(res => res));
	}
	chengePassDis(pass) {
		const json = JSON.stringify(pass);
		const params = 'json=' + json;
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.post(this.baseUrl + this.urlCambioContrasenaDistribuidor, params, this.headers)
			.pipe(map(res => res));
	}

	olvide(reset) {
		const json = JSON.stringify(reset);
		const params = 'json=' + json;
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.post(this.baseUrl + this.urlGenerarNewPass, params, this.headers)
			.pipe(map(res => res));
	}
}
