<admin-header></admin-header>
<admin-menu></admin-menu>

<div class="content-wrapper">
	<section class="content-header">
	  <h1>
	    Usuarios
	    <small>Control panel</small>
	  </h1>
	  <ol class="breadcrumb">
	    <li><a href="#"><i class="fa fa-home"></i> Home</a></li>
	    <li class="">Usuarios</li>
	  </ol>
	</section>
	<section class="content">
		<div class="row">
			<div class="col-lg-12 table-responsive">
				<table id="tableUsuarios" style="width: 100%;" datatable [dtOptions]="dtOptions" class="row-border hover"></table>
			</div>
		</div>
	</section>
	<img class="add"  src="../../assets/img/circle-add.svg" alt="" title="Agregar nuevo usuario" data-toggle="modal" data-target="#modalAgregar" (click)="resetSelect();">
</div>

<div class="modal fade" id="modalAgregar" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <form #formAdd="ngForm">
			<div class="modal-header">
				<h5 class="modal-title">Agregar usuario</h5>
				<button (click)="formAdd.reset();" type="button" class="close" data-dismiss="modal" aria-label="Close">
				  <span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row">
			    	<div class="col-lg-6 col-xs-12">
			    		<div class="form-group">
						    <label for="nombre">Nombre <span class="obligatorio">*</span></label><span class="Error" *ngIf="nombre.touched && !nombre.valid">Error en el campo</span>
						    <input type="email" class="form-control" placeholder="Nombre" name="nombre" #nombre="ngModel" [(ngModel)]="usuarios.nombre" required>
						</div>
			    	</div>
			    	<div class="col-lg-6 col-xs-12">
			    		<div class="form-group">
						    <label for="exampleInputEmail1">Email <span class="obligatorio">*</span></label><span class="Error" *ngIf="email.touched && !email.valid">Error en el campo</span>
						    <input type="email" class="form-control" placeholder="Email" name="email" #email="ngModel" [(ngModel)]="usuarios.email" required pattern="{{emailPattern}}">
						</div>
			    	</div>
			    </div>
			    <div class="row">
			    	<div class="col-lg-6 col-xs-12">
			    		<div class="form-group">
						    <label for="nombre">Contraseña <span class="obligatorio">*</span></label><span class="Error" *ngIf="password.touched && !password.valid">Error en el campo</span>
						    <input type="text" class="form-control" placeholder="Contraseña" name="password" #password="ngModel" [(ngModel)]="usuarios.password" required>
						</div>
			    	</div>
			    	<div class="col-lg-6 col-xs-12">
			    		<div class="form-group">
						    <label for="exampleInputEmail1">Rol <span class="obligatorio">*</span></label><span class="Error" *ngIf="id_rol.touched && !id_rol.valid">Error en el campo</span>
						    <select class="form-control" name="id_rol" #id_rol="ngModel" [(ngModel)]="usuarios.id_rol" required >
						    	<option [ngValue]="-1">Selecciona una opción</option>}
						    	option
						    	<option *ngFor="let rol of roles" [ngValue]="rol.id">{{rol.nombre}}</option>
						    </select>
						</div>
			    	</div>
			    </div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn btnOrganicBlack" data-dismiss="modal" (click)="saveUser(); formAdd.reset(); resetSelect();" [disabled]="!formAdd.form.valid" >Guardar</button>
			</div>
        </form>
        </div>
    </div>
</div>

<div class="modal fade" id="modalEditar" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
			<form #formEdit="ngForm">
			<div class="modal-header">
				<h5 class="modal-title">Editar usuario</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				  <span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row">
			    	<div class="col-lg-12 col-xs-12">
			    		<div class="form-group">
						    <label for="exampleInputEmail1">Email <span class="obligatorio">*</span></label><span class="Error" *ngIf="email.touched && !email.valid">Error en el campo</span>
						    <input type="email" class="form-control" placeholder="Email" name="email" #email="ngModel" [(ngModel)]="usuario.email" required disabled>
						</div>
			    	</div>
			    	<div class="col-lg-12 col-xs-12">
			    		<div class="form-group">
						    <label for="nombre">Nombre <span class="obligatorio">*</span></label><span class="Error" *ngIf="nombre.touched && !nombre.valid">Error en el campo</span>
						    <input type="email" class="form-control" placeholder="Nombre" name="nombre" #nombre="ngModel" [(ngModel)]="usuario.nombre" required>
						</div>
			    	</div>
			    </div>
			    <div class="row">
			    	<div class="col-lg-12 col-xs-12">
			    		<div class="form-group">
						    <label for="exampleInputEmail1">Rol <span class="obligatorio">*</span></label><span class="Error" *ngIf="id_rol.touched && !id_rol.valid">Error en el campo</span>
						    <select class="form-control" name="id_rol" #id_rol="ngModel" [(ngModel)]="usuario.id_rol" required >
						    	<option [ngValue]="-1">Selecciona una opción</option>}
						    	option
						    	<option *ngFor="let rol of roles" [ngValue]="rol.id">{{rol.nombre}}</option>
						    </select>
						</div>
			    	</div>
			    </div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn btnOrganicBlack" [disabled]="!formEdit.form.valid" data-dismiss="modal" (click)="editUser();">Guardar</button>
				<button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">Close</button>
			</div>
        </form>
        </div>
    </div>
</div>

<div class="modal fade" id="modalDelete" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Eliminar usuario</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				  <span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row">
			    	<div class="col-lg-12 col-xs-12 text-center">
			    		<h3>¿Deseas eliminar al usuario <b>{{usuario.nombre}}</b> con el correo <b>{{usuario.email}}?</b></h3>
			    	</div>
			    </div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn btnOrganicBlack" data-dismiss="modal" (click)="deleteUser();">Aceptar</button>
				<button type="button" class="btn btn-secondary waves-effect waves-light" data-dismiss="modal">Close</button>
			</div>
        </div>
    </div>
</div>
