import { Component, TemplateRef, ViewChild, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AppGlobals } from '../app.global';

import { LoginService } from '../services/login.service';
import { CategoriasService } from '../services/categorias.service';
import { CatalogoService } from '../services/catalogo.service';
import { SubcategoriaService } from '../services/subcategoria.service';
import { ProductoService } from '../services/producto.service';

import { Producto } from '../models/producto';

@Component({
  selector: "productosEdit",
  templateUrl: './productosEdit.component.html',
  styleUrls: ['./productosEdit.component.css'],
  providers: [
    LoginService,
    CategoriasService,
    CatalogoService,
    SubcategoriaService,
    ProductoService,
  ],
})
export class ProductosEditComponent implements OnInit {
  @ViewChild('myInput', { static: true }) static;
  public myInputVariable;
  public identificador;
  public catalogos;
  public categorias;
  public subcategorias;
  public base = AppGlobals.url;
  public producto: Producto;
  public formData = new FormData();
  public bandPrincipal = true;
  public bandColor = true;
  private readonly notifier: NotifierService;
  public config = {
    removeButtons:
      'Source,Save,NewPage,DocProps,Preview,Print,Templates,document,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Image,Flash,Table,HorizontalRule,Smiley,PageBreak,Iframe,CreateDiv,BidiLtr,BidiRtl,About,ShowBlocks,Font,UIColor,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Superscript,RemoveFormat,CreatePlaceholder,Styles',
  };

  constructor(
    private loginService: LoginService,
    private categoriasService: CategoriasService,
    private subcategoriaService: SubcategoriaService,
    private catalogoService: CatalogoService,
    private productoService: ProductoService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    notifierService: NotifierService
  ) {
    this.producto = new Producto(
      '',
      '',
      '',
      '',
      -1,
      -1,
      -1,
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      '',
      false
    );
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.titleService.setTitle('Editar producto - Organic Nails');
    this.route.params.forEach((params: Params) => {
      const parametro = localStorage.getItem('token');
      const idparametro = localStorage.getItem('element');
      this.identificador = parametro;
      this.loginService.validate(parametro).subscribe(
        (result) => {
          if (result === 'false') {
            this.router.navigate(['/login']);
          }
        },
        (error) => {
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
      this.productoService.getProducto(idparametro).subscribe(
        (result) => {
          this.producto = result[0];
          this.producto.destacado = this.producto.destacado === '1' ? true : false;
          this.producto.identificador_producto = this.producto.identificador;
          this.getCategorias(1);
          this.getSubcategorias();
        },
        (error) => {
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
    });
    this.catalogoService.getCatalogos().subscribe(
      (result) => {
        this.catalogos = result;
      },
      (error) => {
        this.notifier.notify(
          'error',
          '¡Error! La operación no se realizo exitosamente'
        );
        const errorMessage = error as any;
        console.log(errorMessage);
      }
    );
  }

  getCategorias(aux) {
    if (aux === 1) {
      if (this.producto.id_catalogo !== -1) {
        this.categoriasService
          .getCategorias(this.producto.id_catalogo)
          .subscribe(
            (result) => {
              this.categorias = result;
            },
            (error) => {
              this.notifier.notify(
                'error',
                '¡Error! La operación no se realizo exitosamente'
              );
              const errorMessage = error as any;
              console.log(errorMessage);
            }
          );
      } else {
        this.producto.id_categoria = -1;
      }
    } else {
      this.producto.id_categoria = -1;
      this.producto.id_subcategoria = -1;
      this.subcategorias = null;
      this.categoriasService.getCategorias(this.producto.id_catalogo).subscribe(
        (result) => {
          this.categorias = result;
          this.getSubcategorias();
        },
        (error) => {
          this.notifier.notify(
            'error',
            '¡Error! La operación no se realizo exitosamente'
          );
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
    }
  }

  getSubcategorias() {
    if (this.producto.id_categoria !== -1) {
      this.subcategoriaService
        .getSubcategorias(this.producto.id_categoria)
        .subscribe(
          (result) => {
            this.subcategorias = result;
          },
          (error) => {
            this.notifier.notify(
              'error',
              '¡Error! La operación no se realizo exitosamente'
            );
            const errorMessage = error as any;
            console.log(errorMessage);
          }
        );
    } else {
      this.producto.id_subcategoria = -1;
    }
  }

  onChangePrincipal(event) {
    const elem = event.target;
    if (elem.files.length > 0) {
      this.formData.append('imagen_principal', elem.files[0]);
      this.bandPrincipal = false;
    }
  }

  onChangeColor(event) {
    const elem = event.target;
    if (elem.files.length > 0) {
      this.formData.append('imagen_color', elem.files[0]);
      this.bandColor = false;
    }
  }

  saveProducto() {
    this.formData.append('id', this.producto.id);
    this.formData.append('id_catalogo', this.producto.id_catalogo);
    this.formData.append('id_categoria', this.producto.id_categoria);
    this.formData.append('id_subcategoria', this.producto.id_subcategoria);
    this.formData.append('nombre', this.producto.nombre);
    this.formData.append('info_producto', this.producto.info_producto);
    this.formData.append('caracteristicas', this.producto.caracteristicas);
    this.formData.append('presentaciones', this.producto.presentaciones);
    this.formData.append('video', this.producto.video);
    this.formData.append('precio', this.producto.precio);
    this.formData.append('page_title', this.producto.page_title);
    this.formData.append('meta_description', this.producto.meta_description);
    this.formData.append('link', this.producto.link);
    if (this.producto.destacado) {
      this.formData.append('destacado', '1');
    } else {
      this.formData.append('destacado', '2');
    }
    this.formData.append('etiqueta', this.producto.etiqueta);
    this.formData.append('identificador', this.identificador);
    this.formData.append(
      'identificador_producto',
      this.producto.identificador_producto
    );
    this.productoService.edit(this.formData).subscribe(
      (result) => {
        if (result) {
          this.notifier.notify(
            'success',
            '¡Exito! La operación se realizo exitosamente'
          );
          setTimeout(() => {
            this.router.navigate(['/productoslist']);
          }, 1500);
        } else {
          this.notifier.notify(
            'error',
            '¡Error! La operación no se realizo exitosamente'
          );
        }
      },
      (error) => {
        this.notifier.notify(
          'error',
          '¡Error! La operación no se realizo exitosamente'
        );
        const errorMessage = error as any;
        console.log(errorMessage);
      }
    );
  }
}
