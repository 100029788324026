import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MDBBootstrapModule, TooltipModule } from 'angular-bootstrap-md';
import { DataTablesModule } from 'angular-datatables';
import { CKEditorModule } from 'ngx-ckeditor';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { NotifierModule } from 'angular-notifier';
import { NgxPaginationModule } from 'ngx-pagination';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { AdminFooterComponent } from './adminFooter/adminFooter.component';
import { AdminHeaderComponent } from './adminHeader/adminHeader.component';
import { AdminMenuComponent } from './adminMenu/adminMenu.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { DialogComponent } from './dialog/dialog.component';
import { BannersEditComponent } from './bannersEdit/bannersEdit.component';
import { AdmonBannersComponent } from './admonBanners/admonBanners.component';
import { AdmonPromastersComponent } from './admonPromasters/admonPromasters.component';
import { AddPromasterComponent } from './addPromaster/addPromaster.component';
import { EditPromasterComponent } from './editPromaster/editPromaster.component';
import { DistribuidoresAdminComponent } from './distribuidoresAdmin/distribuidoresAdmin.component';
import { DistribuidoresAddComponent } from './distribuidoresAdd/distribuidoresAdd.component';
import { BannersAddComponent } from './bannersAdd/bannersAdd.component';
import { EventosAdminComponent } from './eventosAdmin/eventosAdmin.component';
import { EventosAddComponent } from './eventosAdd/eventosAdd.component';
import { EventosEditComponent } from './eventosEdit/eventosEdit.component';
import { CcAdminComponent } from './ccAdmin/ccAdmin.component';
import { CcAddComponent } from './ccAdd/ccAdd.component';
import { EventosAllComponent } from './eventosAll/eventosAll.component';
import { CcEditComponent } from './ccEdit/ccEdit.component';
import { DocumentacionAdminComponent } from './documentacionAdmin/documentacionAdmin.component';
import { NewsletterAdminComponent } from './newsletterAdmin/newsletterAdmin.component';
import { LoginDistibuidoresComponent } from './loginDistribuidores/loginDistribuidores.component';
import { AdminDistribuidorComponent } from './adminDistribuidor/adminDistribuidor.component';
import { AdminDistribuidorMenuComponent } from './adminDistribuidorMenu/adminDistribuidorMenu.component';
import { AdminDistribuidorHeaderComponent } from './adminDistribuidorHeader/adminDistribuidorHeader.component';
import { DocumentacionDistribuidorComponent } from './documentacionDistribuidor/documentacionDistribuidor.component';
import { CambiarPasswordDistribuidorComponent } from './cambiarPasswordDistribuidor/cambiarPasswordDistribuidor.component';
import { CatalogosAdminComponent } from './catalogosAdmin/catalogosAdmin.component';
import { CatalogosEditComponent } from './catalogosEdit/catalogosEdit.component';
import { CategoriasAdminComponent } from './categoriasAdmin/categoriasAdmin.component';
import { CategoriasAddComponent } from './categoriasAdd/categoriasAdd.component';
import { CategoriasEditComponent } from './categoriasEdit/categoriasEdit.component';
import { SubcategoriasAdminComponent } from './subcategoriaAdmin/subcategoriaAdmin.component';
import { SubcategoriaAddComponent } from './subcategoriaAdd/subcategoriaAdd.component';
import { SubcategoriaEditComponent } from './subcategoriaEdit/subcategoriaEdit.component';
import { ProductosAdminComponent } from './productosAdmin/productosAdmin.component';
import { ProductosAddComponent } from './productosAdd/productosAdd.component';
import { ProductosEditComponent } from './productosEdit/productosEdit.component';
import { SeoAdminComponent } from './seoAdmin/seoAdmin.component';
import { DistribuidoresEditComponent } from './distribuidoresEdit/distribuidoresEdit.component';

import { KeysPipe } from './pipes/keys.pipe';
import { StringPipe } from './pipes/string.pipe';
import { MesPipe } from './pipes/meses.pipe';
import { PaisPipe } from './pipes/pais.pipe';
import { TipoPromasterPipe } from './pipes/tipoPromaster.pipe';
import { YoutubePipe } from './pipes/youtube.pipe';
import { PaisCCoComponent } from './pais-cco/pais-cco.component';

@NgModule({
  declarations: [
	AppComponent,
	LoginComponent,
	AdminComponent,
	AdminFooterComponent,
	AdminHeaderComponent,
	AdminMenuComponent,
	UsuariosComponent,
	DialogComponent,
	AdmonPromastersComponent,
	AddPromasterComponent,
	EditPromasterComponent,
	KeysPipe,
	StringPipe,
	MesPipe,
	PaisPipe,
	YoutubePipe,
	TipoPromasterPipe,
	AdmonBannersComponent,
	BannersAddComponent,
	BannersEditComponent,
	DistribuidoresAdminComponent,
	DistribuidoresAddComponent,
	EventosAdminComponent,
	EventosAddComponent,
	EventosEditComponent,
	EventosAllComponent,
	CcAdminComponent,
	CcAddComponent,
	CcEditComponent,
	DocumentacionAdminComponent,
	NewsletterAdminComponent,
	LoginDistibuidoresComponent,
	AdminDistribuidorComponent,
	AdminDistribuidorMenuComponent,
	AdminDistribuidorHeaderComponent,
	DocumentacionDistribuidorComponent,
	CambiarPasswordDistribuidorComponent,
	CatalogosAdminComponent,
	CatalogosEditComponent,
	CategoriasAdminComponent,
	CategoriasAddComponent,
	CategoriasEditComponent,
	SubcategoriasAdminComponent,
	SubcategoriaAddComponent,
	SubcategoriaEditComponent,
	ProductosAdminComponent,
	ProductosAddComponent,
	ProductosEditComponent,
	SeoAdminComponent,
	DistribuidoresEditComponent,
	PaisCCoComponent
  ],
  imports: [
	BrowserModule,
	AppRoutingModule,
	AgmCoreModule.forRoot({
		apiKey: 'AIzaSyBg3l50uHE-J-Vz2GFnRno3w3JF4uqOU40',
		libraries: ['places'],
		language: 'es'
	}),
	FormsModule,
	ReactiveFormsModule,
	HttpClientModule,
	BrowserAnimationsModule,
	MDBBootstrapModule.forRoot(),
	TooltipModule.forRoot(),
	DataTablesModule,
	CKEditorModule,
	ColorPickerModule,
	MatDatepickerModule,
	MatFormFieldModule,
	MatInputModule,
	TextareaAutosizeModule,
	NgxPaginationModule,
	MatIconModule,
	MatNativeDateModule,
	NotifierModule.withConfig({
		theme: 'material',
		position: {
			horizontal: {
				position: 'right'
			},
			vertical: {
				position: 'top',
				distance: 1,
				gap: 100
			}
		}
	})
  ],
  providers: [
	  AppRoutingModule,
		{ provide:
			MAT_DATE_LOCALE,
			useValue: 'es-ES' }],
  bootstrap: [AppComponent]
})
export class AppModule { }
