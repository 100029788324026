import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { AppGlobals } from '../app.global';

import { LoginService } from '../services/login.service';

@Component({
  selector: 'admindistribuidor-menu',
  templateUrl: './adminDistribuidorMenu.component.html',
  styleUrls: ['./adminDistribuidorMenu.component.css'],
  providers: [LoginService]

})
export class AdminDistribuidorMenuComponent {
	public base = AppGlobals.url;
	public identificador;
	public nameUser;

	constructor(
		private loginService: LoginService,
		private route: ActivatedRoute,
		private router: Router

	) {}
	ngOnInit() {
		this.route.params.forEach((params: Params) => {
			const parametro = localStorage.getItem('token');
			this.identificador = parametro;
		});
	}
}
