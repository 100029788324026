import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppGlobals } from '../app.global';

@Injectable()
export class UsuariosService {
	public baseUrl: string;
	public urlGetRoles: string;
	public urlSave: string;
	public urlGetUsuarios: string;
	public urlDesacUsuario: string;
	public urlActUsuario: string;
	public urlEdit: string;
	public urlDelete: string;

	public headers = {
		headers: new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded',
		})
	};

	constructor(
		public http: HttpClient
	) {
		this.baseUrl = AppGlobals.baseURL;
		this.urlGetRoles = 'Usuario/selectRoles';
		this.urlSave = 'Usuario/addUsuario';
		this.urlGetUsuarios = 'Usuario/getUsuarios';
		this.urlDesacUsuario = 'Usuario/inactiveUsuario';
		this.urlActUsuario = 'Usuario/activeUsuario';
		this.urlEdit = 'Usuario/editUsuario';
		this.urlDelete = 'Usuario/deleteUsuario';
	}
	getRoles() {
		return this.http.get(this.baseUrl + this.urlGetRoles, this.headers)
			.pipe(map(res => res));
	}
	getUsuarios() {
		return this.http.get(this.baseUrl + this.urlGetUsuarios, this.headers)
			.pipe(map(res => res));
	}
	save(user) {
		const json = encodeURIComponent(JSON.stringify(user));
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlSave, params, this.headers)
			.pipe(map(res => res));
	}
	desactive(hash) {
		const json = JSON.stringify(hash);
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlDesacUsuario, params, this.headers)
			.pipe(map(res => res));
	}
	active(hash) {
		const json = JSON.stringify(hash);
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlActUsuario, params, this.headers)
			.pipe(map(res => res));
	}
	edit(usuario) {
		const json = encodeURIComponent(JSON.stringify(usuario));
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlEdit, params, this.headers)
			.pipe(map(res => res));
	}
	delete(hash) {
		const json = JSON.stringify(hash);
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlDelete, params, this.headers)
			.pipe(map(res => res));
	}


}
