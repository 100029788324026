import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AppGlobals } from '../app.global';

import { LoginService } from '../services/login.service';
import { CategoriasService } from '../services/categorias.service';
import { CatalogoService } from '../services/catalogo.service';

import { Categoria } from '../models/categoria';

@Component({
	selector: 'categoriasEdit',
	templateUrl: './categoriasEdit.component.html',
	styleUrls: ['./categoriasEdit.component.css'],
	providers: [LoginService, CategoriasService, CatalogoService]
})

export class CategoriasEditComponent {

	@ViewChild('myInput', { static: true }) static; ;
	public myInputVariable;
	public identificador;
	public catalogos;
	public base = AppGlobals.url;
	public categoria: Categoria;
	public formData = new FormData();
	public bandDesk = true;
	public bandMovil = true;
	public bandTablet = true;
	public bandMiniatura = true;
	private readonly notifier: NotifierService;

	constructor(
		private loginService: LoginService,
		private categoriasService: CategoriasService,
		private catalogoService: CatalogoService,
		private route: ActivatedRoute,
		private router: Router,
		private titleService: Title,
		notifierService: NotifierService
	) {
		this.categoria = new Categoria('', '', '', '', -1, '', '', {}, {}, {}, {}, '', '', '', '', '', '', '', '', '', '', '', '');
		this.notifier = notifierService;
	}

	ngOnInit() {
		this.titleService.setTitle('Agregar Categoría - Organic Nails');
		this.route.params.forEach((params: Params) => {
			const parametro = localStorage.getItem('token');
			const idparametro = localStorage.getItem('element');
			this.identificador = parametro;
			this.loginService.validate(parametro).subscribe(
				result => {
					if (result == 'false') {
						this.router.navigate(['/login']);
					}
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
			this.categoriasService.getCategoria(idparametro).subscribe(
				result => {
					this.categoria = result[0];
					this.categoria.id = idparametro;
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
		});
		this.catalogoService.getCatalogos().subscribe(
			result => {
				this.catalogos = result;
			},
			error => {
				this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				const errorMessage =  error as any;
				console.log(errorMessage);
			}
		);
	}

	onChangeMovil(event) {
		const elem = event.target;
		if (elem.files.length > 0) {
			this.formData.append('movil', elem.files[0]);
			this.bandMovil = false;
		}
	}

	onChangeTablet(event) {
		const elem = event.target;
		if (elem.files.length > 0) {
			this.formData.append('tablet', elem.files[0]);
			this.bandTablet = false;
		}
	}

	onChangeDesk(event) {
		const elem = event.target;
		if (elem.files.length > 0) {
			this.formData.append('desk', elem.files[0]);
			this.bandDesk = false;
		}
	}

	onChangeMiniatura(event) {
		const elem = event.target;
		if (elem.files.length > 0) {
			this.formData.append('miniatura', elem.files[0]);
			this.bandMiniatura = false;
		}
	}

	editCategoria() {
		this.formData.append('id', this.categoria.id);
		this.formData.append('nombre', this.categoria.nombre);
		this.formData.append('descripcion', this.categoria.descripcion);
		this.formData.append('identificador_categoria', this.categoria.identificador);
		this.formData.append('subtitulo', this.categoria.subtitulo);
		this.formData.append('info_subtitulo', this.categoria.info_subtitulo);
		this.formData.append('color', this.categoria.color);
		this.formData.append('id_catalogo', this.categoria.id_catalogo);
		this.formData.append('identificador', this.identificador);
		this.formData.append('meta_description', this.categoria.meta_description);
		this.formData.append('page_title', this.categoria.page_title);
		this.categoriasService.edit(this.formData).subscribe(
			result => {
				if (result) {
					this.notifier.notify('success', '¡Exito! La operación se realizo exitosamente');
					setTimeout(() => {
						this.router.navigate(['/categoriaslist']);
					}, 1500);
				} else {
					this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				}
			},
			error => {
				this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				const errorMessage =  error as any;
				console.log(errorMessage);
			}
		);
	}

}
