import { PipeTransform, Pipe } from '@angular/core';

@Pipe({name: 'tipoPromaster'})
export class TipoPromasterPipe implements PipeTransform {
  transform(value) {
    switch (value) {
    	case "1":
    		return "Promaster";
    		break;
    	case "2":
    		return "Proeducator";
    		break;
    	default:
    		return "N/D";
    		break;
    }
  }
}