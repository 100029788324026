export class Producto {
  constructor(
    public id,
    public id_usuario,
    public fecha_creacion,
    public fecha_edicion,
    public id_catalogo,
    public id_categoria,
    public id_subcategoria,
    public nombre,
    public imagen_principal,
    public info_producto,
    public presentaciones,
    public video,
    public precio,
    public imagen_color,
    public identificador,
    public identificador_producto,
    public imagenes,
    public status,
    public status_delete,
    public nombre_catalogo,
    public identificador_catalogo,
    public nombre_categoria,
    public identificador_categoria,
    public nombre_subcategoria,
    public page_title,
    public meta_description,
    public new_order,
    public orden,
    public caracteristicas,
    public otras_imagenes,
    public link,
    public etiqueta,
    public destacado
  ) {}
}
