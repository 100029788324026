<admin-header></admin-header>
<admin-menu></admin-menu>

<notifier-container></notifier-container>

<div class="content-wrapper">
	<div class="content-header">
	  <h1>
	    Agregar categoría
	    <small>Control panel</small>
	  </h1>
	  <ol class="breadcrumb">
	    <li><a [routerLink]="['/admin']"><i class="fa fa-home"></i> Home</a></li>
	    <li class=""><a [routerLink]="['/categoriaslist']">Categorías</a></li>
	    <li class="">Agregar categoría</li>
	  </ol>
	</div>
	<section class="content">
		<form #formAdd="ngForm">
			<!--Titulos-->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Nombre <span class="obligatorio">*</span></label><span class="Error" *ngIf="nombre.touched && !nombre.valid">Error en el campo</span>
					    <input type="text" class="form-control" placeholder="Nombre" name="nombre" #nombre="ngModel" [(ngModel)]="categoria.nombre" required>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Descripción <span class="obligatorio">*</span></label><span class="Error" *ngIf="descripcion.touched && !descripcion.valid">Error en el campo</span>
					    <textarea type="text" class="form-control" placeholder="Información banner" name="descripcion" #descripcion="ngModel" [(ngModel)]="categoria.descripcion" required></textarea>
					</div>
				</div>
			</div>
			<!--URL y Catalogo-->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">URL (organic.com/producto/) <span class="obligatorio">*</span></label><span class="Error" *ngIf="url.touched && !url.valid">Error en el campo</span>
					    <input type="text" class="form-control" placeholder="URL" name="url" #url="ngModel" [(ngModel)]="categoria.identificador" required>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Catálogo <span class="obligatorio">*</span></label><span class="Error" *ngIf="id_catalogo.touched && !id_catalogo.valid">Error en el campo</span>
					    <select class="form-control" name="id_catalogo" #id_catalogo="ngModel" [(ngModel)]="categoria.id_catalogo" >
					    	<option value="-1">Selecciona</option>
					    	<option *ngFor="let c of catalogos" [ngValue]="c.id">{{c.nombre}}</option>
					    </select>
					</div>
				</div>
			</div>
			<!--Subtitulos-->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Subtitulo <span class="obligatorio">*</span></label><span class="Error" *ngIf="subtitulo.touched && !subtitulo.valid">Error en el campo</span>
					    <input type="text" class="form-control" placeholder="Subtitulo" name="subtitulo" #subtitulo="ngModel" [(ngModel)]="categoria.subtitulo" required>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Información subtitulo <span class="obligatorio">*</span></label><span class="Error" *ngIf="info_subtitulo.touched && !info_subtitulo.valid">Error en el campo</span>
					    <textarea type="text" class="form-control" placeholder="Información subtitulo" name="info_subtitulo" #info_subtitulo="ngModel" [(ngModel)]="categoria.info_subtitulo" required></textarea>
					</div>
				</div>
			</div>
			<!--Identificador y color-->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
						<label for="nombre">Color de texto<span class="obligatorio">*</span></label>
						<input class="form-control" [style.background]="categoria.color"
					       [cpAlphaChannel]="'always'"
					       [cpOutputFormat]="'rgba'"
					       [cpPresetColors]="['#fff', '#2889e9']"
					       [cpAddColorButton]="true"
					       [(colorPicker)]="categoria.color"/>
					</div>
				</div>
			</div>
			<!-- Imagenes-->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
						<label>Fondo</label>
						<input #myInput accept="image/png, image/jpeg, image/jpg" type="file" placeholder="File Name" name="filename" (change)="onChangeDesk($event)" class="form-control">
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
						<label>Imagen</label>
						<input #myInput accept="image/png, image/jpeg, image/jpg" type="file" placeholder="File Name" name="filename" (change)="onChangeTablet($event)" class="form-control">
					</div>
				</div>
			</div>
			<!--<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
						<label>Movil</label>
						<input #myInput accept="image/png, image/jpeg, image/jpg" type="file" placeholder="File Name" name="filename" (change)="onChangeMovil($event)" class="form-control">
						<div *ngIf="bandMovil">
							<img src="{{categoria.img_movil}}" style="width: 100%;">
						</div>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
						<label>Miniatura</label>
						<input #myInput accept="image/png, image/jpeg, image/jpg" type="file" placeholder="File Name" name="filename" (change)="onChangeMiniatura($event)" class="form-control">
						<div *ngIf="bandMiniatura">
							<img src="{{categoria.miniatura}}" style="width: 100%;">
						</div>
					</div>
				</div>
			</div>-->
			<!--SEO-->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Page Title <span class="obligatorio">*</span></label><span class="Error" *ngIf="page_title.touched && !page_title.valid">Error en el campo</span>
					    <input type="text" class="form-control" placeholder="Page Title" name="page_title" #page_title="ngModel" [(ngModel)]="categoria.page_title" required>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Meta description <span class="obligatorio">*</span></label><span class="Error" *ngIf="meta_description.touched && !meta_description.valid">Error en el campo</span>
					    <input type="text" class="form-control" placeholder="Meta description" name="meta_description" #meta_description="ngModel" [(ngModel)]="categoria.meta_description" required>
					</div>
				</div>
			</div>
			<div class="row espForms">
				<div class="col-md-3">
					<button class="btn btnOrganicBlack" (click)="saveCategoria();">Guardar</button>
				</div>
			</div>
		</form>
	</section>

