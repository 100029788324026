<admindistribuidor-menu></admindistribuidor-menu>
<admindistribuidor-header></admindistribuidor-header>

<div class="content-wrapper">
	<section class="content-header">
	  <h1>
	    Documentación para distribuidores
	    <small> Control panel</small>
	  </h1>
	  <ol class="breadcrumb">
	    <li><a [routerLink]="['/adminDistribuidor', identificador]"><i class="fa fa-home"></i> Home </a> </li>
	    <li class="">Documentación</li>
	  </ol>
	</section>
	<section class="content">
		<div class="row">
			<div class="col-lg-8 text-center">
				
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12 table-responsive">
				<table id="tableDocsDistribuidor" style="width: 100%;" datatable [dtOptions]="dtOptions" class="row-border hover"></table>
			</div>
		</div>
	</section>
</div>