import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppGlobals } from '../app.global';

@Injectable()
export class DistribuidoresService {
	public baseUrl: string;
	public urlSave: string;
	public urlEdit: string;
	public urlActive: string;
	public urlInactive: string;
	public urlDelete: string;
	public urlInternacioneles: string;
	public urlGetPorPais: string;
	public urlGetNacional: string;
	public urlDistribuidoresFiltro: string;
	public urlGetDistribuidor: string;

	public headers = {
		headers: new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded',
		})
	};

	constructor(
		public http: HttpClient
	) {
		this.baseUrl = AppGlobals.baseURL;
		this.urlSave = 'Distribuidor/addDistribuidor';
		this.urlEdit = 'Distribuidor/editDistribuidor';
		this.urlGetDistribuidor = 'Distribuidor/getDistribuidor';
		this.urlActive = 'Distribuidor/activeDistribuidor';
		this.urlInactive = 'Distribuidor/inactiveDistribuidor';
		this.urlDelete = 'Distribuidor/deleteDistribuidor';
		this.urlInternacioneles = 'Distribuidor/getDistribuidoresInternacioneles';
		this.urlGetPorPais = 'Distribuidor/getDistribuidoresInternacionalesPais';
		this.urlGetNacional = 'Distribuidor/getNacionales';
		this.urlDistribuidoresFiltro = 'Distribuidor/getPorFiltro';
	}

	save(distribuidor) {
		const json = encodeURIComponent(JSON.stringify(distribuidor));
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlSave, params, this.headers)
			.pipe(map(res => res));
	}

	edit(distribuidor) {
		const json = encodeURIComponent(JSON.stringify(distribuidor));
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlEdit, params, this.headers)
			.pipe(map(res => res));
	}

	deleteDistribuidor(distribuidor) {
		const json = encodeURIComponent(JSON.stringify(distribuidor));
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlDelete, params, this.headers)
			.pipe(map(res => res));
	}

	desactive(id) {
		const json = JSON.stringify(id);
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlInactive, params, this.headers)
			.pipe(map(res => res));
	}
	active(id) {
		const json = JSON.stringify(id);
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlActive, params, this.headers)
			.pipe(map(res => res));
	}
	getDistribuidor(id) {
		const json = JSON.stringify(id);
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlGetDistribuidor, params, this.headers)
			.pipe(map(res => res));
	}
	getInternacionales() {

		return this.http.get(this.baseUrl + this.urlInternacioneles, this.headers)
			.pipe(map(res => res));
	}
	getInternacionalesPais(pais) {
		const json = JSON.stringify(pais);
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlGetPorPais, params, this.headers)
			.pipe(map(res => res));
	}

	getNacionales() {
		return this.http.get(this.baseUrl + this.urlGetNacional, this.headers)
			.pipe(map(res => res));
	}

	getDistribuidoresFiltro(text) {
		const json = JSON.stringify(text);
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlDistribuidoresFiltro, params, this.headers)
			.pipe(map(res => res));
	}
}
