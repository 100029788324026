export class Cc{
	constructor(
		public id,
		public fecha_creacion,
		public fecha_edicion,
		public id_usuario,
		public identificador,
		public titulo,
		public nombre,
		public direccion,
		public zona,
		public horarios,
		public url_mapa,
		public telefono,
		public email,
		public whatsapp,
		public status,
		public status_delete
	){}
}