import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppGlobals } from '../app.global';

@Injectable()
export class BannersService {
  public baseUrl: string;
  public urlSave: string;
  public urlGetBanner: string;
  public urlDelete: string;
  public urlInactive: string;
  public urlActive: string;
  public urlEdit: string;
  public urlGetBannersPrincipal: string;
  public urlGetBannersSecundarios: string;
  public urlOrdenamiento: string;
  public headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
  };

  constructor(public http: HttpClient) {
    this.baseUrl = AppGlobals.baseURL;
    this.urlSave = 'Banner/addBanner';
    this.urlGetBanner = 'Banner/getBanner';
    this.urlDelete = 'Banner/deleteBanner';
    this.urlInactive = 'Banner/inactiveBanner';
    this.urlActive = 'Banner/activeBanner';
    this.urlEdit = 'Banner/editBanner';
    this.urlGetBannersPrincipal = 'Banner/getBannersPrincipal';
    this.urlGetBannersSecundarios = 'Banner/getBannersSecundario';
    this.urlOrdenamiento = 'Banner/updateOrder';
  }

  save(banner) {
    const json = encodeURIComponent(JSON.stringify(banner));
    const params = 'json=' + json;

    return this.http
      .post(this.baseUrl + this.urlSave, banner /*, {headers: headers}*/)
      .pipe(map((res) => res));
  }
  edit(banner) {
    const json = encodeURIComponent(JSON.stringify(banner));
    const params = 'json=' + json;

    return this.http
      .post(this.baseUrl + this.urlEdit, banner /*, {headers: headers}*/)
      .pipe(map((res) => res));
  }
  ordenamiento(banner) {
    const json = encodeURIComponent(JSON.stringify(banner));
    const params = 'json=' + json;

    return this.http
      .post(this.baseUrl + this.urlOrdenamiento, params, this.headers)
      .pipe(map((res) => res));
  }
  desactive(id) {
    const json = JSON.stringify(id);
    const params = 'json=' + json;

    return this.http
      .post(this.baseUrl + this.urlInactive, params, this.headers)
      .pipe(map((res) => res));
  }
  active(id) {
    const json = JSON.stringify(id);
    const params = 'json=' + json;

    return this.http
      .post(this.baseUrl + this.urlActive, params, this.headers)
      .pipe(map((res) => res));
  }
  deleteBanner(id) {
    const json = JSON.stringify(id);
    const params = 'json=' + json;

    return this.http
      .post(this.baseUrl + this.urlDelete, params, this.headers)
      .pipe(map((res) => res));
  }
  getBannerEdit(id) {
    const json = JSON.stringify(id);
    const params = 'json=' + json;

    return this.http
      .post(this.baseUrl + this.urlGetBanner, params, this.headers)
      .pipe(map((res) => res));
  }
  getBannersPrincipales() {
    return this.http
      .get(this.baseUrl + this.urlGetBannersPrincipal, this.headers)
      .pipe(map((res) => res));
  }
  getBannersSecundarios() {
    return this.http
      .get(this.baseUrl + this.urlGetBannersSecundarios, this.headers)
      .pipe(map((res) => res));
  }
  getBanner() {
    return this.http
      .get(this.baseUrl + this.urlGetBannersSecundarios, this.headers)
      .pipe(map((res) => res));
  }
}
