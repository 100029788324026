import { Component, TemplateRef } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NotifierService } from "angular-notifier";

import { AppGlobals } from "../app.global";

import { LoginService } from "../services/login.service";
import { DistribuidoresService } from "../services/distribuidores.service";

import { Distribuidor } from "../models/distribuidor";

@Component({
  selector: "distribuidoresAdmin",
  templateUrl: "./distribuidoresAdmin.component.html",
  styleUrls: ["./distribuidoresAdmin.component.css"],
  providers: [LoginService, DistribuidoresService],
})
export class DistribuidoresAdminComponent {
  public dtOptions: any = {};
  public baseURL = AppGlobals.baseURL;
  public identificador;
  public base = AppGlobals.url;
  public distribuidor: Distribuidor;
  public patternEmail =
    "/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/";
  private readonly notifier: NotifierService;

  constructor(
    private loginService: LoginService,
    private distribuidoresService: DistribuidoresService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    notifierService: NotifierService
  ) {
    this.distribuidor = new Distribuidor(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.titleService.setTitle("Distribuidores - Organic Nails");
    this.route.params.forEach((params: Params) => {
      const parametro = localStorage.getItem("token");
      this.identificador = parametro;
      this.loginService.validate(parametro).subscribe(
        (result) => {
          if (result == "false") {
            this.router.navigate(["/login"]);
          }
        },
        (error) => {
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
    });
    this.dtOptions = {
      ajax: this.baseURL + "/Distribuidor/getDistribuidoresCMS",
      columns: [
        { title: "Nombre", data: "nombre" },
        { title: "Código", data: "codigo" },
        {
          title: "Tipo distribuidor",
          data: "tipo_distribuidor",
          mRender(data, type, full) {
            if (data == 1) {
              return "Nacional";
            } else {
              return "Internacional";
            }
          },
        },
        {
          title: "Estatus",
          data: "status",
          mRender(data, type, full) {
            if (data == 1) {
              return '<span id="status"><i class="far fa-check-circle"></i></span>';
            } else {
              return '<span id="status"><i class="far fa-circle"></i></span>';
            }
          },
        },
        {
          title: "Acciones",
          data: "id",
          bSortable: false,
          mRender(data, type, full) {
            return '<span class="accion" id="editar"><i class="fa fa-pencil-alt"></i></span> <span class="accion" id="eliminar" data-toggle="modal" data-target="#modalDelete"><i class="fa fa-trash-alt"></i></span>';
          },
        },
      ],
      pagingType: "simple",
      stateSave: true,
      language: {
        sProcessing: "Procesando...",
        sLengthMenu: "Mostrar _MENU_ registros",
        sZeroRecords: "No se encontraron resultados",
        sEmptyTable: "Ningún dato disponible en esta tabla",
        sInfo:
          "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
        sInfoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
        sInfoFiltered: "(filtrado de un total de _MAX_ registros)",
        sInfoPostFix: "",
        sSearch: "Buscar:",
        sUrl: "",
        sInfoThousands: ",",
        sLoadingRecords: "Cargando...",
        oPaginate: {
          sFirst: "Primero",
          sLast: "Último",
          sNext: "<i class='fa  fa-arrow-right'></i>",
          sPrevious: "<i class='fa fa-arrow-left'></i>",
        },
        oAria: {
          sSortAscending:
            ": Activar para ordenar la columna de manera ascendente",
          sSortDescending:
            ": Activar para ordenar la columna de manera descendente",
        },
      },
      rowCallback: (row: Node, data, index: number) => {
        const self = this;
        $("td>span#status", row).unbind("click");
        $("td>span#status", row).bind("click", () => {
          this.distribuidor = data;
          this.status();
        });
        $("td>span#editar", row).unbind("click");
        $("td>span#editar", row).bind("click", () => {
          localStorage.setItem("element", data.id);
          this.router.navigate(["/editardistribuidor"]);
        });
        $("td>span#eliminar", row).unbind("click");
        $("td>span#eliminar", row).bind("click", () => {
          this.distribuidor = data;
        });
        return row;
      },
      infoCallback(settings, start, end, max, total, pre) {
        if (total == 0) {
          return "Sin registros disponibles";
        }
        return (
          "Mostrando registros del " +
          start +
          " al " +
          end +
          " de un total de " +
          total +
          " registros"
        );
      },
    };
  }

  recargarTable() {
    $("#tableDistribuidores").DataTable().ajax.reload();
  }
  resetDistribuidor() {
    this.distribuidor = new Distribuidor(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
  }

  save() {
    this.distribuidor.usuario = this.identificador;
    this.distribuidoresService.save(this.distribuidor).subscribe(
      (result) => {
        this.notifier.notify(
          "success",
          "¡Exito! La operación se realizo exitosamente"
        );
        setTimeout(() => {
          localStorage.setItem("element", String(result));
          this.router.navigate(["/editardistribuidor"]);
        }, 1500);
      },
      (error) => {
        this.notifier.notify(
          "error",
          "¡Error! La operación no se realizo exitosamente"
        );
        const errorMessage = error as any;
        console.log(errorMessage);
      }
    );
  }
  delete() {
    this.distribuidoresService
      .deleteDistribuidor(this.distribuidor.id)
      .subscribe(
        (result) => {
          if (result) {
            this.notifier.notify(
              "success",
              "¡Exito! La operación se realizo exitosamente"
            );
            this.resetDistribuidor();
            this.recargarTable();
          } else {
            this.notifier.notify(
              "error",
              "¡Error! La operación no se realizo exitosamente"
            );
          }
        },
        (error) => {
          this.notifier.notify(
            "error",
            "¡Error! La operación no se realizo exitosamente"
          );
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
  }

  status() {
    if (this.distribuidor.status == 1) {
      this.distribuidoresService.desactive(this.distribuidor.id).subscribe(
        (result) => {
          if (result) {
            this.resetDistribuidor();
            this.recargarTable();
          }
        },
        (error) => {
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
    } else {
      this.distribuidoresService.active(this.distribuidor.id).subscribe(
        (result) => {
          if (result) {
            this.resetDistribuidor();
            this.recargarTable();
          }
        },
        (error) => {
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
    }
  }
}
