<admindistribuidor-menu></admindistribuidor-menu>
<admindistribuidor-header></admindistribuidor-header>

<notifier-container></notifier-container>

<div class="content-wrapper">
	<section class="content-header">
	  <h1>
	    Cambio de contraseña
	    <small> Control panel</small>
	  </h1>
	  <ol class="breadcrumb">
	    <li><a [routerLink]="['/adminDistribuidor', identificador]"><i class="fa fa-home"></i> Home </a> </li>
	    <li class="">Cambio de contraseña</li>
	  </ol>
	</section>
	<section class="content">
		<form #formAdd="ngForm">
			<div class="row esp">
				<div class="col-lg-3">
					<div class="form-group">
						<label>Contraseña actual <span class="obligatorio">*</span></label><span class="Error" *ngIf="password.touched && !password.valid">Error en el campo</span>
						<input type="password" placeholder="Contraseña actual" class="form-control" name="password" #password="ngModel" [(ngModel)]="pass.password" required />
					</div>
				</div>
			</div>
			<div class="row esp">
				<div class="col-lg-3">
					<div class="form-group">
						<label>Nueva contraseña <span class="obligatorio">*</span></label>
						<input type="password" placeholder="Nueva contraseña" class="form-control" name="new_password" #new_password="ngModel" [(ngModel)]="pass.new_password" required/>
					</div>
				</div>
				<div class="col-lg-3">
					<div class="form-group">
						<label>Confirmar contraseña <span class="obligatorio">*</span></label><span class="Error" *ngIf="validPass">Las contraseñas no son iguales</span>
						<input type="password" placeholder="Confirmar contraseña" class="form-control" name="confirm" #confirm="ngModel" [(ngModel)]="pass.confirm"/>
					</div>
				</div>
			</div>
			<div class="row esp">
				<div class="col-lg-6">
					<button class="btn btnOrganicBlack" [disabled]="!formAdd.form.valid" (click)="save();">Guardar</button>
				</div>
			</div>
		</form>
	</section>
</div>