import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { AdminComponent } from './admin/admin.component';
import { AdmonPromastersComponent } from './admonPromasters/admonPromasters.component';
import { AddPromasterComponent } from './addPromaster/addPromaster.component';
import { EditPromasterComponent } from './editPromaster/editPromaster.component';
import { DistribuidoresAdminComponent } from './distribuidoresAdmin/distribuidoresAdmin.component';
import { EventosAdminComponent } from './eventosAdmin/eventosAdmin.component';
import { EventosAddComponent } from './eventosAdd/eventosAdd.component';
import { EventosEditComponent } from './eventosEdit/eventosEdit.component';
import { CcAdminComponent } from './ccAdmin/ccAdmin.component';
import { CcAddComponent } from './ccAdd/ccAdd.component';
import { CcEditComponent } from './ccEdit/ccEdit.component';
import { DocumentacionAdminComponent } from './documentacionAdmin/documentacionAdmin.component';
import { NewsletterAdminComponent } from './newsletterAdmin/newsletterAdmin.component';
import { LoginDistibuidoresComponent } from './loginDistribuidores/loginDistribuidores.component';
import { DocumentacionDistribuidorComponent } from './documentacionDistribuidor/documentacionDistribuidor.component';
import { CatalogosAdminComponent } from './catalogosAdmin/catalogosAdmin.component';
import { CatalogosEditComponent } from './catalogosEdit/catalogosEdit.component';
import { CategoriasAdminComponent } from './categoriasAdmin/categoriasAdmin.component';
import { CategoriasAddComponent } from './categoriasAdd/categoriasAdd.component';
import { CategoriasEditComponent } from './categoriasEdit/categoriasEdit.component';
import { SubcategoriasAdminComponent } from './subcategoriaAdmin/subcategoriaAdmin.component';
import { SubcategoriaAddComponent } from './subcategoriaAdd/subcategoriaAdd.component';
import { SubcategoriaEditComponent } from './subcategoriaEdit/subcategoriaEdit.component';
import { ProductosAdminComponent } from './productosAdmin/productosAdmin.component';
import { ProductosAddComponent } from './productosAdd/productosAdd.component';
import { ProductosEditComponent } from './productosEdit/productosEdit.component';
import { SeoAdminComponent } from './seoAdmin/seoAdmin.component';
import { DistribuidoresEditComponent } from './distribuidoresEdit/distribuidoresEdit.component';
import { PaisCCoComponent } from './pais-cco/pais-cco.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'login', component: LoginComponent },
  { path: 'ingreso', component: LoginDistibuidoresComponent },
  { path: 'admin', component: AdminComponent },
  { path: 'eventoslist', component: EventosAdminComponent },
  { path: 'eventoadd', component: EventosAddComponent },
  { path: 'eventosEdit', component: EventosEditComponent },
  { path: 'cclist', component: CcAdminComponent },
  { path: 'ccadd', component: CcAddComponent },
  { path: 'ccedit', component: CcEditComponent },
  { path: 'adminpromaster', component: AdmonPromastersComponent },
  { path: 'addPromaster', component: AddPromasterComponent },
  { path: 'editPromaster', component: EditPromasterComponent },
  { path: 'documentacionlist', component: DocumentacionAdminComponent },
  { path: 'documentacion', component: DocumentacionDistribuidorComponent },
  { path: 'productoslist', component: ProductosAdminComponent },
  { path: 'productosadd', component: ProductosAddComponent },
  { path: 'productosedit', component: ProductosEditComponent },
  { path: 'catalogoslist', component: CatalogosAdminComponent },
  { path: 'catalogosEdit', component: CatalogosEditComponent },
  { path: 'categoriaslist', component: CategoriasAdminComponent },
  { path: 'categoriasadd', component: CategoriasAddComponent },
  { path: 'categoriaEdit', component: CategoriasEditComponent },
  { path: 'subcategorialist', component: SubcategoriasAdminComponent },
  { path: 'subcategoriaadd', component: SubcategoriaAddComponent },
  { path: 'subcategoriaedit', component: SubcategoriaEditComponent },
  { path: 'seolist', component: SeoAdminComponent },
  { path: 'newsletterlist', component: NewsletterAdminComponent },
  { path: 'adminDistribuidores', component: DistribuidoresAdminComponent },
  { path: 'editardistribuidor', component: DistribuidoresEditComponent },
  { path: 'paiscco', component: PaisCCoComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
