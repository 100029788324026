export class Promaster{
	constructor(
		public id,
		public fecha_creacion,
		public fecha_edicion,
		public id_usuario,
		public identificador,
		public identificador_promaster,
		public nombre,
		public id_tipo,
		public puesto,
		public descripcion_corta,
		public facebook,
		public instagram,
		public pinterest,
		public whatsapp,
		public frase,
		public detalle_frase,
		public biografia,
		public fotografia_principal,
		public imagen_banner,
		public fotografia_biografia,
		public videos,
		public page_title,
		public meta_description,
		public status,
		public status_delete
	){}
}