import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser'
import { Router, ActivatedRoute, Params } from '@angular/router';

import { LoginService } from '../services/login.service';

@Component({
  selector: 'admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  providers: [LoginService]
})
export class AdminComponent {
	
	constructor(
		private _route: ActivatedRoute,
		private _router: Router,
		private titleService: Title,
		private _loginService: LoginService
	){}
	ngOnInit(){
		this.titleService.setTitle('Administrador - Organic Nails');
		this._route.params.forEach((params: Params) => {
			let parametro = params['page'];
			this._loginService.validate(parametro).subscribe(
				result => {
					if(result=="false"){
						this._router.navigate(['/login']);
					}
				},
				error => {
					var errorMessage = <any>error;
					console.log(errorMessage);
				}
			);
		});
		
	}
	
}
