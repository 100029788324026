export class Evento{
	constructor(
		public id,
		public fecha_creacion,
		public fecha_edicion,
		public id_usuario,
		public fecha_evento,
		public dia,
		public mes,
		public anio,
		public titulo,
		public estado,
		public pais,
		public tema,
		public url,
		public status,
		public identificador
	){}
}