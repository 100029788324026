import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AppGlobals } from '../app.global';

import { LoginService } from '../services/login.service';
import { CatalogoService } from '../services/catalogo.service';

import { Catalogo } from '../models/catalogo';

@Component({
	selector: 'catalogosEdit',
	templateUrl: './catalogosEdit.component.html',
	styleUrls: ['./catalogosEdit.component.css'],
	providers: [LoginService, CatalogoService]
})

export class CatalogosEditComponent {

	@ViewChild('myInput', { static: true }) static; ;
	public myInputVariable;
	public identificador;
	public catalogo: Catalogo;
	public formData = new FormData();
	public bandDesk = true;
	public bandMovil = true;
	public bandTablet = true;
	private readonly notifier: NotifierService;

	constructor(
		private loginService: LoginService,
		private catalogoService: CatalogoService,
		private route: ActivatedRoute,
		private router: Router,
		private titleService: Title,
		notifierService: NotifierService
	) {
		this.catalogo = new Catalogo('', '', '', '', '', {}, {}, {}, '', '', '', '', '', '', '', '', '', '');
		this.notifier = notifierService;
	}

	ngOnInit() {
		this.titleService.setTitle('Editar Catálogo - Organic Nails');
		this.route.params.forEach((params: Params) => {
			const parametro = localStorage.getItem('token');
			const idparametro = localStorage.getItem('element');
			this.identificador = parametro;
			this.loginService.validate(parametro).subscribe(
				result => {
					if (result == 'false') {
						this.router.navigate(['/login']);
					}
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
			this.catalogoService.getCatalogoEdit(idparametro).subscribe(
				result => {
					this.catalogo = result[0];
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
		});
	}

	onChangeMovil(event) {
		const elem = event.target;
		if (elem.files.length > 0) {
			this.formData.append('movil', elem.files[0]);
			this.bandMovil = false;
		}
	}
	onChangeTablet(event) {
		const elem = event.target;
		if (elem.files.length > 0) {
			this.formData.append('tablet', elem.files[0]);
			this.bandTablet = false;
		}
	}
	onChangeDesk(event) {
		const elem = event.target;
		if (elem.files.length > 0) {
			this.formData.append('desk', elem.files[0]);
			this.bandDesk = false;
		}
	}

	saveCatalogo() {
		this.formData.append('id', this.catalogo.id);
		this.formData.append('slogan', this.catalogo.slogan);
		this.formData.append('subtitulo', this.catalogo.subtitulo);
		this.formData.append('info_imagen', this.catalogo.info_imagen);
		this.formData.append('subtitulo', this.catalogo.subtitulo);
		this.formData.append('info_subtitulo', this.catalogo.info_subtitulo);
		this.formData.append('color', this.catalogo.color);
		this.formData.append('identificador', this.identificador);
		this.formData.append('page_title', this.catalogo.page_title);
		this.formData.append('meta_description', this.catalogo.meta_description);
		this.catalogoService.edit(this.formData).subscribe(
			result => {
				if (result) {
					this.notifier.notify('success', '¡Exito! La operación se realizo exitosamente');
					setTimeout(() => {
						this.router.navigate(['/catalogoslist']);
					}, 1500);
				} else {
					this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				}
			},
			error => {
				this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				const errorMessage =  error as any;
				console.log(errorMessage);
			}
		);
	}

}
