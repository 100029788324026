import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { TooltipModule } from 'angular-bootstrap-md';
import { NotifierService } from 'angular-notifier';

import { Banner } from '../models/banner';
import { LoginService } from '../services/login.service';
import { UsuariosService } from '../services/usuarios.service';
import { BannersService } from '../services/banners.service';

import { AppGlobals } from '../app.global';

@Component({
	selector: 'admonBanners',
	templateUrl: './admonBanners.component.html',
	styleUrls: ['./admonBanners.component.css'],
	providers: [LoginService, UsuariosService, BannersService, Md5]

})
export class AdmonBannersComponent {

	@ViewChild('myInput', { static: true }) static; ;
	public myInputVariable;
	public type;
	public dtOptions: any = {};
	public baseURL = AppGlobals.baseURL;
	public identificador;
	public banner: Banner;
	public base = AppGlobals.url;
	public auxOrden;
	private readonly notifier: NotifierService;

	constructor(
		private loginService: LoginService,
		private bannersService: BannersService,
		private route: ActivatedRoute,
		private router: Router,
		private titleService: Title,
		notifierService: NotifierService
	) {
		this.banner = new Banner('', '', '', '', '0', '', '', '', '', '', '', '', '', '', '', '0', {}, {}, {}, '', '', '', '', '', '', '', '', '', '', '', '');
		this.notifier = notifierService;
	}

	ngOnInit() {
		this.titleService.setTitle('Banners - Organic Nails');
		this.route.params.forEach((params: Params) => {
			const parametro = params.page;
			this.identificador = parametro;
			this.loginService.validate(parametro).subscribe(
				result => {
					if (result === 'false') {
						this.router.navigate(['/login']);
					}
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
		});
		this.dtOptions = {
			ajax: this.baseURL + '/Banner/getBannersCMS',
			columns: [
				{
					title: 'Nombre banner',
					data: 'titulo',
					mRender(data, type, full) {
						if (data !== '') {
							return data;
						} else {
							return 'Banner de imagen';
						}
					}
				},
				{
					title: 'Tipo de banner',
					data: 'id_tipo',
					mRender(data, type, full) {
						if (data === '1') {
							return 'Primario';
						} else {
							return 'Secundario';
						}
					}
				},
				{ title: 'Orden', data: 'orden' },
				{
					title: 'Estatus',
					data: 'status',
					mRender(data, type, full) {
						if (data === '1') {
							return '<span id="status"><i class="far fa-check-circle"></i></span>';
						} else {
							return '<span id="status"><i class="far fa-circle"></i></span>';
						}
					}
				},
				{
					title: 'Acciones',
					data: 'id',
					bSortable: false,
					mRender(data, type, full) {
						return '<span class="accion" id="sort" data-toggle="modal" data-target="#modalOrder"><i class="fas fa-sort"></i></span> <span class="accion" id="editar" tooltip="Editar"><i class="fa fa-pencil-alt"></i></span> <span class="accion" id="eliminar" data-toggle="modal" data-target="#modalDelete"><i class="fa fa-trash-alt"></i></span>';
					}
				}
			],
			pagingType: 'simple',
			stateSave: true,
			language: {
				sProcessing: 'Procesando...',
				sLengthMenu: 'Mostrar _MENU_ registros',
				sZeroRecords: 'No se encontraron resultados',
				sEmptyTable: 'Ningún dato disponible en esta tabla',
				sInfo: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
				sInfoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
				sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
				sInfoPostFix: '',
				sSearch: 'Buscar:',
				sUrl: '',
				sInfoThousands: ',',
				sLoadingRecords: 'Cargando...',
				oPaginate: {
					sFirst: 'Primero',
					sLast: 'Último',
					sNext: '<i class=\'fa  fa-arrow-right\'></i>',
					sPrevious: '<i class=\'fa fa-arrow-left\'></i>'
				},
				oAria: {
					sSortAscending: ': Activar para ordenar la columna de manera ascendente',
					sSortDescending: ': Activar para ordenar la columna de manera descendente'
				}
			},
			rowCallback: (row: Node, data, index: number) => {
				const self = this;
				$('td>span#status', row).unbind('click');
				$('td>span#status', row).bind('click', () => {
					this.banner = data;
					this.status();
				});
				$('td>span#sort', row).unbind('click');
				$('td>span#sort', row).bind('click', () => {
					this.banner = data;
					this.auxOrden = data.orden;
				});
				$('td>span#editar', row).unbind('click');
				$('td>span#editar', row).bind('click', () => {
					localStorage.setItem('element', data.id);
					this.router.navigate(['/bannersEdit']);
				});
				$('td>span#eliminar', row).unbind('click');
				$('td>span#eliminar', row).bind('click', () => {
					this.banner = data;
				});
				$('td>span#desktop', row).unbind('click');
				$('td>span#desktop', row).bind('click', () => {
					this.banner = data;
				});
				$('td>span#tablet', row).unbind('click');
				$('td>span#tablet', row).bind('click', () => {
					this.banner = data;
				});
				$('td>span#movil', row).unbind('click');
				$('td>span#movil', row).bind('click', () => {
					this.banner = data;
				});
				return row;
			}
		};
	}
	recargarTable() {
		$('#tableBanners').DataTable().ajax.reload();
	}
	resetBanner() {
		this.banner = new Banner('', '', '', '', '0', '', '', '', '', '', '', '', '', '', '', '0', {}, {}, {}, '', '', '', '', '', '', '', '', '', '', '', '');
	}
	status() {
		if (this.banner.status === '1') {
			this.bannersService.desactive(this.banner.id).subscribe(
				result => {
					if (result) {
						this.resetBanner();
						this.recargarTable();
					}
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
		} else {
			this.bannersService.active(this.banner.id).subscribe(
				result => {
					if (result) {
						this.resetBanner();
						this.recargarTable();
					}
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
		}
	}

	delete() {
		this.bannersService.deleteBanner(this.banner.id).subscribe(
			result => {
				if (result) {
					this.notifier.notify('success', '¡Exito! La operación se realizo exitosamente');
					this.resetBanner();
					this.recargarTable();
				} else {
					this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				}
			},
			error => {
				this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				const errorMessage =  error as any;
				console.log(errorMessage);
			}
		);
	}

	ordenamiento() {
		this.banner.new_order = this.auxOrden;
		this.bannersService.ordenamiento(this.banner).subscribe(
			result => {
				if (result) {
					this.notifier.notify('success', '¡Exito! La operación se realizo exitosamente');
					this.resetBanner();
					this.recargarTable();
				} else {
					this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				}
			},
			error => {
				this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				const errorMessage =  error as any;
				console.log(errorMessage);
			}
		);
	}

}
