import { Component, TemplateRef  } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { NotifierService } from 'angular-notifier';

import { LoginService } from '../services/login.service';
import { EventosService } from '../services/eventos.service';

import { Evento } from '../models/evento';
import { AppGlobals } from '../app.global';

@Component({
  selector: 'eventosAdd',
  templateUrl: './eventosAdd.component.html',
  styleUrls: ['./eventosAdd.component.css'],
  providers: [LoginService, Md5, EventosService]

})
export class EventosAddComponent {

	public baseURL = AppGlobals.baseURL;
	public identificador;
	public base = AppGlobals.url;
	public evento: Evento;
	public minDate = new Date(Date.now());
	public paises;
	public estados;
	private readonly notifier: NotifierService;

	constructor(
		private _loginService: LoginService,
		private _route: ActivatedRoute,
		private _router: Router,
		private titleService: Title,
		private _eventosService: EventosService,
		notifierService: NotifierService
	) {
		this.evento = new Evento('', '', '', '', '', '', '', '', '', '', '', '', '', '', '');
		this.notifier = notifierService;
	}

	ngOnInit() {
		this.titleService.setTitle('Agregar eventos - Organic Nails');
		this._route.params.forEach((params: Params) => {
			const parametro = localStorage.getItem('token');
			this.identificador = parametro;
			this._loginService.validate(parametro).subscribe(
				result => {
					if (result === 'false') {
						this._router.navigate(['/login']);
					}
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);

		});
		this._eventosService.paises().subscribe(
			result => {
				this.paises = result;
			},
			error => {
				const errorMessage =  error as any;
				console.log(errorMessage);
			}
		);
		this._eventosService.estados().subscribe(
			result => {
				this.estados = result;
			},
			error => {
				const errorMessage =  error as any;
				console.log(errorMessage);
			}
		);
	}
	save() {
		const aux = this.evento.fecha_evento;
		this.evento.dia = aux.getDate();
		this.evento.mes = parseInt(aux.getMonth(), 10);
		this.evento.anio = aux.getFullYear();
		this.evento.identificador = this.identificador;
		this._eventosService.save(this.evento).subscribe(
			result => {
				if (result) {
					this.notifier.notify( 'success', '¡Exito! La operación se realizo exitosamente' );
					setTimeout(() => {
					    this._router.navigate(['/eventoslist']);
					 }, 1500);

				}
			},
			error => {
				this.notifier.notify( 'error', '¡Error! La operación no se realizo exitosamente' );
				const errorMessage =  error as any;
				console.log(errorMessage);
			}
		);
	}
}
