import { Component, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { NotifierService } from 'angular-notifier';

import { AppGlobals } from '../app.global';

import { LoginService } from '../services/login.service';
import { CategoriasService } from '../services/categorias.service';
import { SubcategoriaService } from '../services/subcategoria.service';

import { Subcategoria } from '../models/subcategoria';

@Component({
	selector: 'subcategoriaAdmin',
	templateUrl: './subcategoriaAdmin.component.html',
	styleUrls: ['./subcategoriaAdmin.component.css'],
	providers: [LoginService, Md5, CategoriasService, SubcategoriaService]

})

export class SubcategoriasAdminComponent {

	public subcategoria: Subcategoria;
	public dtOptions: any = {};
	public baseURL = AppGlobals.baseURL;
	public identificador;
	public base = AppGlobals.url;
	public auxOrden;
	public patternEmail = '/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/';
	private readonly notifier: NotifierService;

	constructor(
		private loginService: LoginService,
		private categoriasService: CategoriasService,
		private subcategoriaService: SubcategoriaService,
		private route: ActivatedRoute,
		private router: Router,
		private titleService: Title,
		notifierService: NotifierService
	) {
		this.subcategoria = new Subcategoria('', '', '', '', -1, -1, '', '', '', '', '', '', '', '', '', '');
		this.notifier = notifierService;
	}

	ngOnInit() {
		this.titleService.setTitle('Subcategorías - Organic Nails');
		this.route.params.forEach((params: Params) => {
			const parametro = localStorage.getItem('token');
			this.identificador = parametro;
			this.loginService.validate(parametro).subscribe(
				result => {
					if (result === 'false') {
						this.router.navigate(['/login']);
					}
				},
				error => {
					const errorMessage = error as any;
					console.log(errorMessage);
				}
			);
		});
		this.dtOptions = {
			ajax: this.baseURL + '/Subcategorias/getSubcategoriasCMS',
			columns: [
				{ title: 'Nombre', data: 'titulo' },
				{ title: 'Categoría', data: 'nombre_categoria' },
				{ title: 'Catálogo', data: 'nombre_catalogo' },
				{ title: 'Orden', data: 'orden' },
				{
					title: 'Estatus',
					data: 'status',
					mRender(data, type, full) {
						if (data == 1) {
							return '<span id="status"><i class="far fa-check-circle"></i></span>';
						} else {
							return '<span id="status"><i class="far fa-circle"></i></span>';
						}
					}
				},
				{
					title: 'Acciones',
					data: 'id',
					bSortable: false,
					mRender(data, type, full) {
						return '<span class="accion" id="sort" data-toggle="modal" data-target="#modalOrder"><i class="fas fa-sort"></i></span> <span class="accion" id="editar"><i class="fa fa-pencil-alt"></i></span> <span class="accion" id="eliminar" data-toggle="modal" data-target="#modalDelete"><i class="fa fa-trash-alt"></i></span>';
					}
				}
			],
			pagingType: 'simple',
			stateSave: true,
			language: {
				sProcessing: 'Procesando...',
				sLengthMenu: 'Mostrar _MENU_ registros',
				sZeroRecords: 'No se encontraron resultados',
				sEmptyTable: 'Ningún dato disponible en esta tabla',
				sInfo: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
				sInfoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
				sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
				sInfoPostFix: '',
				sSearch: 'Buscar:',
				sUrl: '',
				sInfoThousands: ',',
				sLoadingRecords: 'Cargando...',
				oPaginate: {
					sFirst: 'Primero',
					sLast: 'Último',
					sNext: '<i class=\'fa  fa-arrow-right\'></i>',
					sPrevious: '<i class=\'fa fa-arrow-left\'></i>'
				},
				oAria: {
					sSortAscending: ': Activar para ordenar la columna de manera ascendente',
					sSortDescending: ': Activar para ordenar la columna de manera descendente'
				}
			},
			rowCallback: (row: Node, data, index: number) => {
				const self = this;
				$('td>span#status', row).unbind('click');
				$('td>span#status', row).bind('click', () => {
					this.subcategoria = data;
					this.status();
				});
				$('td>span#editar', row).unbind('click');
				$('td>span#editar', row).bind('click', () => {
					localStorage.setItem('element', data.id);
					this.router.navigate(['/subcategoriaedit']);
				});
				$('td>span#sort', row).unbind('click');
				$('td>span#sort', row).bind('click', () => {
					this.subcategoria = data;
					this.auxOrden = data.orden;
				});
				$('td>span#eliminar', row).unbind('click');
				$('td>span#eliminar', row).bind('click', () => {
					this.subcategoria = data;
				});
				return row;
			},
			infoCallback(settings, start, end, max, total, pre) {
				if (total == 0) { return 'Sin registros disponibles'; }
				return 'Mostrando registros del ' + start + ' al ' + end + ' de un total de ' + total + ' registros';
			}
		};
	}

	recargarTable() {
		$('#tableSubcategorías').DataTable().ajax.reload();
	}

	resetSubcategoria() {
		this.subcategoria = new Subcategoria('', '', '', '', -1, -1, '', '', '', '', '', '', '', '', '', '');
	}

	delete() {
		this.subcategoriaService.delete(this.subcategoria.id).subscribe(
			result => {
				if (result) {
					this.notifier.notify('success', '¡Exito! La operación se realizo exitosamente');
					this.resetSubcategoria();
					this.recargarTable();
				} else {
					this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				}
			},
			error => {
				this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				const errorMessage = error as any;
				console.log(errorMessage);
			}
		);
	}


	status() {
		if (this.subcategoria.status == 1) {
			this.subcategoriaService.desactive(this.subcategoria.id).subscribe(
				result => {
					if (result) {
						this.resetSubcategoria();
						this.recargarTable();
					}
				},
				error => {
					const errorMessage = error as any;
					console.log(errorMessage);
				}
			);
		} else {
			this.subcategoriaService.active(this.subcategoria.id).subscribe(
				result => {
					if (result) {
						this.resetSubcategoria();
						this.recargarTable();
					}
				},
				error => {
					const errorMessage = error as any;
					console.log(errorMessage);
				}
			);
		}
	}

	ordenamiento() {
		this.subcategoria.new_order = this.auxOrden;
		this.subcategoriaService.ordenamiento(this.subcategoria).subscribe(
			result => {
				if (result) {
					this.notifier.notify('success', '¡Exito! La operación se realizo exitosamente');
					this.resetSubcategoria();
					this.recargarTable();
				} else {
					this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				}
			},
			error => {
				this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				const errorMessage = error as any;
				console.log(errorMessage);
			}
		);
	}
}
