<admin-header></admin-header>
<admin-menu></admin-menu>

<notifier-container></notifier-container>

<div class="content-wrapper">
	<section class="content-header">
	  <h1>
	    Productos
	    <small>Control panel</small>
	  </h1>
	  <ol class="breadcrumb">
	    <li><a [routerLink]="['/admin']"><i class="fa fa-home"></i> Home</a></li>
	    <li class="">Productos</li>
	  </ol>
	</section>
	<section class="content">
		<div class="row">
			<div class="col-lg-8 text-center">
				
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12 table-responsive">
				<table id="tableProductos" style="width: 100%;" datatable [dtOptions]="dtOptions" class="row-border hover"></table>
			</div>
		</div>
	</section>
	<a [routerLink]="['/productosadd']">
		<img class="add"  src="../../assets/img/circle-add.svg" alt="" mdbTooltip="Agregar nuevo registro">
	</a>
</div>

<div class="modal fade" id="modalDelete" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Eliminar registro</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				  <span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row">
			    	<div class="col-lg-12 col-xs-12 text-center">
			    		<h3>¿Deseas eliminar el registro ?</h3>
			    	</div>
			    </div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn btnOrganicBlack" data-dismiss="modal" (click)="delete();">Aceptar</button>
			</div>
        </div>
    </div>
</div>


<div class="modal fade" id="modalUploadImagen" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">   
			<div class="modal-header">
				<h5 class="modal-title">Subir imagen</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				  <span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row">
			    	<div class="col-lg-12 col-xs-12 ">
			    		<label>Imagen</label>
			    		<input #myInput type="file" placeholder="File Name" name="filename" (change)="onChangeSecundaria($event)" class="form-control">
			    	</div>
			    </div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn btnOrganicBlack" data-dismiss="modal" (click)="saveImagen();">Aceptar</button>
			</div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalVerImagenes" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Imagenes secundarias</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				  <span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row">
			    	<div class="col-lg-12 col-xs-12 ">
			    		<mdb-carousel class="carousel slide carousel-fade" [animation]="'fade'">
						  <mdb-carousel-item *ngFor="let i of producto.otras_imagenes">
						  	<div class="delete" (click)="deleteImage(i.id)" data-dismiss="modal">
						  		<i class="fas fa-trash-alt"></i>
						  	</div>
						    <img class="d-block w-100" src="{{i.imagen}}">
						  </mdb-carousel-item>
						</mdb-carousel>
			    	</div>
			    </div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn btnOrganicBlack" data-dismiss="modal">Cerrar</button>
			</div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalOrder" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Ordenamiento</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				  <span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row">
			    	<div class="col-lg-12 col-xs-12">
			    		<label for="nombre">Orden <span class="obligatorio">*</span></label><span class="Error" *ngIf="orden.touched && !orden.valid">Error en el campo</span>
						<input type="email" class="form-control" placeholder="Nombre" name="orden" #orden="ngModel" [(ngModel)]="auxOrden" required>
			    	</div>
			    </div>
			</div>
			<div class="modal-footer">
				<a (click)="ordenamiento();"  class="btn btnOrganicBlack" data-dismiss="modal">Aceptar</a>
			</div>
        </div>
    </div>
</div>