import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppGlobals } from '../app.global';

@Injectable()
export class ProductoService {
  public baseUrl: string;
  public urlGetProducto: string;
  public urlEdit: string;
  public urlGetProductos: string;
  public urlSave: string;
  public urlInactive: string;
  public urlActive: string;
  public urlDelete: string;
  public urlGetPorCatalogo: string;
  public urlGetPorCategoria: string;
  public urlGetRelacionados: string;
  public urlSaveImagenes: string;
  public urlDeleteImagen: string;
  public urlOrdenamiento: string;

  public headers = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
  };

  constructor(public http: HttpClient) {
    this.baseUrl = AppGlobals.baseURL;
    this.urlGetProducto = 'Producto/getProducto';
    this.urlEdit = 'Producto/editProducto';
    this.urlSave = 'Producto/addProducto';
    this.urlInactive = 'Producto/inactiveProducto';
    this.urlActive = 'Producto/activeProducto';
    this.urlDelete = 'Producto/deleteProducto';
    this.urlGetPorCatalogo = 'Producto/getProductosCategoriasFront';
    this.urlGetPorCategoria = 'Producto/getProductosSubcategoriasFront';
    this.urlGetRelacionados = 'Producto/getSomeProductosFront';
    this.urlSaveImagenes = 'Producto/addImagenProducto';
    this.urlDeleteImagen = 'Producto/deleteImagenSecundaria';
    this.urlOrdenamiento = 'Producto/updateOrder';
  }

  save(producto) {
    const json = encodeURIComponent(JSON.stringify(producto));
    const params = 'json=' + json;

    return this.http
      .post(this.baseUrl + this.urlSave, producto)
      .pipe(map((res) => res));
  }

  edit(producto) {
    const json = encodeURIComponent(JSON.stringify(producto));
    const params = 'json=' + json;

    return this.http
      .post(this.baseUrl + this.urlEdit, producto)
      .pipe(map((res) => res));
  }

  getProducto(id) {
    const json = JSON.stringify(id);
    const params = 'json=' + json;

    return this.http
      .post(this.baseUrl + this.urlGetProducto, params, this.headers)
      .pipe(map((res) => res));
  }

  desactive(id) {
    const json = JSON.stringify(id);
    const params = 'json=' + json;

    return this.http
      .post(this.baseUrl + this.urlInactive, params, this.headers)
      .pipe(map((res) => res));
  }

  active(id) {
    const json = JSON.stringify(id);
    const params = 'json=' + json;

    return this.http
      .post(this.baseUrl + this.urlActive, params, this.headers)
      .pipe(map((res) => res));
  }

  delete(id) {
    const json = JSON.stringify(id);
    const params = 'json=' + json;

    return this.http
      .post(this.baseUrl + this.urlDelete, params, this.headers)
      .pipe(map((res) => res));
  }

  getCategoriasProductos(catalogo) {
    const json = JSON.stringify(catalogo);
    const params = 'json=' + json;

    return this.http
      .post(this.baseUrl + this.urlGetPorCatalogo, params, this.headers)
      .pipe(map((res) => res));
  }

  getProductos(catalogo) {
    const json = JSON.stringify(catalogo);
    const params = 'json=' + json;

    return this.http
      .get(this.baseUrl + this.urlGetPorCategoria + '?json=' + catalogo)
      .pipe(map((res) => res));
  }

  getRelacionados(idSubcategoria) {
    const json = JSON.stringify(idSubcategoria);
    const params = 'json=' + json;

    return this.http
      .post(this.baseUrl + this.urlGetRelacionados, params, this.headers)
      .pipe(map((res) => res));
  }

  saveImagenes(imagen) {
    const json = JSON.stringify(imagen);
    const params = 'json=' + json;

    return this.http
      .post(this.baseUrl + this.urlSaveImagenes, imagen)
      .pipe(map((res) => res));
  }

  deleteImagen(id) {
    const json = JSON.stringify(id);
    const params = 'json=' + json;

    return this.http
      .post(this.baseUrl + this.urlDeleteImagen, params, this.headers)
      .pipe(map((res) => res));
  }

  ordenamiento(producto) {
    const json = encodeURIComponent(JSON.stringify(producto));
    const params = 'json=' + json;

    return this.http
      .post(this.baseUrl + this.urlOrdenamiento, params, this.headers)
      .pipe(map((res) => res));
  }
}
