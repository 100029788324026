import { Component, ViewChild, ElementRef, NgZone } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Md5 } from "ts-md5/dist/md5";
import { NotifierService } from "angular-notifier";
import { MapsAPILoader } from "@agm/core";
import { MouseEvent as AGMMouseEvent } from "@agm/core";

import { AppGlobals } from "../app.global";

import { FormControl } from "@angular/forms";
/// <reference types="@types/googlemaps" />

import { LoginService } from "../services/login.service";
import { DistribuidoresService } from "../services/distribuidores.service";

import { Distribuidor } from "../models/distribuidor";

@Component({
  selector: "distribuidoresEdit",
  templateUrl: "./distribuidoresEdit.component.html",
  styleUrls: ["./distribuidoresEdit.component.css"],
  providers: [LoginService, Md5, DistribuidoresService],
})
export class DistribuidoresEditComponent {
  private readonly notifier: NotifierService;

  public latitude: number;
  public longitude: number;
  public isViewable: boolean;
  public loading: boolean;

  public lat: number;
  public lng: number;

  public searchControl: FormControl;
  public zoom: number;
  @ViewChild("search", { static: true }) search;
  public searchElementRef: ElementRef;
  public geoCoder;

  public distri: Distribuidor;
  public identificador;
  public base = AppGlobals.url;
  @ViewChild("myInput", { static: true }) static;
  public myInputVariable;
  public config = {
    removeButtons:
      "Source,Save,NewPage,DocProps,Preview,Print,Templates,document,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Image,Flash,Table,HorizontalRule,Smiley,PageBreak,Iframe,CreateDiv,BidiLtr,BidiRtl,About,ShowBlocks,Font,UIColor,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Superscript,RemoveFormat,CreatePlaceholder,Styles",
  };

  constructor(
    private loginService: LoginService,
    private distribuidoresService: DistribuidoresService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    notifierService: NotifierService
  ) {
    this.distri = new Distribuidor(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.titleService.setTitle("Editar distribuidor - Organic Nails");
    this.route.params.forEach((params: Params) => {
      const parametro = localStorage.getItem("token");
      const idParametro = localStorage.getItem("element");
      this.identificador = parametro;
      this.loginService.validate(parametro).subscribe(
        (result) => {
          if (result === "false") {
            this.router.navigate(["/login"]);
          }
        },
        (error) => {
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
      this.distribuidoresService.getDistribuidor(idParametro).subscribe(
        (result) => {
          this.distri = result[0];
          this.distri = result[0];
          const aux = this.distri.coordenadas.split(",");
          this.lat = parseFloat(aux[0]);
          this.lng = parseFloat(aux[1]);
          this.zoom = 17;
          if (aux[0] !== "NaN" || aux[0] !== "") {
            this.isViewable = true;
          } else {
            this.isViewable = false;
          }
          /*this.lat = 23.4046239;
					this.lng = -102.6920737;
    				this.zoom = 4;*/
        },
        (error) => {
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
    });

    // create search FormControl
    this.searchControl = new FormControl();

    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      this.geoCoder = new google.maps.Geocoder();
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {
          types: ["address"],
        }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          const aux = place.formatted_address.split('"');
          const aux1 = aux[0].split(", ");
          this.distri.pais = aux1[aux1.length - 1];
          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.distri.domicilio = place.formatted_address;
          // set latitude, longitude and zoom
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.zoom = 17;
        });
      });
    });
  }
  show_map() {
    this.loading = true;
    this.geoCoder.geocode(
      {
        address:
          this.distri.calle +
          " " +
          this.distri.exterior +
          " " +
          this.distri.colonia +
          " " +
          this.distri.estado +
          " " +
          this.distri.pais,
      },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.isViewable = true;
            this.loading = false;
            this.zoom = 17;
            this.lat = results[0].geometry.location.lat();
            this.lng = results[0].geometry.location.lng();
          }
        }
      }
    );
  }
  markerDragEnd($event: AGMMouseEvent) {
    this.lat = $event.coords.lat;
    this.lng = $event.coords.lng;
    this.getAddress(this.lat, this.lng);
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode(
      { location: { lat: latitude, lng: longitude } },
      (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            this.zoom = 17;
          } else {
            window.alert("No results found");
          }
        } else {
          window.alert("Geocoder failed due to: " + status);
        }
      }
    );
  }
  save() {
    this.distri.coordenadas = this.lat + "," + this.lng;
    this.distri.codigo = this.identificador;
    this.distribuidoresService.edit(this.distri).subscribe(
      (result) => {
        this.notifier.notify(
          "success",
          "¡Exito! La operación se realizo exitosamente"
        );
        setTimeout(() => {
          this.router.navigate(["/adminDistribuidores"]);
        }, 1500);
      },
      (error) => {
        this.notifier.notify(
          "error",
          "¡Error! La operación no se realizo exitosamente"
        );
        const errorMessage = error as any;
        console.log(errorMessage);
      }
    );
  }
}
