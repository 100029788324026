import { Component, TemplateRef } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Md5 } from "ts-md5/dist/md5";
import { NotifierService } from "angular-notifier";

import { LoginService } from "../services/login.service";
import { CcService } from "../services/cc.service";
import { PaisesService } from '../services/paises.service';

import { Cc } from "../models/cc";
import { AppGlobals } from "../app.global";

@Component({
  selector: "ccEdit",
  templateUrl: "./ccEdit.component.html",
  styleUrls: ["./ccEdit.component.css"],
  providers: [LoginService, Md5, CcService, PaisesService],
})
export class CcEditComponent {
  public dtOptions: any = {};
  public baseURL = AppGlobals.baseURL;
  public identificador;
  public base = AppGlobals.url;
  public CC;
  public onlyNumber = "^([(0-9) ]*)$";
  private readonly notifier: NotifierService;
  public paises: any;

  constructor(
    private loginService: LoginService,
    private ccService: CcService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    notifierService: NotifierService,
    private paisService: PaisesService
  ) {
    this.CC = {};
    this.notifier = notifierService;
    this.paises = [];
  }

  ngOnInit() {
    this.titleService.setTitle(
      "Editar centros de capacitación - Organic Nails"
    );
    this.route.params.forEach((params: Params) => {
      const parametro = localStorage.getItem("token");
      const id = localStorage.getItem("element");
      this.identificador = parametro;
      this.loginService.validate(parametro).subscribe(
        (result) => {
          if (result === "false") {
            this.router.navigate(["/login"]);
          }
        },
        (error) => {
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
      this.ccService.getCentro(id).subscribe(
        (result) => {
          this.CC = result[0];
          this.CC.id = id;
        },
        (error) => {
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
      this.getPaises();
    });
  }
  onFileChange(e) {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      this.CC.imagen_principal = file;
    }
  }
  getPaises() {
    this.paisService.getPaisesActive().subscribe(
      (result) => {
        this.paises = result;
      },
      (error) => {
        const errorMessage = error as any;
        console.log(errorMessage);
      }
    )
  }
  save() {
    const formAdd = new FormData();
    formAdd.append("id", this.CC.id);
    formAdd.append("titulo", this.CC.titulo);
    formAdd.append("nombre", this.CC.nombre);
    formAdd.append("zona", this.CC.zona);
    formAdd.append("telefono", this.CC.telefono);
    formAdd.append("direccion", this.CC.direccion);
    formAdd.append("horarios", this.CC.horarios);
    formAdd.append("whatsapp", this.CC.whatsapp);
    formAdd.append("email", this.CC.email);
    formAdd.append("url_mapa", this.CC.url_mapa);
    formAdd.append("id_pais", this.CC.id_pais);
    formAdd.append("identificador", this.identificador);
    formAdd.append("imagen_principal", this.CC.imagen_principal);
    this.ccService.edit(formAdd).subscribe(
      (result) => {
        if (result) {
          this.notifier.notify(
            "success",
            "¡Exito! La operación se realizo exitosamente"
          );
          setTimeout(() => {
            this.router.navigate(["/cclist"]);
          }, 1500);
        }
      },
      (error) => {
        this.notifier.notify(
          "error",
          "¡Error! La operación no se realizo exitosamente"
        );
        const errorMessage = error as any;
        console.log(errorMessage);
      }
    );
  }
}
