import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';


import { User } from '../models/user';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [LoginService, Md5]
})
export class LoginComponent {

	public patternEmail = '/^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;';
	public user: User;

	constructor(
		private _loginService: LoginService,
		private _route: ActivatedRoute,
		private _router: Router,
		private titleService: Title,
		private _md5: Md5
	) {
		this.user = new User('', '');
	}

	ngOnInit() {
		this.titleService.setTitle('Login - Organic Nails');
	}

	LogIn() {
		const hash = String(Md5.hashStr(this.user.email));
		localStorage.setItem('token', hash);
		this._loginService.login(this.user).subscribe(
			result => {
				if (result) {
					if (result !== 'not-found') {
						this._router.navigate(['/admin']);
					}
				}
			},
			error => {
				const errorMessage =  error as any;
				console.log(errorMessage);
			}
		);
	}

}
