export class Banner{
	constructor(
		public id,
		public fecha_creacion,
		public fecha_edicion,
		public id_usuario,
		public id_tipo,
		public titulo,
		public subtitulo,
		public color1,
		public color2,
		public color_over,
		public color_hover,
		public id_dispositivo,
		public tiene_boton,
		public texto_boton,
		public url,
		public posicion_texto,
		public desk,
		public tablet,
		public movil,
		public img_desk,
		public img_desk_1,
		public img_movil,
		public img_movil_1,
		public img_tablet,
		public img_tablet_1,
		public img_fondo,
		public blank,
		public new_order,
		public orden,
		public status,
		public status_delete
	){}
}