import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser'
import { Router, ActivatedRoute, Params } from '@angular/router';

import { LoginService } from '../services/login.service';

@Component({
	selector: 'adminDistribuidor',
	templateUrl: './adminDistribuidor.component.html',
	styleUrls: ['./adminDistribuidor.component.css'],
	providers: [LoginService]
})
export class AdminDistribuidorComponent {

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private titleService: Title,
		private loginService: LoginService
	) { }
	ngOnInit() {
		this.titleService.setTitle('Distribuidor - Organic Nails');
		this.route.params.forEach((params: Params) => {
			let parametro = localStorage.getItem('token');
			this.loginService.valDistribuidor(parametro).subscribe(
				result => {
					if (result == "false") {
						this.router.navigate(['/ingreso']);
					}
				},
				error => {
					var errorMessage = <any>error;
					console.log(errorMessage);
				}
			);
		});

	}

}
