import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppGlobals } from '../app.global';

@Injectable()
export class SeoService {
	public baseUrl: string;
	public urlEdit: string;
	public getSeo: string;

	public headers = {
		headers: new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded',
		})
	};

	constructor(
		public http: HttpClient
	) {
		this.baseUrl = AppGlobals.baseURL;
		this.urlEdit = 'Seo/editPagina';
		this.getSeo = 'Seo/getPagina';
	}

	edit(seo) {
		const json = encodeURIComponent(JSON.stringify(seo));
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlEdit, params, this.headers)
			.pipe(map(res => res));
	}

	getSeoItem(id) {
		const json = JSON.stringify(id);
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.getSeo, params, this.headers)
			.pipe(map(res => res));
	}
}
