<aside class="main-sidebar">
  <div class="sidebar">
    <ul class="sidebar-menu" data-widget="tree">
    	<!--<li>
        <a href="{{base}}/usuarios/}}" [routerLinkActive]="['active']" title="Home">
          <i class="fas fa-user-circle"></i> <span>Usuarios</span>
        </a>
      </li>
      <li>
        <a href="#">
          <i class="fas fa-user-circle"></i> <span>Otro</span>
        </a>
      </li>-->
      <li>
        <a [routerLink]="['/eventoslist']" title="Eventos">
          <i class="far fa-calendar-alt"></i> <span> Eventos</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/cclist']" title="Centros de capacitación">
          <i class="fas fa-building"></i> <span> C. de capacitación</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/paiscco']" title="Paises CCO">
          <i class="fas fa-truck"></i> <span> Paises CCO</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/adminpromaster']" title="Promasters">
          <i class="fas fa-users"></i> <span> Promasters</span>
        </a>
      </li>
      <!--<li>
        <a [routerLink]="['/banners']" title="Banners">
          <i class="fas fa-images"></i> <span> Banners</span>
        </a>
      </li>-->
      <li>
        <a [routerLink]="['/adminDistribuidores']" title="Distribuidores">
          <i class="fas fa-truck"></i> <span> Distribuidores</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/documentacionlist']" title="Documentación">
          <i class="fas fa-file-alt"></i> <span> Documentación</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/productoslist']" title="Productos">
          <i class="fas fa-archive"></i> <span> Productos</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/catalogoslist']" title="Catálogos">
          <i class="fab fa-audible"></i> <span> Catálogos</span>
        </a>
      </li>
       <li>
        <a [routerLink]="['/categoriaslist']" title="Categorías">
          <i class="fas fa-sitemap"></i> <span> Categorías</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/subcategorialist']" title="Categorías">
          <i class="fab fa-linode"></i> <span> Subcategorías</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/seolist']" title="SEO">
          <i class="fas fa-chart-line"></i> <span> SEO</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/newsletterlist']" title="Newsletter">
          <i class="fas fa-newspaper"></i> <span> Newsletter</span>
        </a>
      </li>
    </ul>
  </div>
</aside>
