import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { LoginService } from '../services/login.service';

@Component({
  selector: 'admindistribuidor-header',
  templateUrl: './adminDistribuidorHeader.component.html',
  styleUrls: ['./adminDistribuidorHeader.component.css'],
  providers: [LoginService]
})
export class AdminDistribuidorHeaderComponent {
	public cont = 0;
	public usuario: string;

	constructor(
		private loginService: LoginService,
		private route: ActivatedRoute,
		private router: Router
	) {}

	ngOnInit() {
	}
	LogOut() {
		this.route.params.forEach((params: Params) => {
			const parametro = localStorage.getItem('token');
			this.loginService.logoutDistribuidor(parametro).subscribe(
				result => {
					if (result == 'true') {
						this.router.navigate(['/ingreso']);
					}
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
		});
	}
}
