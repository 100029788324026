<admin-header></admin-header>
<admin-menu></admin-menu>

<notifier-container></notifier-container>

<div class="content-wrapper">
	<section class="content-header">
	  <h1>
	    Documentación para distribuidores
	    <small> Control panel</small>
	  </h1>
	  <ol class="breadcrumb">
	    <li><a [routerLink]="['/admin']"><i class="fa fa-home"></i> Home </a> </li>
	    <li class="">Documentación para distribuidores</li>
	  </ol>
	</section>
	<section class="content">
		<div class="row">
			<div class="col-lg-8 text-center">
				
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12 table-responsive">
				<table id="tableDocs" style="width: 100%;" datatable [dtOptions]="dtOptions" class="row-border hover"></table>
			</div>
		</div>
	</section>
	<a data-toggle="modal" data-target="#modalAgregar">
		<img class="add"  src="../../assets/img/circle-add.svg" alt="" mdbTooltip="Agregar nuevo registro">
	</a>
</div>

<div class="modal fade" id="modalAgregar" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Agregar</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				  <span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row">
			    	<div class="col-lg-12 col-xs-12">
			    		<div class="form-group">
						    <label for="exampleInputEmail1">Nombre <span class="obligatorio">*</span></label>
						    <input class="form-control" name="nombre" #nombre="ngModel" [(ngModel)]="documentacion.nombre" required>
						</div>
			    	</div>
			    </div>
			    <!--<div class="row">
			    	<div class="col-lg-12 col-xs-12">
			    		<div class="form-group">
						    <label for="exampleInputEmail1">Tipo <span class="obligatorio">*</span></label>
						    <select class="form-control" name="tipo" #tipo="ngModel" [(ngModel)]="documentacion.tipo" required>
						    	<option [ngValue]="0">Selecciona</option>
						    </select>
						</div>
			    	</div>
			    </div>-->
			    <div class="row">
			    	<div class="col-lg-12 col-xs-12">
			    		<div class="form-group">
						    <label for="exampleInputEmail1">Documento <span class="obligatorio">*</span></label>
						    <input class="form-control" #myInput type="file" name="filename" (change)="onChangeDocumento($event)">
						</div>
			    	</div>
			    </div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn btnOrganicBlack" data-dismiss="modal" (click)="save();">Guardar</button>
			</div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalEditar" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Editar</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				  <span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row">
			    	<div class="col-lg-12 col-xs-12">
			    		<div class="form-group">
						    <label for="exampleInputEmail1">Nombre <span class="obligatorio">*</span></label>
						    <input class="form-control" name="nombre" #nombre="ngModel" [(ngModel)]="documentacion.nombre" required>
						</div>
			    	</div>
			    </div>
			    <div class="row">
			    	<div class="col-lg-12 col-xs-12">
			    		<div class="form-group">
						    <label for="exampleInputEmail1">Documento actual</label>
						    <a style="margin-left: 10px;" class="accion" href="{{documentacion.documento}}" target="_blank"><i class="fas fa-download"></i></a>
						</div>
			    	</div>
			    </div>
			    <div class="row">
			    	<div class="col-lg-12 col-xs-12">
			    		<div class="form-group">
						    <label for="exampleInputEmail1">Documento <span class="obligatorio">*</span></label>
						    <input class="form-control" #myInput type="file" name="filename" [(ngModel)]="documentacion.documento" (change)="onChangeDocumento($event)">
						</div>
			    	</div>
			    </div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn btnOrganicBlack" data-dismiss="modal" (click)="editar();">Guardar</button>
			</div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalDelete" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Eliminar</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				  <span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row">
			    	<div class="col-lg-12 col-xs-12 text-center">
			    		<h3>¿Deseas eliminar el registro con el nombre "<b>{{documentacion.nombre}}</b>" ?</h3>
			    	</div>
			    </div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn btnOrganicBlack" data-dismiss="modal" (click)="delete();">Aceptar</button>
			</div>
        </div>
    </div>
</div>