<aside class="main-sidebar">
  <div class="sidebar">
    <ul class="sidebar-menu" data-widget="tree">
    	<!--<li>
        <a href="{{base}}/usuarios/{{identificador}}" [routerLinkActive]="['active']" title="Home">
          <i class="fas fa-user-circle"></i> <span>Usuarios</span>
        </a>
      </li>
      <li>
        <a href="#">
          <i class="fas fa-user-circle"></i> <span>Otro</span>
        </a>
      </li>-->
      <li>
        <a [routerLink]="['/documentacion']" title="Documentación">
          <i class="fas fa-file-alt"></i> <span> Documentación</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/editarperfil']" title="Editar perfil">
          <i class="fas fa-edit"></i> <span> Editar perfil</span>
        </a>
      </li>
      <li>
        <a [routerLink]="['/cambiarpass']" title="Cambiar contraseña">
          <i class="fas fa-exchange-alt"></i> <span> Cambiar contraseña</span>
        </a>
      </li>
    </ul>
  </div>
</aside>