<admindistribuidor-menu></admindistribuidor-menu>
<admindistribuidor-header></admindistribuidor-header>

<notifier-container></notifier-container>

<div class="content-wrapper">
  <div class="content-header">
    <h1>
      Editar perfil
      <small>Control panel</small>
    </h1>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/adminDistribuidor']"
          ><i class="fa fa-home"></i> Home</a
        >
      </li>
      <li class="">Editar perfil</li>
    </ol>
  </div>
  <section class="content">
    <form #formAdd="ngForm">
      <div class="row esp">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="nombre">Nombre <span class="obligatorio">*</span></label
            ><span class="Error" *ngIf="nombre.touched && !nombre.valid"
              >Error en el campo</span
            >
            <input
              type="text"
              class="form-control"
              placeholder="Nombre"
              name="nombre"
              #nombre="ngModel"
              [(ngModel)]="distri.nombre"
              required
            />
          </div>
        </div>
      </div>
      <div class="row esp">
        <div class="col-lg-3">
          <div class="form-group">
            <label for="correo"
              >Correo electronico <span class="obligatorio">*</span></label
            ><span class="Error" *ngIf="correo.touched && !correo.valid"
              >Error en el campo</span
            >
            <input
              type="text"
              class="form-control"
              placeholder="Correo electronico"
              name="correo"
              #correo="ngModel"
              [(ngModel)]="distri.correo"
              required
            />
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label for="telefono"
              >Teléfono <span class="obligatorio">*</span></label
            ><span class="Error" *ngIf="telefono.touched && !telefono.valid"
              >Error en el campo</span
            >
            <input
              type="text"
              placeholder="Teléfono"
              class="form-control"
              name="telefono"
              #telefono="ngModel"
              [(ngModel)]="distri.telefono"
              required
            />
          </div>
        </div>
      </div>
      <div class="row esp">
        <div class="col-lg-3">
          <div class="form-group">
            <label for="horario">Horarios</label>
            <textarea
              type="email"
              class="form-control"
              placeholder="Horarios"
              name="horario"
              #horario="ngModel"
              [(ngModel)]="distri.horario"
            ></textarea>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label for="whatsapp">WhatsApp</label>
            <input
              type="text"
              class="form-control"
              placeholder="WhatsApp"
              name="whatsapp"
              #whatsapp="ngModel"
              [(ngModel)]="distri.whatsapp"
            />
          </div>
        </div>
      </div>
      <div class="row esp">
        <div class="col-lg-6">
          <label for="cp">Dirección</label>
          <div class="form-group">
            <input
              placeholder="Busca tu dirección"
              autocorrect="off"
              autocapitalize="off"
              spellcheck="off"
              type="text"
              class="form-control"
              #search
              [formControl]="searchControl"
            />
          </div>
          <!--<agm-map [latitude]="latitude" [longitude]="longitude" [scrollwheel]="false" [zoom]="zoom">
			        	<agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
			      	</agm-map>-->
          <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
          </agm-map>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <button
            class="btn btnOrganicBlack"
            [disabled]="!formAdd.form.valid"
            (click)="save()"
          >
            Guardar
          </button>
        </div>
      </div>
    </form>
  </section>
</div>
