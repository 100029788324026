import {
  Component,
  TemplateRef,
  ViewChild,
  ElementRef,
  NgZone,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { NotifierService } from "angular-notifier";

import { AppGlobals } from "../app.global";

import { AgmCoreModule, MapsAPILoader } from "@agm/core";
import { FormControl } from "@angular/forms";
/// <reference types="@types/googlemaps" />

import { LoginService } from "../services/login.service";
import { DistribuidoresService } from "../services/distribuidores.service";

import { Distribuidor } from "../models/distribuidor";

@Component({
  selector: "distribuidoresAdd",
  templateUrl: "./distribuidoresAdd.component.html",
  styleUrls: ["./distribuidoresAdd.component.css"],
  providers: [LoginService, DistribuidoresService],
})
export class DistribuidoresAddComponent {
  private readonly notifier: NotifierService;

  public latitude: number;
  public longitude: number;

  public lat: number;
  public lng: number;

  public searchControl: FormControl;
  public zoom: number;
  @ViewChild("search", { static: true }) search;
  public searchElementRef: ElementRef;

  public distri: Distribuidor;
  public identificador;
  public base = AppGlobals.url;
  @ViewChild("myInput", { static: true }) static;
  public myInputVariable;
  public config = {
    removeButtons:
      "Source,Save,NewPage,DocProps,Preview,Print,Templates,document,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Image,Flash,Table,HorizontalRule,Smiley,PageBreak,Iframe,CreateDiv,BidiLtr,BidiRtl,About,ShowBlocks,Font,UIColor,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Superscript,RemoveFormat,CreatePlaceholder,Styles",
  };

  constructor(
    private loginService: LoginService,
    private distribuidoresService: DistribuidoresService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    notifierService: NotifierService
  ) {
    this.distri = new Distribuidor(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.titleService.setTitle("Editar perfil - Organic Nails");
    this.route.params.forEach((params: Params) => {
      const parametro = localStorage.getItem("token");
      this.identificador = parametro;
      this.loginService.valDistribuidor(parametro).subscribe(
        (result) => {
          if (result == "false") {
            this.router.navigate(["/login"]);
          } else {
            this.distri = result[0];
            const aux = this.distri.coordenadas.split(",");
            this.lat = parseFloat(aux[0]);
            this.lng = parseFloat(aux[1]);
            this.zoom = 17;
          }
        },
        (error) => {
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
    });

    // create search FormControl
    this.searchControl = new FormControl();

    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.searchElementRef.nativeElement,
        {
          types: ["address"],
        }
      );
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();
          const aux = place.formatted_address.split('"');
          const aux1 = aux[0].split(", ");
          this.distri.pais = aux1[aux1.length - 1];
          // verify result
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          this.distri.domicilio = place.formatted_address;
          // set latitude, longitude and zoom
          this.lat = place.geometry.location.lat();
          this.lng = place.geometry.location.lng();
          this.zoom = 17;
        });
      });
    });
  }
  save() {
    this.distri.coordenadas = this.lat + "," + this.lng;
    this.distri.codigo = this.identificador;
    this.distribuidoresService.edit(this.distri).subscribe(
      (result) => {
        this.notifier.notify(
          "success",
          "¡Exito! La operación se realizo exitosamente"
        );
        setTimeout(() => {
          window.location.reload();
        }, 1500);
      },
      (error) => {
        this.notifier.notify(
          "error",
          "¡Error! La operación no se realizo exitosamente"
        );
        const errorMessage = error as any;
        console.log(errorMessage);
      }
    );
  }
}
