export class Documentacion{
	constructor(
		public id,
		public fecha_creacion,
		public fecha_edicion,
		public id_usuario,
		public identificador,
		public nombre,
		public id_tipo_documento,
		public documento,
		public status,
		public status_delete
	){}
}