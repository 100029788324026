<header class="main-header">
  <a class="logo">
    <span class="logo-mini"><img src="../assets/logo_ON_32x32.jpg" alt=""></span>
    <span class="logo-lg"><img src="../assets/logo_ON_150x50.jpg" alt=""></span>
  </a>
  <nav class="navbar navbar-static-top">
    <a href="#" class="sidebar-toggle" data-toggle="push-menu" role="button">
      <i class="fas fa-bars"></i>
      <span class="sr-only">Toggle navigation</span>
    </a>
    <div class="navbar-custom-menu">
      <ul class="nav navbar-nav nav-flex-icons ml-auto">
        <!--<li class="dropdown user user-menu">
          <a href="#" class="dropdown-toggle" data-toggle="dropdown">
            <i class="far fa-user"></i>
            <span class="hidden-xs">{{usuario}}</span>
          </a>
          <ul class="dropdown-menu">
            <li class="user-footer">
              <div class="pull-left">
                <a href="#" class="btn btn-default btn-flat">Profile</a>
              </div>
              <div class="pull-right">
                <a href="#" class="btn btn-default btn-flat" (click)="cerrarSesion();">Sign out</a>
              </div>
            </li>
          </ul>
        </li>-->
        <li class="nav-item">
          <a (click)="LogOut();" class="logout">
              <i class="fas fa-sign-out-alt"></i> Salir
          </a>
        </li>
      </ul>
    </div>
  </nav>
</header>