<admin-header></admin-header>
<admin-menu></admin-menu>

<notifier-container></notifier-container>

<div class="content-wrapper">
	<div class="content-header">
	  <h1>
	    Editar subcategoría
	    <small>Control panel</small>
	  </h1>
	  <ol class="breadcrumb">
	    <li><a [routerLink]="['/admin']"><i class="fa fa-home"></i> Home</a></li>
	    <li class=""><a [routerLink]="['/subcategorialist']">Subcategorías</a></li>
	    <li class="">Editar subcategoría</li>
	  </ol>
	</div>
	<section class="content">
		<form #formAdd="ngForm">
			<!--Titulos-->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Título <span class="obligatorio">*</span></label><span class="Error" *ngIf="titulo.touched && !titulo.valid">Error en el campo</span>
					    <input type="text" class="form-control" placeholder="Título" name="titulo" #titulo="ngModel" [(ngModel)]="subcategoria.titulo" required>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Información del título <span class="obligatorio">*</span></label><span class="Error" *ngIf="info_titulo.touched && !info_titulo.valid">Error en el campo</span>
					    <textarea type="text" class="form-control" placeholder="Información banner" name="info_titulo" #info_titulo="ngModel" [(ngModel)]="subcategoria.info_titulo" required></textarea>
					</div>
				</div>
			</div>
			<!-- Catalogo y categoria -->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Catálogo <span class="obligatorio">*</span></label><span class="Error" *ngIf="id_catalogo.touched && !id_catalogo.valid">Error en el campo</span>
					    <select class="form-control" name="id_catalogo" #id_catalogo="ngModel" [(ngModel)]="subcategoria.id_catalogo" (change)="getCategorias()">
					    	<option value="-1">Selecciona</option>
					    	<option *ngFor="let c of catalogos" [ngValue]="c.id">{{c.nombre}}</option>
					    </select>
					</div>
				</div>
				<div class="col-md-3">
					<label for="nombre">Categoría <span class="obligatorio">*</span></label><span class="Error" *ngIf="id_categoria.touched && !id_categoria.valid">Error en el campo</span>
				    <select class="form-control" name="id_categoria" #id_categoria="ngModel" [(ngModel)]="subcategoria.id_categoria" [disabled]="subcategoria.id_catalogo==-1">
				    	<option value="-1">Selecciona</option>
				    	<option *ngFor="let cat of categorias" [ngValue]="cat.id">{{cat.nombre}}</option>
				    </select>
				</div>
			</div>
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">URL (organic.com/subcategoria/) <span class="obligatorio">*</span></label><span class="Error" *ngIf="identificador_subcat.touched && !identificador_subcat.valid">Error en el campo</span>
					    <input type="text" class="form-control" placeholder="URL" name="identificador_subcat" #identificador_subcat="ngModel" [(ngModel)]="subcategoria.identificador_subcat" required>
					</div>
				</div>
			</div>
			<!--SEO-->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Page Title <span class="obligatorio">*</span></label><span class="Error" *ngIf="page_title.touched && !page_title.valid">Error en el campo</span>
					    <input type="text" class="form-control" placeholder="Page Title" name="page_title" #page_title="ngModel" [(ngModel)]="subcategoria.page_title" required>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Meta description <span class="obligatorio">*</span></label><span class="Error" *ngIf="meta_description.touched && !meta_description.valid">Error en el campo</span>
					    <input type="text" class="form-control" placeholder="Meta description" name="meta_description" #meta_description="ngModel" [(ngModel)]="subcategoria.meta_description" required>
					</div>
				</div>
			</div>
			<div class="row espForms">
				<div class="col-md-3">
					<button class="btn btnOrganicBlack" (click)="saveSubcategoria();">Guardar</button>
				</div>
			</div>
		</form>
	</section>

