<div class="container">
    <div class="card card-container">
        <img id="profile-img" class="profile-img-card" src="../../assets/logo.png" />
        <p id="profile-name" class="profile-name-card"></p>
        <form class="form-signin" #formLogin="ngForm">
            <span id="reauth-email" class="reauth-email"></span>
            <input type="email" class="form-control" placeholder="Correo electrónico" name="email" #email="ngModel" [(ngModel)]="user.email" required pattern={{patternEmail}}>
            <input type="password" class="form-control" placeholder="Contraseña" name="password" #password="ngModel" [(ngModel)]="user.password" required>
            <input class="btn btn-lg btn-block btn-signin" type="submit"  value="Ingresar" (click)="LogIn();">
        </form>
        <a href="#" class="forgot-password">
            ¿Olvidaste tu contraseña?
        </a>
    </div>
</div>