import { Component, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { NotifierService } from 'angular-notifier';

import { LoginService } from '../services/login.service';
import { DistribuidoresService } from '../services/distribuidores.service';

import { changePassDistribuidor } from '../models/changePassDistribuidor';



@Component({
	selector: 'cambiarPasswordDistibuidor',
	templateUrl: './cambiarPasswordDistribuidor.component.html',
	styleUrls: ['./cambiarPasswordDistribuidor.component.css'],
	providers: [LoginService, Md5, DistribuidoresService]

})
export class CambiarPasswordDistribuidorComponent {

	public identificador;
	public validPass = false;
	public pass: changePassDistribuidor;
	private readonly notifier: NotifierService;

	constructor(
		private loginService: LoginService,
		private route: ActivatedRoute,
		private router: Router,
		private titleService: Title,
		notifierService: NotifierService
	) {
		this.pass = new changePassDistribuidor('', '', '', '');
		this.notifier = notifierService;
	}

	ngOnInit() {
		this.titleService.setTitle('Cambiar contraseña - Organic Nails');
		this.route.params.forEach((params: Params) => {
			const parametro = localStorage.getItem('token');
			this.identificador = parametro;
			this.loginService.validate(parametro).subscribe(
				result => {
					if (result == 'false') {
						this.router.navigate(['/login']);
					}
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
		});
	}
	resetPass() {
		this.pass = new changePassDistribuidor('', '', '', '');
	}
	save() {
		this.pass.identificador = this.identificador;
		if (this.pass.confirm != this.pass.new_password || this.pass.new_password === '') {
			this.validPass = true;
		} else {
			this.validPass = false;
			this.loginService.chengePassDis(this.pass).subscribe(
				result => {
					this.notifier.notify('success', '¡Exito! La operación se realizo exitosamente');
					this.resetPass();
				},
				error => {
					this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
		}
	}
}
