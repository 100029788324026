export class Distribuidor {
  constructor(
    public id,
    public fecha_creacion,
    public fecha_edicion,
    public id_usuario,
    public nombre,
    public tipo_distribuidor,
    public correo,
    public codigo,
    public password,
    public uncode_password,
    public nuevo_password,
    public usuario,
    public identificador,
    public whatsapp,
    public telefono,
    public domicilio,
    public calle,
    public exterior,
    public interior,
    public postal,
    public estado,
    public municipio,
    public ciudad,
    public colonia,
    public sitio_web,
    public pais,
    public coordenadas,
    public horario,
    public status,
    public status_delete,
    public login_in
  ) {}
}
