import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { NotifierService } from 'angular-notifier';

import { AppGlobals } from '../app.global';

import { LoginService } from '../services/login.service';
import { BannersService } from '../services/banners.service';

import { Banner } from '../models/banner';

@Component({
	selector: 'bannersAdd',
	templateUrl: './bannersAdd.component.html',
	styleUrls: ['./bannersAdd.component.css'],
	providers: [LoginService, Md5, BannersService]
})

export class BannersAddComponent {

	@ViewChild('myInput', { static: true }) static; ;
	public myInputVariable;
	public identificador;
	public base = AppGlobals.url;
	public banner: Banner;
	public formData = new FormData();
	private readonly notifier: NotifierService;


	constructor(
		private loginService: LoginService,
		private bannersService: BannersService,
		private route: ActivatedRoute,
		private router: Router,
		private titleService: Title,
		private md5: Md5,
		notifierService: NotifierService
	) {
		this.banner = new Banner('', '', '', '', '0', '', '', '', '', '', '', '', '', '', '', '0', {}, {}, {}, '', '', '', '', '', '', '', '', '', '', '', '');
		this.notifier = notifierService;
	}

	ngOnInit() {
		this.titleService.setTitle('Agregar Banners - Organic Nails');
		this.route.params.forEach((params: Params) => {
			const parametro = localStorage.getItem('token');
			this.identificador = parametro;
			this.loginService.validate(parametro).subscribe(
				result => {
					if (result === 'false') {
						this.router.navigate(['/login']);
					}
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
		});
	}

	/*Fondo*/
	onChangeFondo(event) {
		const elem = event.target;
		if (elem.files.length > 0) {
			this.formData.append('fondo', elem.files[0]);
			this.banner.desk = elem.files[0];
		}
	}
	/*Desk*/
	onChangeDeskI(event) {
		const elem = event.target;
		if (elem.files.length > 0) {
			this.formData.append('desk_i', elem.files[0]);
			this.banner.desk = elem.files[0];
		}
	}
	onChangeDeskD(event) {
		const elem = event.target;
		if (elem.files.length > 0) {
			this.formData.append('desk_d', elem.files[0]);
			this.banner.desk = elem.files[0];
		}
	}
	/*Tablet*/
	onChangeTabletI(event) {
		const elem = event.target;
		if (elem.files.length > 0) {
			this.formData.append('tablet_i', elem.files[0]);
			this.banner.tablet = elem.files[0];
		}
	}
	onChangeTabletD(event) {
		const elem = event.target;
		if (elem.files.length > 0) {
			this.formData.append('tablet_d', elem.files[0]);
			this.banner.tablet = elem.files[0];
		}
	}

	/*Movil*/
	onChangeMovilI(event) {
		const elem = event.target;
		if (elem.files.length > 0) {
			this.formData.append('movil_i', elem.files[0]);
			this.banner.movil = elem.files[0];
		}
	}
	onChangeMovilD(event) {
		const elem = event.target;
		if (elem.files.length > 0) {
			this.formData.append('movil_d', elem.files[0]);
			this.banner.movil = elem.files[0];
		}
	}


	saveBanner() {
		this.formData.append('titulo', this.banner.titulo);
		this.formData.append('subtitulo', this.banner.subtitulo);
		this.formData.append('color1', this.banner.color1);
		this.formData.append('color2', this.banner.color2);
		this.formData.append('color_over', this.banner.color_over);
		this.formData.append('color_hover', this.banner.color_hover);
		this.formData.append('tiene_boton', this.banner.tiene_boton);
		this.formData.append('texto_boton', this.banner.texto_boton);
		this.formData.append('url', this.banner.url);
		this.formData.append('posicion_texto', this.banner.posicion_texto);
		this.formData.append('id_tipo', this.banner.id_tipo);
		this.formData.append('blank', this.banner.blank);
		this.formData.append('identificador', this.identificador);

		this.bannersService.save(this.formData).subscribe(
			result => {
				if (result) {
					this.notifier.notify('success', '¡Exito! La operación se realizo exitosamente');
					setTimeout(() => {
						this.router.navigate(['/banners']);
					}, 1500);
				} else {
					this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				}
			},
			error => {
				this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				const errorMessage =  error as any;
				console.log(errorMessage);
			}
		);
	}
}
