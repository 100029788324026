import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';

import { AppGlobals } from '../app.global';

import { LoginService } from '../services/login.service';
import { DocumentacionService } from '../services/documentacion.service';

import { Documentacion } from '../models/documentacion';

@Component({
	selector: 'documentacionDistribuidor',
	templateUrl: './documentacionDistribuidor.component.html',
	styleUrls: ['./documentacionDistribuidor.component.css'],
	providers: [LoginService, Md5, DocumentacionService]

})

export class DocumentacionDistribuidorComponent {

	public dtOptions: any = {};
	public baseURL = AppGlobals.baseURL;
	public identificador;

	constructor(
		private loginService: LoginService,
		private documentacionService: DocumentacionService,
		private route: ActivatedRoute,
		private router: Router,
		private titleService: Title
	) { }

	ngOnInit() {
		this.titleService.setTitle('Documentación - Organic Nails');
		this.route.params.forEach((params: Params) => {
			const parametro = localStorage.getItem('token');
			this.identificador = parametro;
			this.loginService.valDistribuidor(parametro).subscribe(
				result => {
					if (result == 'false') {
						this.router.navigate(['/ingreso']);
					}
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
		});
		this.dtOptions = {
			ajax: this.baseURL + '/Documentos_distribuidores/getDocumentosCMS',
			columns: [
				{ title: 'Nombre', data: 'nombre' },
				{
					title: 'Documento',
					data: 'archivo',
					mRender(data, type, full) {
						return '<a class="accion" href="' + data + '" target="_blank"><i class="fas fa-download"></i></a>';
					}
				},
			],
			pagingType: 'simple',
			stateSave: true,
			language: {
				sProcessing: 'Procesando...',
				sLengthMenu: 'Mostrar _MENU_ registros',
				sZeroRecords: 'No se encontraron resultados',
				sEmptyTable: 'Ningún dato disponible en esta tabla',
				sInfo: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
				sInfoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
				sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
				sInfoPostFix: '',
				sSearch: 'Buscar:',
				sUrl: '',
				sInfoThousands: ',',
				sLoadingRecords: 'Cargando...',
				oPaginate: {
					sFirst: 'Primero',
					sLast: 'Último',
					sNext: '<i class=\'fa  fa-arrow-right\'></i>',
					sPrevious: '<i class=\'fa fa-arrow-left\'></i>'
				},
				oAria: {
					sSortAscending: ': Activar para ordenar la columna de manera ascendente',
					sSortDescending: ': Activar para ordenar la columna de manera descendente'
				}
			},
			infoCallback(settings, start, end, max, total, pre) {
				if (total == 0) { return 'Sin registros disponibles'; }
				return 'Mostrando registros del ' + start + ' al ' + end + ' de un total de ' + total + ' registros';
			}
		};
	}
}
