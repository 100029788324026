import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AppGlobals } from '../app.global';

import { LoginService } from '../services/login.service';
import { CategoriasService } from '../services/categorias.service';
import { CatalogoService } from '../services/catalogo.service';
import { SubcategoriaService } from '../services/subcategoria.service';

import { Subcategoria } from '../models/subcategoria';

@Component({
	selector: 'subcategoriaAdd',
	templateUrl: './subcategoriaAdd.component.html',
	styleUrls: ['./subcategoriaAdd.component.css'],
	providers: [LoginService, CategoriasService, CatalogoService, SubcategoriaService]
})

export class SubcategoriaAddComponent {

	@ViewChild('myInput', { static: true }) static;
	public myInputVariable;
	public identificador;
	public catalogos;
	public categorias;
	public base = AppGlobals.url;
	public subcategoria: Subcategoria;
	public formData = new FormData();
	public bandDesk = true;
	public bandMovil = true;
	public bandTablet = true;
	public bandMiniatura = true;
	private readonly notifier: NotifierService;

	constructor(
		private loginService: LoginService,
		private categoriasService: CategoriasService,
		private subcategoriaService: SubcategoriaService,
		private catalogoService: CatalogoService,
		private route: ActivatedRoute,
		private router: Router,
		private titleService: Title,
		notifierService: NotifierService
	) {
		this.subcategoria = new Subcategoria('', '', '', '', -1, -1, '', '', '', '', '', '', '', '', '', '');
		this.notifier = notifierService;
	}

	ngOnInit() {
		this.titleService.setTitle('Agregar Subcategoría - Organic Nails');
		this.route.params.forEach((params: Params) => {
			const parametro = localStorage.getItem('token');
			this.identificador = parametro;
			this.loginService.validate(parametro).subscribe(
				result => {
					if (result == 'false') {
						this.router.navigate(['/login']);
					}
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
		});
		this.catalogoService.getCatalogos().subscribe(
			result => {
				this.catalogos = result;
			},
			error => {
				this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				const errorMessage =  error as any;
				console.log(errorMessage);
			}
		);
	}

	getCategorias() {
		if (this.subcategoria.id_catalogo != -1) {
			this.categoriasService.getCategorias(this.subcategoria.id_catalogo).subscribe(
				result => {
					this.categorias = result;
				},
				error => {
					this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
		} else {
			this.subcategoria.id_categoria = -1;
		}
	}

	saveSubcategoria() {
		this.subcategoria.identificador = this.identificador;
		this.subcategoriaService.save(this.subcategoria).subscribe(
			result => {
				if (result) {
					this.notifier.notify('success', '¡Exito! La operación se realizo exitosamente');
					setTimeout(() => {
						this.router.navigate(['/subcategorialist']);
					}, 1500);
				} else {
					this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				}
			},
			error => {
				this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				const errorMessage =  error as any;
				console.log(errorMessage);
			}
		);
	}

}
