import { Component, ViewEncapsulation, ViewChild, ElementRef, PipeTransform, Pipe, OnInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";

@Pipe({name: 'youtube'})
export class YoutubePipe implements PipeTransform {

	constructor(private sanitizer: DomSanitizer) { }
	transform(url) {
		let aux = url.split("watch?v=");
		let link  = aux[0]+'embed/'+aux[1];
	    return this.sanitizer.bypassSecurityTrustResourceUrl(link);
	}
}