export class Subcategoria{
	constructor(
		public id,
		public id_usuario,
		public fecha_creacion,
		public fecha_edicion,
		public id_catalogo,
		public id_categoria,
		public titulo,
		public info_titulo,
		public identificador,
		public identificador_subcat,
		public page_title,
		public meta_description,
		public new_order,
		public orden,
		public status,
		public status_delete
	){}
}