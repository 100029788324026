import { Component, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AppGlobals } from '../app.global';

import { LoginService } from '../services/login.service';

@Component({
	selector: 'catalogosAdmin',
	templateUrl: './catalogosAdmin.component.html',
	styleUrls: ['./catalogosAdmin.component.css'],
	providers: [LoginService]

})
export class CatalogosAdminComponent {

	public dtOptions: any = {};
	public baseURL = AppGlobals.baseURL;
	public identificador;
	public base = AppGlobals.url;
	public patternEmail = '/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/';
	private readonly notifier: NotifierService;

	constructor(
		private loginService: LoginService,
		private route: ActivatedRoute,
		private router: Router,
		private titleService: Title,
		notifierService: NotifierService
	) {
		this.notifier = notifierService;
	}

	ngOnInit() {
		this.titleService.setTitle('Catálogos - Organic Nails');
		this.route.params.forEach((params: Params) => {
			const parametro = localStorage.getItem('token');
			this.identificador = parametro;
			this.loginService.validate(parametro).subscribe(
				result => {
					if (result == 'false') {
						this.router.navigate(['/login']);
					}
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
		});
		this.dtOptions = {
			ajax: this.baseURL + '/Catalogos/getCatalogosCMS',
			columns: [
				{ title: 'Nombre', data: 'nombre' },
				{
					title: 'Acciones',
					data: 'id',
					bSortable: false,
					mRender(data, type, full) {
						return '<span class="accion" id="editar"><i class="fa fa-pencil-alt"></i></span>';
					}
				}
			],
			pagingType: 'simple',
			stateSave: true,
			language: {
				sProcessing: 'Procesando...',
				sLengthMenu: 'Mostrar _MENU_ registros',
				sZeroRecords: 'No se encontraron resultados',
				sEmptyTable: 'Ningún dato disponible en esta tabla',
				sInfo: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
				sInfoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
				sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
				sInfoPostFix: '',
				sSearch: 'Buscar:',
				sUrl: '',
				sInfoThousands: ',',
				sLoadingRecords: 'Cargando...',
				oPaginate: {
					sFirst: 'Primero',
					sLast: 'Último',
					sNext: '<i class=\'fa  fa-arrow-right\'></i>',
					sPrevious: '<i class=\'fa fa-arrow-left\'></i>'
				},
				oAria: {
					sSortAscending: ': Activar para ordenar la columna de manera ascendente',
					sSortDescending: ': Activar para ordenar la columna de manera descendente'
				}
			},
			rowCallback: (row: Node, data, index: number) => {
				const self = this;
				$('td>span#status', row).unbind('click');
				$('td>span#status', row).bind('click', () => {
					// this.status();
				});
				$('td>span#editar', row).unbind('click');
				$('td>span#editar', row).bind('click', () => {
					localStorage.setItem('element', data.id);
					this.router.navigate(['/catalogosEdit']);
				});
				$('td>span#eliminar', row).unbind('click');
				$('td>span#eliminar', row).bind('click', () => {
				});
				return row;
			},
			infoCallback(settings, start, end, max, total, pre) {
				if (total == 0) { return 'Sin registros disponibles'; }
				return 'Mostrando registros del ' + start + ' al ' + end + ' de un total de ' + total + ' registros';
			}
		};
	}

	recargarTable() {
		$('#tableDistribuidores').DataTable().ajax.reload();
	}
	resetDistribuidor() {
	}
}
