import { Component, TemplateRef } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { Md5 } from "ts-md5/dist/md5";
import { NotifierService } from "angular-notifier";

import { AppGlobals } from "../app.global";

import { LoginService } from "../services/login.service";
import { NewsletterService } from "../services/newsletter.service";

import { Newsletter } from "../models/newsletter";

@Component({
  selector: "newsletterAdmin",
  templateUrl: "./newsletterAdmin.component.html",
  styleUrls: ["./newsletterAdmin.component.css"],
  providers: [LoginService, Md5, NewsletterService],
})
export class NewsletterAdminComponent {
  public dtOptions: any = {};
  public baseURL = AppGlobals.baseURL;
  public identificador;
  public base = AppGlobals.url;
  public newsletter: Newsletter;
  private readonly notifier: NotifierService;

  constructor(
    private loginService: LoginService,
    private newsletterService: NewsletterService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    notifierService: NotifierService
  ) {
    this.newsletter = new Newsletter("", "", "", "", "", "");
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.titleService.setTitle("Newsletter - Organic Nails");
    this.route.params.forEach((params: Params) => {
      const parametro = localStorage.getItem("token");
      this.identificador = parametro;
      this.loginService.validate(parametro).subscribe(
        (result) => {
          if (result === "false") {
            this.router.navigate(["/login"]);
          }
        },
        (error) => {
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
    });
    this.dtOptions = {
      ajax: this.baseURL + "/Newsletter/getNewsletterCMS",
      columns: [
        {
          title: "Nombre",
          data: "nombre",
          mRender(data, type, full) {
            if (data === "") {
              return "N/D";
            } else {
              return data;
            }
          },
        },
        { title: "Email", data: "email" },
        {
          title: "Teléfono",
          data: "telefono",
          mRender(data, type, full) {
            if (data === "0") {
              return "N/D";
            } else {
              return data;
            }
          },
        },
        {
          title: "Estatus",
          data: "status",
          mRender(data, type, full) {
            if (data == 1) {
              return '<span id="status"><i class="far fa-check-circle"></i></span>';
            } else {
              return '<span id="status"><i class="far fa-circle"></i></span>';
            }
          },
        },
        {
          title: "Acciones",
          data: "id",
          bSortable: false,
          mRender(data, type, full) {
            return '<span class="accion" id="eliminar" data-toggle="modal" data-target="#modalDelete"><i class="fa fa-trash-alt"></i></span>';
          },
        },
      ],
      dom: 'Bfrtip',
      // Configure the buttons
      buttons: [
        'columnsToggle',
        'colvis',
        'copy',
        'print',
        'excel',
        {
          text: 'Some button',
          key: '1',
          action: function (e, dt, node, config) {
            alert('Button activated');
          }
        }
      ],
      pagingType: "simple",
      stateSave: true,
      language: {
        sProcessing: "Procesando...",
        sLengthMenu: "Mostrar _MENU_ registros",
        sZeroRecords: "No se encontraron resultados",
        sEmptyTable: "Ningún dato disponible en esta tabla",
        sInfo:
          "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
        sInfoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
        sInfoFiltered: "(filtrado de un total de _MAX_ registros)",
        sInfoPostFix: "",
        sSearch: "Buscar:",
        sUrl: "",
        sInfoThousands: ",",
        sLoadingRecords: "Cargando...",
        oPaginate: {
          sFirst: "Primero",
          sLast: "Último",
          sNext: "<i class='fa  fa-arrow-right'></i>",
          sPrevious: "<i class='fa fa-arrow-left'></i>",
        },
        oAria: {
          sSortAscending:
            ": Activar para ordenar la columna de manera ascendente",
          sSortDescending:
            ": Activar para ordenar la columna de manera descendente",
        },
      },
      rowCallback: (row: Node, data, index: number) => {
        const self = this;
        $("td>span#status", row).unbind("click");
        $("td>span#status", row).bind("click", () => {
          this.newsletter = data;
          this.status();
        });
        $("td>span#editar", row).unbind("click");
        $("td>span#editar", row).bind("click", () => {});
        $("td>span#eliminar", row).unbind("click");
        $("td>span#eliminar", row).bind("click", () => {
          this.newsletter = data;
        });
        return row;
      },
      infoCallback(settings, start, end, max, total, pre) {
        if (total == 0) {
          return "Sin registros disponibles";
        }
        return (
          "Mostrando registros del " +
          start +
          " al " +
          end +
          " de un total de " +
          total +
          " registros"
        );
      },
    };
  }

  delete() {
    this.newsletterService.deleteNewsletter(this.newsletter.id).subscribe(
      (result) => {
        if (result) {
          this.notifier.notify(
            "success",
            "¡Exito! La operación se realizo exitosamente"
          );
          this.resetNewsletter();
          this.recargarTable();
        } else {
          this.notifier.notify(
            "error",
            "¡Error! La operación no se realizo exitosamente"
          );
        }
      },
      (error) => {
        this.notifier.notify(
          "error",
          "¡Error! La operación no se realizo exitosamente"
        );
        const errorMessage = error as any;
        console.log(errorMessage);
      }
    );
  }

  status() {
    if (this.newsletter.status == 1) {
      this.newsletterService.desactive(this.newsletter.id).subscribe(
        (result) => {
          if (result) {
            this.resetNewsletter();
            this.recargarTable();
          }
        },
        (error) => {
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
    } else {
      this.newsletterService.active(this.newsletter.id).subscribe(
        (result) => {
          if (result) {
            this.resetNewsletter();
            this.recargarTable();
          }
        },
        (error) => {
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
    }
  }

  resetNewsletter() {
    this.newsletter = new Newsletter("", "", "", "", "", "");
  }
  recargarTable() {
    $("#tableNews").DataTable().ajax.reload();
  }
}
