<admin-header></admin-header>
<admin-menu></admin-menu>

<notifier-container></notifier-container>

<div class="content-wrapper">
	<div class="content-header">
	  <h1>
	    Editar Eventos
	    <small>Control panel</small>
	  </h1>
	  <ol class="breadcrumb">
	    <li><a [routerLink]="['/admin']"><i class="fa fa-home"></i> Home</a></li>
	    <li class=""><a [routerLink]="['/eventoslist']">Eventos</a></li>
	    <li class="">Editar Eventos</li>
	  </ol>
	</div>
	<section class="content">
		<form #formAdd="ngForm">
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
						<label>Título <span class="obligatorio">*</span></label>
						<input type="text" class="form-control" placeholder="Título" name="titulo" #titulo="ngModel" [(ngModel)]="evento.titulo"/>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
						<label>Tema <span class="obligatorio">*</span></label>
						<input type="text" class="form-control" placeholder="Tema" name="tema" #tema="ngModel" [(ngModel)]="evento.tema"/>
					</div>
				</div>
			</div>
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
						<label>País <span class="obligatorio">*</span></label>
						<select class="form-control" name="pais" #pais="ngModel" [(ngModel)]="evento.pais">
							<option value="">Selecciona</option>
							<option *ngFor="let pais of paises" value="{{pais.name}}">{{pais.name}}</option>
						</select>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
						<label>Localidad <span class="obligatorio">*</span></label>
						<input class="form-control" placeholder="Localidad" name="estado" #estado="ngModel" [(ngModel)]="evento.estado">
					</div>
				</div>
			</div>
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
						<label>Fecha <span class="obligatorio">*</span></label>
						<mat-form-field>
						    <input matInput [min]="minDate" [matDatepicker]="dp3" disabled name="fecha_evento" #fecha_evento="ngModel" [(ngModel)]="evento.fecha_evento">
						    <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
						    <mat-datepicker #dp3 disabled="false"></mat-datepicker>
						</mat-form-field>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
						<label>URL <span class="obligatorio">*</span></label>
						<input type="text" class="form-control" placeholder="URL" name="url" #url="ngModel" [(ngModel)]="evento.url"/>
					</div>
				</div>
			</div>
			<div class="row espForms">
				<div class="col-md-3">
					<button class="btn btnOrganicBlack" (click)="save();">Guardar</button>
				</div>
			</div>
		</form>
	</section>

