<admin-header></admin-header>
<admin-menu></admin-menu>

<notifier-container></notifier-container>

<div class="content-wrapper">
	<div class="content-header">
	  <h1>
	    Editar producto
	    <small>Control panel</small>
	  </h1>
	  <ol class="breadcrumb">
	    <li><a [routerLink]="['/admin', identificador]"><i class="fa fa-home"></i> Home</a></li>
	    <li class=""><a [routerLink]="['/productoslist', identificador]">Productos </a></li>
	    <li class="">Editar producto</li>
	  </ol>
	</div>
	<section class="content">
		<form #formAdd="ngForm">
			<!--Titulos-->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Nombre <span class="obligatorio">*</span></label>
					    <input type="text" class="form-control" placeholder="Nombre" name="nombre" #titulo="ngModel" [(ngModel)]="producto.nombre" required>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">URL <span class="obligatorio">*</span></label>
					    <input type="text" class="form-control" placeholder="identificador_producto (url)" name="identificador_producto" #titulo="ngModel" [(ngModel)]="producto.identificador_producto" required>
					</div>
				</div>
			</div>
			<!-- Info producto -->
			<div class="row espForms">
				<div class="col-md-6">
					<div class="form-group">
					    <label for="nombre">Información del producto <span class="obligatorio">*</span></label>
					    <textarea type="text" class="form-control" placeholder="Información del producto" name="info_producto" #info_producto="ngModel" [(ngModel)]="producto.info_producto" required></textarea>
					</div>
				</div>
			</div>
			<!--Caracteristicas-->
			<div class="row espForms">
				<div class="col-md-6">
					<div class="form-group">
					    <label for="nombre">Características <span class="obligatorio">*</span></label>
					    <ck-editor name="caracteristicas" #caracteristicas="ngModel" [(ngModel)]="producto.caracteristicas" [config]="config" skin="moono-lisa" language="es" [fullPage]="true" ></ck-editor>
					</div>
				</div>
			</div>
			<div class="row espForms">
				<div class="col-md-6">
					<div class="form-group">
					    <label for="nombre">Presentaciones <span class="obligatorio">*</span></label>
					    <ck-editor name="presentaciones" #presentaciones="ngModel" [(ngModel)]="producto.presentaciones" [config]="config" skin="moono-lisa" language="es" [fullPage]="true" ></ck-editor>
					</div>
				</div>
			</div>
			<!-- Catalogo y categoria -->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Catálogo <span class="obligatorio">*</span></label>
					    <select class="form-control" name="id_catalogo" #id_catalogo="ngModel" [(ngModel)]="producto.id_catalogo" (change)="getCategorias(0)">
					    	<option value="-1">Selecciona</option>
					    	<option *ngFor="let c of catalogos" [ngValue]="c.id">{{c.nombre}}</option>
					    </select>
					</div>
				</div>
				<div class="col-md-3">
					<label for="nombre">Categoría <span class="obligatorio">*</span></label>
				    <select class="form-control" name="id_categoria" #id_categoria="ngModel" [(ngModel)]="producto.id_categoria" [disabled]="producto.id_catalogo==-1" (change)="getSubcategorias()">
				    	<option value="-1">Selecciona</option>
				    	<option *ngFor="let cat of categorias" [ngValue]="cat.id">{{cat.nombre}}</option>
				    </select>
				</div>
			</div>
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Subcategoria <span class="obligatorio">*</span></label>
					    <select class="form-control" name="id_subcategoria" #id_subcategoria="ngModel" [(ngModel)]="producto.id_subcategoria" [disabled]="producto.id_categoria==-1">
					    	<option value="-1">Selecciona</option>
					    	<option *ngFor="let sub of subcategorias" [ngValue]="sub.id">{{sub.titulo}}</option>
					    </select>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Video (url) <span class="obligatorio">*</span></label>
					    <input type="text" class="form-control" placeholder="Video (url)" name="video" #titulo="ngModel" [(ngModel)]="producto.video" required>
					</div>
				</div>
			</div>
			<!-- VIdeo y costo -->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Precio <span class="obligatorio">*</span></label>
					    <input type="text" class="form-control" placeholder="Precio" name="precio" #titulo="ngModel" [(ngModel)]="producto.precio" required>
					</div>
        </div>
        <div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Link amazon</label>
					    <input type="text" class="form-control" placeholder="Link" name="link" #amazon="ngModel" [(ngModel)]="producto.link" required>
					</div>
				</div>
      </div>
      <div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Etiquetas</label>
					    <input type="text" class="form-control" placeholder="Etiquetas" name="etiqueta" #etiqueta="ngModel" [(ngModel)]="producto.etiqueta" required>
					</div>
        </div>
        <div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Destacado</label> <br>
              <input type="checkbox" name="destacado" #destacado="ngModel" [(ngModel)]="producto.destacado">
					</div>
				</div>
			</div>
			<!-- Imagenes -->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
						<label>Imagen principal</label>
						<input #myInput accept="image/png, image/jpeg, image/jpg" type="file" placeholder="File Name" name="filename" (change)="onChangePrincipal($event)" class="form-control">
						<div *ngIf="bandPrincipal">
							<img src="{{producto.imagen_principal}}" style="width: 100%;">
						</div>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
						<label>Imagen de colores</label>
						<input #myInput accept="image/png, image/jpeg, image/jpg" type="file" placeholder="File Name" name="filename" (change)="onChangeColor($event)" class="form-control">
						<div *ngIf="bandColor">
							<img src="{{producto.imagen_color}}" style="width: 100%;">
						</div>
					</div>
				</div>
			</div>
			<!--SEO-->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Page Title <span class="obligatorio">*</span></label><span class="Error" *ngIf="page_title.touched && !page_title.valid">Error en el campo</span>
					    <input type="text" class="form-control" placeholder="Page Title" name="page_title" #page_title="ngModel" [(ngModel)]="producto.page_title" required>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Meta description <span class="obligatorio">*</span></label><span class="Error" *ngIf="meta_description.touched && !meta_description.valid">Error en el campo</span>
					    <input type="text" class="form-control" placeholder="Meta description" name="meta_description" #meta_description="ngModel" [(ngModel)]="producto.meta_description" required>
					</div>
				</div>
			</div>
			<!-- BTN -->
			<div class="row espForms">
				<div class="col-md-3">
					<button class="btn btnOrganicBlack" (click)="saveProducto();">Guardar</button>
				</div>
			</div>
		</form>
	</section>

