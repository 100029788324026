import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppGlobals } from '../app.global';

@Injectable()
export class PromasterService {
	public baseUrl: string;
	public urlSave: string;
	public urlEdit: string;
	public urlDelete: string;
	public urlDeleteVideo: string;
	public urlInactive: string;
	public urlActive: string;
	public urlGetPromaster: string;
	public urlGetPromasters: string;
	public urlGetProeducators: string;

	public headers = {
		headers: new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded',
		})
	};

	constructor(
		public http: HttpClient
	) {
		this.baseUrl = AppGlobals.baseURL;
		this.urlSave = 'Promasters/addPromasters';
		this.urlInactive = 'Promasters/inactivePromasters';
		this.urlActive = 'Promasters/activePromasters';
		this.urlDelete = 'Promasters/deletePromasters';
		this.urlDeleteVideo = 'Promasters/deletePromastersVideo';
		this.urlEdit = 'Promasters/editPromasters';
		this.urlGetPromaster = 'Promasters/getPromaster';
		this.urlGetPromasters = 'Promasters/getPromastersFront';
		this.urlGetProeducators = 'Promasters/getProeducatorsFront';
	}

	save(promaster) {
		const json = encodeURIComponent(JSON.stringify(promaster));

		return this.http.post(this.baseUrl + this.urlSave, promaster)
			.pipe(map(res => res));
	}

	edit(promaster) {
		const json = encodeURIComponent(JSON.stringify(promaster));

		return this.http.post(this.baseUrl + this.urlEdit, promaster)
			.pipe(map(res => res));
	}

	deletePromaster(promaster) {
		const json = encodeURIComponent(JSON.stringify(promaster));
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlDelete, params, this.headers)
			.pipe(map(res => res));
	}

	desactive(id) {
		const json = JSON.stringify(id);
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlInactive, params, this.headers)
			.pipe(map(res => res));
	}

	active(id) {
		const json = JSON.stringify(id);
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlActive, params, this.headers)
			.pipe(map(res => res));
	}

	deleteVideo(id) {
		const json = JSON.stringify(id);
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlDeleteVideo, params, this.headers)
			.pipe(map(res => res));
	}

	getPromaster(promaster) {
		const json = encodeURIComponent(JSON.stringify(promaster));
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlGetPromaster, params, this.headers)
			.pipe(map(res => res));
	}

	getPromasters() {

		return this.http.get(this.baseUrl + this.urlGetPromasters, this.headers)
			.pipe(map(res => res));
	}

	getProeducators() {
		return this.http.get(this.baseUrl + this.urlGetProeducators, this.headers)
			.pipe(map(res => res));
	}
}
