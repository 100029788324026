<admin-header></admin-header>
<admin-menu></admin-menu>

<notifier-container></notifier-container>

<div class="content-wrapper">
  <section class="content-header">
    <h1>
      Agregar Centros de capacitación
      <small> Control panel</small>
    </h1>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/admin']"><i class="fa fa-home"></i> Home</a>
      </li>
      <li class="">
        <a [routerLink]="['/cclist']">Centros de capacitación</a>
      </li>
      <li class="">Agregar Centros de capacitación</li>
    </ol>
  </section>
  <section class="content">
    <form #formAdd="ngForm">
      <!--Titulos-->
      <div class="row espForms">
        <div class="col-md-3">
          <div class="form-group">
            <label for="nombre">Título <span class="obligatorio">*</span></label
            ><span class="Error" *ngIf="titulo.touched && !titulo.valid"
              >Error en el campo</span
            >
            <input
              type="text"
              class="form-control"
              placeholder="Título"
              name="titulo"
              #titulo="ngModel"
              [(ngModel)]="CC.titulo"
              required
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="nombre">Nombre <span class="obligatorio">*</span></label
            ><span class="Error" *ngIf="nombre.touched && !nombre.valid"
              >Error en el campo</span
            >
            <input
              type="text"
              class="form-control"
              placeholder="Nombre"
              name="nombre"
              #nombre="ngModel"
              [(ngModel)]="CC.nombre"
              required
            />
          </div>
        </div>
      </div>
      <div class="row espForms">
        <div class="col-md-3">
          <div class="form-group">
            <label for="zona">Lugar <span class="obligatorio">*</span></label
            ><span class="Error" *ngIf="zona.touched && !zona.valid"
              >Error en el campo</span
            >
            <input
              type="text"
              class="form-control"
              placeholder="Lugar"
              name="zona"
              #zona="ngModel"
              [(ngModel)]="CC.zona"
              required
              maxlength="45"
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="telefono"
              >Teléfono <span class="obligatorio">*</span></label
            ><span class="Error" *ngIf="telefono.touched && !telefono.valid"
              >Error en el campo</span
            >
            <input
              type="text"
              class="form-control"
              placeholder="Teléfono"
              name="telefono"
              #telefono="ngModel"
              [(ngModel)]="CC.telefono"
              required
              [pattern]="onlyNumber"
            />
          </div>
        </div>
      </div>
      <div class="row espForms">
        <div class="col-md-3">
          <div class="form-group">
            <label for="nombre"
              >Dirección <span class="obligatorio">*</span></label
            ><span class="Error" *ngIf="direccion.touched && !direccion.valid"
              >Error en el campo</span
            >
            <textarea
              class="form-control"
              placeholder="Dirección"
              name="direccion"
              #direccion="ngModel"
              [(ngModel)]="CC.direccion"
              required
            ></textarea>
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="horarios"
              >Horarios <span class="obligatorio">*</span></label
            ><span class="Error" *ngIf="horarios.touched && !horarios.valid"
              >Error en el campo</span
            >
            <textarea
              class="form-control"
              placeholder="Horarios"
              name="horarios"
              #horarios="ngModel"
              [(ngModel)]="CC.horarios"
              required
            ></textarea>
          </div>
        </div>
      </div>
      <div class="row espForms">
        <div class="col-md-3">
          <div class="form-group">
            <label for="whatsapp"
              >Whatsapp <span class="obligatorio">*</span></label
            ><span class="Error" *ngIf="whatsapp.touched && !whatsapp.valid"
              >Error en el campo</span
            >
            <input
              type="text"
              class="form-control"
              placeholder="Whatsapp"
              name="whatsapp"
              #whatsapp="ngModel"
              [(ngModel)]="CC.whatsapp"
              required
              [pattern]="onlyNumber"
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="email">Email <span class="obligatorio">*</span></label
            ><span class="Error" *ngIf="email.touched && !email.valid"
              >Error en el campo</span
            >
            <input
              type="text"
              class="form-control"
              placeholder="Email"
              name="email"
              #email="ngModel"
              [(ngModel)]="CC.email"
              required
            />
          </div>
        </div>
      </div>
      <div class="row espForms">
        <div class="col-md-3">
          <div class="form-group">
            <label for="whatsapp"
              >Mapa (url) <span class="obligatorio">*</span></label
            ><span class="Error" *ngIf="url_mapa.touched && !url_mapa.valid"
              >Error en el campo</span
            >
            <input
              type="text"
              class="form-control"
              placeholder="Mapa"
              name="url_mapa"
              #url_mapa="ngModel"
              [(ngModel)]="CC.url_mapa"
              required
            />
          </div>
        </div>
        <div class="col-md-3">
          <div class="form-group">
            <label for="whatsapp">País <span class="obligatorio">*</span></label
            ><span class="Error" *ngIf="id_pais.touched && !id_pais.valid"
              >Error en el campo</span
            >
            <select
              name="id_pais"
              class="form-control"
              #id_pais="ngModel"
              [(ngModel)]="CC.id_pais"
              required
            >
              <option value="0">Selecciona una país</option>
              <option [value]="pais.id" *ngFor="let pais of paises">
                {{ pais.nombre }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="row espForms">
        <div class="col-md-3">
          <div class="form-group">
            <label for="whatsapp"
              >Imagen <span class="obligatorio">*</span></label
            >
            <input name="file" type="file" (change)="onFileChange($event)" />
          </div>
        </div>
      </div>
      <div class="row espForms">
        <div class="col-md-3">
          <button
            class="btn btnOrganicBlack"
            [disabled]="!formAdd.form.valid"
            (click)="save()"
          >
            Guardar
          </button>
        </div>
      </div>
    </form>
  </section>
</div>
