import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

@Component({
  selector: 'admin-footer',
  templateUrl: './adminFooter.component.html',
  styleUrls: ['./adminFooter.component.css']
})
export class AdminFooterComponent {

	
}
