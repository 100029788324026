<admin-header></admin-header>
<admin-menu></admin-menu>

<notifier-container></notifier-container>

<div class="content-wrapper">
  <div class="content-header">
    <h1>
      Editar distribuidor
      <small>Control panel</small>
    </h1>
    <ol class="breadcrumb">
      <li>
        <a [routerLink]="['/admin']"><i class="fa fa-home"></i> Home</a>
      </li>
      <li class="">
        <a [routerLink]="['/adminDistribuidores']">Distribuidores</a>
      </li>
      <li class="">Editar distribuidor</li>
    </ol>
  </div>
  <section class="content">
    <form #formAdd="ngForm">
      <div class="row esp">
        <div class="col-lg-6">
          <div class="form-group">
            <label for="nombre">Nombre <span class="obligatorio">*</span></label
            ><span class="Error" *ngIf="nombre.touched && !nombre.valid"
              >Error en el campo</span
            >
            <input
              type="text"
              class="form-control"
              placeholder="Nombre"
              name="nombre"
              #nombre="ngModel"
              [(ngModel)]="distri.nombre"
              required
            />
          </div>
        </div>
      </div>
      <div class="row esp">
        <div class="col-lg-3">
          <div class="form-group">
            <label for="correo"
              >Correo electronico <span class="obligatorio">*</span></label
            ><span class="Error" *ngIf="correo.touched && !correo.valid"
              >Error en el campo</span
            >
            <input
              type="text"
              class="form-control"
              placeholder="Correo electronico"
              name="correo"
              #correo="ngModel"
              [(ngModel)]="distri.correo"
              required
            />
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label for="telefono"
              >Teléfono <span class="obligatorio">*</span></label
            ><span class="Error" *ngIf="telefono.touched && !telefono.valid"
              >Error en el campo</span
            >
            <input
              type="text"
              placeholder="Teléfono"
              class="form-control"
              name="telefono"
              #telefono="ngModel"
              [(ngModel)]="distri.telefono"
              required
            />
          </div>
        </div>
      </div>
      <div class="row esp">
        <div class="col-lg-3">
          <div class="form-group">
            <label for="horario">Horarios</label>
            <textarea
              type="email"
              class="form-control"
              placeholder="Horarios"
              name="horario"
              #horario="ngModel"
              [(ngModel)]="distri.horario"
            ></textarea>
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label for="whatsapp">WhatsApp</label>
            <input
              type="text"
              class="form-control"
              placeholder="WhatsApp"
              name="whatsapp"
              #whatsapp="ngModel"
              [(ngModel)]="distri.whatsapp"
            />
          </div>
        </div>
      </div>
      <div class="row esp">
        <div class="col-lg-3">
          <div class="form-group">
            <label for="calle">Calle </label>
            <input
              type="text"
              class="form-control"
              placeholder="Calle"
              name="calle"
              #calle="ngModel"
              [(ngModel)]="distri.calle"
            />
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label for="exterior">Número exterior </label>
            <input
              type="text"
              class="form-control"
              placeholder="Número exterior"
              name="exterior"
              #exterior="ngModel"
              [(ngModel)]="distri.exterior"
            />
          </div>
        </div>
      </div>
      <div class="row esp">
        <div class="col-lg-3">
          <div class="form-group">
            <label for="interior">Número interior</label>
            <input
              type="text"
              class="form-control"
              placeholder="Número interior"
              name="interior"
              #interior="ngModel"
              [(ngModel)]="distri.interior"
            />
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label for="colonia">Colonia </label>
            <input
              type="text"
              class="form-control"
              placeholder="Colonia"
              name="colonia"
              #colonia="ngModel"
              [(ngModel)]="distri.colonia"
            />
          </div>
        </div>
      </div>
      <div class="row esp">
        <div class="col-lg-3">
          <div class="form-group">
            <label for="postal">Código postal</label>
            <input
              type="text"
              class="form-control"
              placeholder="Código postal"
              name="postal"
              #postal="ngModel"
              [(ngModel)]="distri.postal"
            />
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label for="estado">Estado </label>
            <input
              type="text"
              class="form-control"
              placeholder="Estado"
              name="estado"
              #estado="ngModel"
              [(ngModel)]="distri.estado"
            />
          </div>
        </div>
      </div>
      <div class="row esp">
        <div class="col-lg-3">
          <div class="form-group">
            <label for="municipio">Municipio </label>
            <input
              type="text"
              class="form-control"
              placeholder="Municipio"
              name="municipio"
              #municipio="ngModel"
              [(ngModel)]="distri.municipio"
            />
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label for="ciudad">Ciudad</label>
            <input
              type="text"
              class="form-control"
              placeholder="Ciudad"
              name="ciudad"
              #ciudad="ngModel"
              [(ngModel)]="distri.ciudad"
            />
          </div>
        </div>
      </div>
      <div class="row esp">
        <div class="col-lg-3">
          <div class="form-group">
            <label for="pais">País <span class="obligatorio">*</span></label
            ><span class="Error" *ngIf="pais.touched && !pais.valid"
              >Error en el campo</span
            >
            <input
              type="text"
              class="form-control"
              placeholder="País"
              name="pais"
              #pais="ngModel"
              [(ngModel)]="distri.pais"
              required
            />
          </div>
        </div>
        <div class="col-lg-3">
          <div class="form-group">
            <label for="sitio_web">Sitio web</label
            >
            <input
              type="text"
              class="form-control"
              placeholder="Sitio web"
              name="sitio_web"
              #sitio_web="ngModel"
              [(ngModel)]="distri.sitio_web"
            />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <button
            class="btn btnOrganicBlack"
            [disabled]="!distri.pais"
            (click)="show_map()"
          >
            Generar mapa
          </button>
          &nbsp;&nbsp;&nbsp;&nbsp;
          <img
            src="../../assets/img/ajax-loader.gif"
            *ngIf="!loading"
            hidden="true"
          />
          <img src="../../assets/img/ajax-loader.gif" *ngIf="loading" />
          <br /><br />
          <label class="text_map"
            >NOTA. Para generar el mapa con una ubicación más precisa, complete
            la dirección con calle, numero, colonia y estado</label
          >
        </div>
      </div>
      <div class="row esp">
        <div class="col-lg-6" *ngIf="isViewable">
          <!--<label for="cp">Dirección</label>
			      	<div class="form-group">
			        	<input placeholder="Busca tu dirección" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" class="form-control" #search [formControl]="searchControl">
			      	</div>-->
          <!--<agm-map [latitude]="latitude" [longitude]="longitude" [scrollwheel]="false" [zoom]="zoom">
			        	<agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
			      	</agm-map>-->
          <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
            <agm-marker
              [latitude]="lat"
              [longitude]="lng"
              [markerDraggable]="true"
              (dragEnd)="markerDragEnd($event)"
            ></agm-marker>
          </agm-map>
        </div>
        <div class="col-lg-6" *ngIf="!isViewable" hidden="true">
          <!--<label for="cp">Dirección</label>
			      	<div class="form-group">
			        	<input placeholder="Busca tu dirección" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" class="form-control" #search [formControl]="searchControl">
			      	</div>-->
          <!--<agm-map [latitude]="latitude" [longitude]="longitude" [scrollwheel]="false" [zoom]="zoom">
			        	<agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
			      	</agm-map>-->
          <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
          </agm-map>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <button
            class="btn btnOrganicBlack"
            [disabled]="!formAdd.form.valid"
            (click)="save()"
          >
            Guardar
          </button>
        </div>
      </div>
    </form>
  </section>
</div>
