<admin-header></admin-header>
<admin-menu></admin-menu>

<notifier-container></notifier-container>

<div class="content-wrapper">
	<section class="content-header">
	  <h1>
	    SEO
	    <small>Control panel</small>
	  </h1>
	  <ol class="breadcrumb">
	    <li><a [routerLink]="['/admin']"><i class="fa fa-home"></i> Home</a></li>
	    <li class="">SEO</li>
	  </ol>
	</section>
	<section class="content">
		<div class="row">
			<div class="col-lg-8 text-center">
				
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12 table-responsive">
				<table id="tableSEO" style="width: 100%;" datatable [dtOptions]="dtOptions" class="row-border hover"></table>
			</div>
		</div>
	</section>
</div>

<div class="modal fade" id="modalEdit" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Editar Seo</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				  <span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row">
			    	<div class="col-lg-12 col-xs-12">
			    		<p>Página: {{seo.nombre_pagina}}</p>
			    	</div>
			    	<div class="col-lg-12 col-xs-12">
			    		<label>Page title</label>
			    		<input type="text" class="form-control" placeholder="Page title" name="page_title" #page_title="ngModel" [(ngModel)]="seo.page_title" required>
			    	</div>
			    	<div class="col-lg-12 col-xs-12">
			    		<label>Meta description</label>
			    		<input type="text" class="form-control" placeholder="Meta description" name="meta_description" #meta_description="ngModel" [(ngModel)]="seo.meta_description" required>
			    	</div>
			    </div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn btnOrganicBlack" data-dismiss="modal" (click)="save();">Aceptar</button>
			</div>
        </div>
    </div>
</div>