import { Component, TemplateRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { NotifierService } from 'angular-notifier';

import { AppGlobals } from '../app.global';

import { LoginService } from '../services/login.service';
import { CategoriasService } from '../services/categorias.service';
import { SubcategoriaService } from '../services/subcategoria.service';
import { ProductoService } from '../services/producto.service';

import { Producto } from '../models/producto';

@Component({
  selector: "productosAdmin",
  templateUrl: './productosAdmin.component.html',
  styleUrls: ['./productosAdmin.component.css'],
  providers: [
    LoginService,
    CategoriasService,
    SubcategoriaService,
    ProductoService,
  ],
})
export class ProductosAdminComponent {
  public producto: any;
  public dtOptions: any = {};
  public baseURL = AppGlobals.baseURL;
  public identificador;
  public formData = new FormData();
  public base = AppGlobals.url;
  public auxOrden;
  public patternEmail =
    '/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$/';
  private readonly notifier: NotifierService;

  constructor(
    private loginService: LoginService,
    private categoriasService: CategoriasService,
    private subcategoriaService: SubcategoriaService,
    private productoService: ProductoService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    notifierService: NotifierService
  ) {
    this.producto = [];
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.titleService.setTitle('Productos - Organic Nails');
    this.route.params.forEach((params: Params) => {
      const parametro = localStorage.getItem('token');
      this.identificador = parametro;
      this.loginService.validate(parametro).subscribe(
        (result) => {
          if (result == 'false') {
            this.router.navigate(['/login']);
          }
        },
        (error) => {
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
    });
    this.dtOptions = {
      ajax: this.baseURL + '/Producto/getProductosCMS',
      columns: [
        { title: 'Nombre', data: 'nombre' },
        { title: 'Subcategoría', data: 'nombre_subcategoria' },
        { title: 'Categoría', data: 'nombre_categoria' },
        { title: 'Catálogo', data: 'nombre_catalogo' },
        { title: 'Orden', data: 'orden' },
        {
          title: 'Imagenes',
          data: 'id',
          mRender(data, type, full) {
            return '<span class="accion" id="verImagenes" data-toggle="modal" data-target="#modalVerImagenes"><i class="far fa-images"></i></span>';
          },
        },
        {
          title: 'Estatus',
          data: 'status',
          mRender(data, type, full) {
            if (data == 1) {
              return '<span id="status"><i class="far fa-check-circle"></i></span>';
            } else {
              return '<span id="status"><i class="far fa-circle"></i></span>';
            }
          },
        },
        {
          title: 'Acciones',
          data: 'id',
          bSortable: false,
          mRender(data, type, full) {
            return '<span class="accion" id="sort" data-toggle="modal" data-target="#modalOrder"><i class="fas fa-sort"></i></span> <span class="accion" id="uploadImagen" data-toggle="modal" data-target="#modalUploadImagen"><i class="fas fa-upload"></i></span> <span class="accion" id="editar"><i class="fa fa-pencil-alt"></i></span> <span class="accion" id="eliminar" data-toggle="modal" data-target="#modalDelete"><i class="fa fa-trash-alt"></i></span>';
          },
        },
      ],
      pagingType: 'simple',
      stateSave: true,
      language: {
        sProcessing: 'Procesando...',
        sLengthMenu: 'Mostrar _MENU_ registros',
        sZeroRecords: 'No se encontraron resultados',
        sEmptyTable: 'Ningún dato disponible en esta tabla',
        sInfo:
          'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
        sInfoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
        sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
        sInfoPostFix: '',
        sSearch: 'Buscar:',
        sUrl: '',
        sInfoThousands: ',',
        sLoadingRecords: 'Cargando...',
        oPaginate: {
          sFirst: 'Primero',
          sLast: 'Último',
          sNext: '<i class=\'fa  fa-arrow-right\'></i>',
          sPrevious: '<i class=\'fa fa-arrow-left\'></i>',
        },
        oAria: {
          sSortAscending:
            ': Activar para ordenar la columna de manera ascendente',
          sSortDescending:
            ': Activar para ordenar la columna de manera descendente',
        },
      },
      rowCallback: (row: Node, data, index: number) => {
        const self = this;
        $('td>span#status', row).unbind('click');
        $('td>span#status', row).bind('click', () => {
          this.producto = data;
          this.status();
        });
        $('td>span#verImagenes', row).unbind('click');
        $('td>span#verImagenes', row).bind('click', () => {
          this.producto = data;
        });
        $('td>span#editar', row).unbind('click');
        $('td>span#editar', row).bind('click', () => {
          localStorage.setItem('element', data.id);
          this.router.navigate(['/productosedit']);
        });
        $('td>span#sort', row).unbind('click');
        $('td>span#sort', row).bind('click', () => {
          this.producto = data;
          this.auxOrden = data.orden;
        });
        $('td>span#eliminar', row).unbind('click');
        $('td>span#eliminar', row).bind('click', () => {
          this.producto = data;
        });
        $('td>span#uploadImagen', row).unbind('click');
        $('td>span#uploadImagen', row).bind('click', () => {
          this.producto = data;
        });
        return row;
      },
      infoCallback(settings, start, end, max, total, pre) {
        if (total == 0) {
          return 'Sin registros disponibles';
        }
        return (
          'Mostrando registros del ' +
          start +
          ' al ' +
          end +
          ' de un total de ' +
          total +
          ' registros'
        );
      },
    };
  }

  recargarTable() {
    $('#tableProductos').DataTable().ajax.reload();
  }

  onChangeSecundaria(event) {
    const elem = event.target;
    if (elem.files.length > 0) {
      this.formData.append('imagen_producto', elem.files[0]);
    }
  }

  resetProducto() {
    this.producto = [];
  }

  delete() {
    this.productoService.delete(this.producto.id).subscribe(
      (result) => {
        if (result) {
          this.notifier.notify(
            'success',
            '¡Exito! La operación se realizo exitosamente'
          );
          this.resetProducto();
          this.recargarTable();
        } else {
          this.notifier.notify(
            'error',
            '¡Error! La operación no se realizo exitosamente'
          );
        }
      },
      (error) => {
        this.notifier.notify(
          'error',
          '¡Error! La operación no se realizo exitosamente'
        );
        const errorMessage = error as any;
        console.log(errorMessage);
      }
    );
  }

  status() {
    if (this.producto.status == 1) {
      this.productoService.desactive(this.producto.id).subscribe(
        (result) => {
          if (result) {
            this.resetProducto();
            this.recargarTable();
          }
        },
        (error) => {
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
    } else {
      this.productoService.active(this.producto.id).subscribe(
        (result) => {
          if (result) {
            this.resetProducto();
            this.recargarTable();
          }
        },
        (error) => {
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
    }
  }

  saveImagen() {
    this.formData.append('identificador', this.identificador);
    this.formData.append('id_producto', this.producto.id);
    this.productoService.saveImagenes(this.formData).subscribe(
      (result) => {
        if (result == 'true') {
          this.notifier.notify(
            'success',
            '¡Exito! La operación se realizo exitosamente'
          );
          this.resetProducto();
          this.recargarTable();
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          this.notifier.notify(
            'error',
            '¡Error! La operación no se realizo exitosamente'
          );
        }
      },
      (error) => {
        this.notifier.notify(
          'error',
          '¡Error! La operación no se realizo exitosamente'
        );
        const errorMessage = error as any;
        console.log(errorMessage);
      }
    );
  }

  deleteImage(id) {
    this.productoService.deleteImagen(id).subscribe(
      (result) => {
        if (result == 'true') {
          this.notifier.notify(
            'success',
            '¡Exito! La operación se realizo exitosamente'
          );
          this.resetProducto();
          this.recargarTable();
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          this.notifier.notify(
            'error',
            '¡Error! La operación no se realizo exitosamente'
          );
        }
      },
      (error) => {
        this.notifier.notify(
          'error',
          '¡Error! La operación no se realizo exitosamente'
        );
        const errorMessage = error as any;
        console.log(errorMessage);
      }
    );
  }

  ordenamiento() {
    this.producto.new_order = this.auxOrden;
    const auxProducto = {
      id: this.producto.id,
      id_subcategoria: this.producto.id_subcategoria,
      orden: this.producto.orden,
      new_order: this.producto.new_order,
    };
    this.productoService.ordenamiento(auxProducto).subscribe(
      (result) => {
        if (result) {
          this.notifier.notify(
            'success',
            '¡Exito! La operación se realizo exitosamente'
          );
          this.resetProducto();
          this.recargarTable();
        } else {
          this.notifier.notify(
            'error',
            '¡Error! La operación no se realizo exitosamente'
          );
        }
      },
      (error) => {
        this.notifier.notify(
          'error',
          '¡Error! La operación no se realizo exitosamente'
        );
        const errorMessage = error as any;
        console.log(errorMessage);
      }
    );
  }
}
