import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { NotifierService } from 'angular-notifier';

import { AppGlobals } from '../app.global';

import { LoginService } from '../services/login.service';
import { PromasterService } from '../services/promaster.service';

import { Promaster } from '../models/promaster';

@Component({
	selector: 'editPromasters',
	templateUrl: './editPromaster.component.html',
	styleUrls: ['./editPromaster.component.css'],
	providers: [LoginService, Md5, PromasterService]

})
export class EditPromasterComponent {

	public promaster: any;
	private readonly notifier: NotifierService;
	public identificador;
	public idPromaster;
	public base = AppGlobals.url;
	public bandMiniatura = true;
	public bandBanner = true;
	public bandBio = true;
	public formData = new FormData();
	@ViewChild('myInput', { static: true }) static;
	public myInputVariable;
	public video = {
		id: '',
		nombre: '',
		url: '',
		descripcion: ''
	};
	public config = { 
		// tslint:disable-next-line: max-line-length
		removeButtons: 'Source,Save,NewPage,DocProps,Preview,Print,Templates,document,Form,Checkbox,Radio,TextField,Textarea,Select,Button,ImageButton,HiddenField,Image,Flash,Table,HorizontalRule,Smiley,PageBreak,Iframe,CreateDiv,BidiLtr,BidiRtl,About,ShowBlocks,Font,UIColor,Cut,Copy,Paste,PasteText,PasteFromWord,Undo,Redo,Find,Replace,SelectAll,Scayt,Superscript,RemoveFormat,CreatePlaceholder,Styles' };

	constructor(
		private loginService: LoginService,
		private promasterService: PromasterService,
		private route: ActivatedRoute,
		private router: Router,
		private titleService: Title,
		notifierService: NotifierService
	) {
		this.promaster = new Promaster('', '', '', '', '', '', '', 0, '', '', '', '', '', '', '', '', '', '', '', '', [], '', '', '', '');
		this.notifier = notifierService;
	}

	ngOnInit() {
		this.titleService.setTitle('Editar Promasters - Organic Nails');
		this.route.params.forEach((params: Params) => {
			const parametro = params.page;
			const id = localStorage.getItem('element');
			this.identificador = localStorage.getItem('token');
			this.loginService.validate(parametro).subscribe(
				result => {
					if (result === 'false') {
						this.router.navigate(['/login']);
					}
				},
				error => {
					const errorMessage = error as any;
					console.log(errorMessage);
				}
			);
			this.promasterService.getPromaster(id).subscribe(
				result => {
					this.promaster = result;
					this.promaster.identificador_promaster = this.promaster.identificador;
					if (this.promaster.videos == null) {
						this.promaster.videos = [];
					}

				},
				error => {
					const errorMessage = error as any;
					console.log(errorMessage);
				}
			);
		});
	}

	savePromaster() {
		this.formData.append('identificador', this.identificador);
		this.formData.append('id', this.promaster.id);
		this.formData.append('biografia', this.promaster.biografia);
		this.formData.append('descripcion_corta', this.promaster.descripcion_corta);
		this.formData.append('detalle_frase', this.promaster.detalle_frase);
		this.formData.append('facebook', this.promaster.facebook);
		this.formData.append('frase', this.promaster.frase);
		this.formData.append('id_tipo', this.promaster.id_tipo);
		this.formData.append('identificador_promaster', this.promaster.identificador_promaster);
		this.formData.append('instagram', this.promaster.instagram);
		this.formData.append('nombre', this.promaster.nombre);
		this.formData.append('pinterest', this.promaster.pinterest);
		this.formData.append('puesto', this.promaster.puesto);
		this.formData.append('whatsapp', this.promaster.whatsapp);
		this.formData.append('meta_description', this.promaster.meta_description);
		this.formData.append('page_title', this.promaster.page_title);
		this.formData.append('videos', JSON.stringify(this.promaster.videos));
		this.promasterService.edit(this.formData).subscribe(
			result => {
				this.notifier.notify('success', '¡Exito! La operación se realizo exitosamente');
				setTimeout(() => {
					this.router.navigate(['/adminpromaster']);
				}, 1500);
			},
			error => {
				this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				const errorMessage = error as any;
				console.log(errorMessage);
			}
		);
	}
	resetVideo() {
		this.video = {
			id: '',
			nombre: '',
			url: '',
			descripcion: ''
		};
	}

	onChange(event) {
		this.bandMiniatura = false;
		const elem = event.target;
		if (elem.files.length > 0) {
			this.formData.append('fotografia_principal', elem.files[0]);
		}
	}

	onChangeBanner(event) {
		this.bandBanner = false;
		const elem = event.target;
		if (elem.files.length > 0) {
			this.formData.append('imagen_banner', elem.files[0]);
		}
	}

	onChangeBio(event) {
		this.bandBio = false;
		const elem = event.target;
		if (elem.files.length > 0) {
			this.formData.append('fotografia_biografia', elem.files[0]);
		}
	}

	addVideo() {
		this.promaster.videos.push(this.video);
		this.resetVideo();
	}

	removeVideo(index, id) {
		if (id !== '') {
			this.promasterService.deleteVideo(id).subscribe(
				result => {

				},
				error => {
					const errorMessage = error as any;
					console.log(errorMessage);
				}
			);
		}
		const resultado = [];
		for (let i = 0; i < this.promaster.videos.length; i++) {
			if (i !== index) {
				resultado.push(this.promaster.videos[i]);
			}
		}
		this.promaster.videos = [];
		this.promaster.videos = resultado;
	}

}
