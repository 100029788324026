import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppGlobals } from '../app.global';

@Injectable()
export class EventosService {
	public baseUrl: string;
	public urlSave: string;
	public urlGetEventos: string;
	public urlGetEvento: string;
	public urlEdit: string;
	public urlActive: string;
	public urlInactive: string;
	public urlDelete: string;
	public urlGetEventoMes: string;

	public headers = {
		headers: new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded',
		})
	};

	constructor(
		public http: HttpClient
	) {
		this.baseUrl = AppGlobals.baseURL;
		this.urlSave = 'Evento/addEvento';
		this.urlGetEventos = 'Evento/getEventosFront';
		this.urlGetEvento = 'Evento/getEvento';
		this.urlEdit = 'Evento/editEvento';
		this.urlActive = 'Evento/activeEvento';
		this.urlInactive = 'Evento/inactiveEvento';
		this.urlDelete = 'Evento/deleteEvento';
		this.urlGetEventoMes = 'Evento/getEventosFrontPorMes';
	}

	save(evento) {
		const json = encodeURIComponent(JSON.stringify(evento));
		const params = 'json=' + json;
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.post(this.baseUrl + this.urlSave, params, this.headers)
			.pipe(map(res => res));
	}

	edit(evento) {
		const json = encodeURIComponent(JSON.stringify(evento));
		const params = 'json=' + json;
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.post(this.baseUrl + this.urlEdit, params, this.headers)
			.pipe(map(res => res));
	}

	deleteEvento(evento) {
		const json = encodeURIComponent(JSON.stringify(evento));
		const params = 'json=' + json;
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.post(this.baseUrl + this.urlDelete, params, this.headers)
			.pipe(map(res => res));
	}

	getEvento(evento) {
		const json = encodeURIComponent(JSON.stringify(evento));
		const params = 'json=' + json;
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.post(this.baseUrl + this.urlGetEvento, params, this.headers)
			.pipe(map(res => res));
	}

	getEventos() {
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.get(this.baseUrl + this.urlGetEventos, this.headers)
			.pipe(map(res => res));
	}

	paises() {
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.get('https://restcountries.eu/rest/v2/all', this.headers)
			.pipe(map(res => res));
	}

	estados() {
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.get('http://geodb-free-service.wirefreethought.com/v1/geo/cities?countryCodes=US,ES,FR', this.headers)
			.pipe(map(res => res));
	}

	desactive(id) {
		const json = JSON.stringify(id);
		const params = 'json=' + json;
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.post(this.baseUrl + this.urlInactive, params, this.headers)
			.pipe(map(res => res));
	}
	active(id) {
		const json = JSON.stringify(id);
		const params = 'json=' + json;
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.post(this.baseUrl + this.urlActive, params, this.headers)
			.pipe(map(res => res));
	}

	getEventoMes(mesanio) {
		const json = JSON.stringify(mesanio);
		const params = 'json=' + json;
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.post(this.baseUrl + this.urlGetEventoMes, params, this.headers)
			.pipe(map(res => res));
	}
}
