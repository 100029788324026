<admin-menu></admin-menu>
<admin-header></admin-header>

<div class="content-wrapper">
  <section class="content-header">
    <h1>
      Paises para CCO
      <small> Control panel</small>
    </h1>
    <ol class="breadcrumb">
      <li>
        <a routerLink="/admin"><i class="fa fa-home"></i> Home </a>
      </li>
      <li class="">Paises para CCO</li>
    </ol>
  </section>
  <section class="content">
    <div class="row">
      <div class="col-lg-8 text-center"></div>
    </div>
    <div class="row">
      <div class="col-lg-12 table-responsive">
        <table
          id="tablePaises"
          style="width: 100%"
          datatable
          [dtOptions]="dtOptions"
          class="row-border hover"
        ></table>
      </div>
    </div>
  </section>
  <a data-toggle="modal" data-target="#modalAgregar">
    <img
      class="add"
      src="../../assets/img/circle-add.svg"
      alt=""
      mdbTooltip="Agregar nuevo registro"
    />
  </a>
</div>

<div
  class="modal fade"
  id="modalAgregar"
  tabindex="-1"
  role="dialog"
  aria-labelledby=""
  aria-hidden="true"
  data-backdrop="false"
>
  <div class="modal-dialog modal-md" role="document">
    <form
      [formGroup]="saveForm"
      class="contact-form"
      (ngSubmit)="save()"
      *ngIf="!success"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Agregar</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-xs-12">
              <div class="form-group">
                <label for="exampleInputEmail1"
                  >Nombre <span class="obligatorio">*</span></label
                >
                <input
                  class="form-control"
                  name="nombre"
                  required
                  formControlName="nombre"
                  [ngClass]="{
                    'is-invalid error-inputs-form':
                      submitted && form.nombre.errors
                  }"
                />
              </div>
            </div>
            <div class="col-lg-12 col-xs-12">
              <div class="form-group">
                <label for="exampleInputEmail1"
                  >Abreviatura <span class="obligatorio">*</span></label
                >
                <input
                  class="form-control"
                  name="Abreviatura"
                  required
                  formControlName="abreviatura"
                  [ngClass]="{
                    'is-invalid error-inputs-form':
                      submitted && form.abreviatura.errors
                  }"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-xs-12">
              <div class="form-group">
                <label for="exampleInputEmail1"
                  >Documento <span class="obligatorio">*</span></label
                >
                <input
                  name="file"
                  type="file"
                  (change)="onFileChange($event)"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btnOrganicBlack">
            Guardar
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div
  class="modal fade"
  id="modalEditar"
  tabindex="-1"
  role="dialog"
  aria-labelledby=""
  aria-hidden="true"
  data-backdrop="false"
>
  <div class="modal-dialog modal-md" role="document">
    <form
      [formGroup]="editForm"
      class="contact-form"
      (ngSubmit)="edit()"
      *ngIf="!success"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Editar</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-lg-12 col-xs-12">
              <div class="form-group">
                <label for="exampleInputEmail1"
                  >Nombre <span class="obligatorio">*</span></label
                >
                <input
                  class="form-control"
                  name="nombre"
                  required
                  formControlName="nombre"
                  [ngClass]="{
                    'is-invalid error-inputs-form':
                      submitted && editform.nombre.errors
                  }"
                />
              </div>
            </div>
            <div class="col-lg-12 col-xs-12">
              <div class="form-group">
                <label for="exampleInputEmail1"
                  >Abreviatura <span class="obligatorio">*</span></label
                >
                <input
                  class="form-control"
                  name="Abreviatura"
                  required
                  formControlName="abreviatura"
                  [ngClass]="{
                    'is-invalid error-inputs-form':
                      submitted && editform.abreviatura.errors
                  }"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-xs-12">
              <div class="form-group">
                <label for="exampleInputEmail1"
                  >Bandera <span class="obligatorio">*</span></label
                >
                <input
                  name="file"
                  type="file"
                  (change)="onFileChangeEdit($event)"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12 col-xs-12">
              <img width="100%" [src]="pais.bandera" alt="">
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="submit" class="btn btnOrganicBlack">
            Guardar
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

