<admin-header></admin-header>
<admin-menu></admin-menu>

<notifier-container></notifier-container>

<div class="content-wrapper">
	<section class="content-header">
	  <h1>
	    Catálogos
	    <small>Control panel</small>
	  </h1>
	  <ol class="breadcrumb">
	    <li><a [routerLink]="['/admin']"><i class="fa fa-home"></i> Home</a></li>
	    <li class="">Catálogos</li>
	  </ol>
	</section>
	<section class="content">
		<div class="row">
			<div class="col-lg-8 text-center">
				
			</div>
		</div>
		<div class="row">
			<div class="col-lg-12 table-responsive">
				<table id="tableDistribuidores" style="width: 100%;" datatable [dtOptions]="dtOptions" class="row-border hover"></table>
			</div>
		</div>
	</section>
</div>