<admin-header></admin-header>
<admin-menu></admin-menu>

<notifier-container></notifier-container>

<div class="content-wrapper">
	<div class="content-header">
	  <h1>
	    Agregar Banners
	    <small>Control panel</small>
	  </h1>
	  <ol class="breadcrumb">
	    <li><a [routerLink]="['/admin']"><i class="fa fa-home"></i> Home</a></li>
	    <li class=""><a [routerLink]="['/banners']">Banners</a></li>
	    <li class="">Agregar Banners</li>
	  </ol>
	</div>
	<section class="content">
		<form #formAdd="ngForm">
			<!--Titulos-->
			<div class="row espForms">
				<h3>Información general</h3>
			</div>
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Nombre banner</label>
					    <input type="text" class="form-control" placeholder="Título" name="titulo" #titulo="ngModel" [(ngModel)]="banner.titulo">
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Tipo de banner <span class="obligatorio">*</span></label><span class="Error" *ngIf="tipo.touched && !tipo.valid">Error en el campo</span>
					    <select class="form-control" name="tipo" #tipo="ngModel" [(ngModel)]="banner.id_tipo" required>
					    	<option value="0">Selecciona</option>
					    	<option value="1">Principal</option>
					    	<option value="2">Secundario</option>
					    </select>
					</div>
				</div>
			</div>
			<!--<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Posición </label>
					    <select class="form-control" name="posicion" #posicion="ngModel" [(ngModel)]="banner.posicion_texto" required>
					    	<option value="0">Selecciona</option>
					    	<option value="1">Izquierda</option>
					    	<option value="2">Derecha</option>
					    </select>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Subtítulo</label>
					    <input type="text" class="form-control" placeholder="Subtítulo" name="subtitulo" #subtitulo="ngModel" [(ngModel)]="banner.subtitulo">
					</div>
				</div>
			</div>-->
			<div class="row espForms">
				<!--<div class="col-md-3">
					<div class="form-group">
						<label for="nombre">Color de texto</label>
						<input class="form-control" [style.background]="banner.color1"
					       [cpAlphaChannel]="'always'"
					       [cpOutputFormat]="'rgba'"
					       [cpPresetColors]="['#fff', '#2889e9']"
					       [cpAddColorButton]="true"
					       [(colorPicker)]="banner.color1"/>
					</div>
				</div>-->
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">URL <span class="obligatorio">*</span></label><span class="Error" *ngIf="url.touched && !url.valid">Error en el campo</span>
					    <input type="text" class="form-control" placeholder="URL" name="url" #url="ngModel" [(ngModel)]="banner.url" required>
					</div>
				</div>
			</div>
			<!--TieneBTN-->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
						<label><input type="checkbox" name="blank" #blank="ngModel" [(ngModel)]="banner.blank" required> ¿Dirige a una página externa?</label>
					</div>
				</div>
				<!--<div class="col-md-3">
					<div class="form-group">
						<label><input type="checkbox" name="tieneBoton" #tieneBoton="ngModel" [(ngModel)]="banner.tiene_boton" required> ¿Tiene botón?</label>
					</div>
				</div>-->
			</div>
			<!--<div *ngIf="banner.tiene_boton" class="animate-if">
				Texto
				<div class="row espForms">
					<div class="col-md-3">
						<div class="form-group">
						    <label for="nombre">Texto <span class="obligatorio">*</span></label><span class="Error" *ngIf="textoBoton.touched && !textoBoton.valid">Error en el campo</span>
						    <input type="text" class="form-control" placeholder="Texto" name="textoBoton" #textoBoton="ngModel" [(ngModel)]="banner.texto_boton" required>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group">
							<label for="nombre">Color de fondo</label>
							<input class="form-control" [style.background]="banner.color2"
						       [cpAlphaChannel]="'always'"
						       [cpOutputFormat]="'rgba'"
						       [cpPresetColors]="['#fff', '#2889e9']"
						       [cpAddColorButton]="true"
						       [(colorPicker)]="banner.color2"/>
						</div>
					</div>
				</div>-->
				<!--Colores
				<div class="row espForms">
					<div class="col-md-3">
						<div class="form-group">
							<label for="nombre">Color fondo hover<span class="obligatorio">*</span></label>
							<input class="form-control" [style.background]="banner.color_over"
						       [cpAlphaChannel]="'always'"
						       [cpOutputFormat]="'rgba'"
						       [cpPresetColors]="['#fff', '#2889e9']"
						       [cpAddColorButton]="true"
						       [(colorPicker)]="banner.color_over"/>
						</div>
					</div>
					<div class="col-md-3">
						<div class="form-group">
							<label for="nombre">Color texto hover<span class="obligatorio">*</span></label>
							<input class="form-control" [style.background]="banner.color_hover"
						       [cpAlphaChannel]="'always'"
						       [cpOutputFormat]="'rgba'"
						       [cpPresetColors]="['#fff', '#2889e9']"
						       [cpAddColorButton]="true"
						       [(colorPicker)]="banner.color_hover"/>
						</div>
					</div>
				</div>
			</div>-->
			<!-- Imagenes-->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
						<label>Fondo</label>
						<input #myInput accept="image/png, image/jpeg, image/jpg" type="file" placeholder="File Name" name="filename" (change)="onChangeFondo($event)" class="form-control">
					</div>
				</div>
			</div>
			<div class="row espForms">
				<h5>Desktop</h5>
			</div>
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
						<label>Imagen Izquierda</label>
						<input #myInput accept="image/png, image/jpeg, image/jpg" type="file" placeholder="File Name" name="filename" (change)="onChangeDeskI($event)" class="form-control">
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
						<label>Imagen Derecha</label>
						<input #myInput accept="image/png, image/jpeg, image/jpg" type="file" placeholder="File Name" name="filename" (change)="onChangeDeskD($event)" class="form-control">
					</div>
				</div>
			</div>
			<div class="row espForms">
				<h5>Tablet</h5>
			</div>
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
						<label>Imagen Izquierda</label>
						<input #myInput accept="image/png, image/jpeg, image/jpg" type="file" placeholder="File Name" name="filename" (change)="onChangeTabletI($event)" class="form-control">
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
						<label>Imagen Derecha</label>
						<input #myInput accept="image/png, image/jpeg, image/jpg" type="file" placeholder="File Name" name="filename" (change)="onChangeTabletD($event)" class="form-control">
					</div>
				</div>
			</div>
			<div class="row espForms">
				<h5>Movil</h5>
			</div>
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
						<label>Imagen Izquierda</label>
						<input #myInput accept="image/png, image/jpeg, image/jpg" type="file" placeholder="File Name" name="filename" (change)="onChangeMovilI($event)" class="form-control">
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
						<label>Imagen Derecha</label>
						<input #myInput accept="image/png, image/jpeg, image/jpg" type="file" placeholder="File Name" name="filename" (change)="onChangeMovilD($event)" class="form-control">
					</div>
				</div>
			</div>
			<div class="row espForms">
				<div class="col-md-3">
					<button class="btn btnOrganicBlack" (click)="saveBanner();">Guardar</button>
				</div>
			</div>
		</form>
	</section>

