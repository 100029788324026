import { Component, TemplateRef,EventEmitter   } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FormControl } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';

import { EventosService } from '../services/eventos.service';

const moment = _rollupMoment || _moment;
export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YYYY',
  },
  display: {
    dateInput: 'MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@Component({
  selector: 'eventosAll',
  templateUrl: './eventosAll.component.html',
  styleUrls: ['./eventosAll.component.css'],
  providers: [
    {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
    EventosService
  ],
})
export class EventosAllComponent {

	public eventosAll;
	public date = new Date();
	public mes;
	public year;
	public prev;
	public prevYear;
	public next;
	public nextYear;
	public valiablemas;
	public dateE = new FormControl(moment());
	public mesanio = {
		"anio": "",
		"mes": "",
	};

	constructor(
		private _eventosService: EventosService,
	){

	}

	ngOnInit(){
		this.getMeses(this.date);
	}

	getMeses(actual){
		actual.setDate(1);
		let actualprev = new Date(actual);
		let actualnext = new Date(actual);
		let prev = new Date(actualprev.setMonth(actualprev.getMonth() - 1));
		let next = new Date(actualnext.setMonth(actualnext.getMonth() + 1));
		this.prev = String(prev.getMonth());
		this.prevYear = prev.getFullYear()
		this.next = String(next.getMonth());
		this.nextYear = next.getFullYear();
		this.mes = String(actual.getMonth());
		this.year = String(actual.getFullYear());
		this.mesanio = {
			"anio": this.year,
			"mes": this.mes,
		};
		this._eventosService.getEventoMes(this.mesanio).subscribe(
			result => {
				if (result) {
					this.eventosAll = result;
				}else{
					this.eventosAll = [];
				}
				
			},
			error => {
				var errorMessage = <any>error;
				console.log(errorMessage);
			}
		);
	}

	nextMonth(){
		this.date = new Date(this.date.setMonth(this.date.getMonth() + 1));
		this.getMeses(this.date);
	}

	prevMonth(){
		this.date = new Date(this.date.setMonth(this.date.getMonth() - 1));
		this.getMeses(this.date);
	}

	chosenYearHandler(normalizedYear: Moment) {
	    const ctrlValue = this.dateE.value;
	    ctrlValue.year(normalizedYear.year());
	    this.date.setFullYear(ctrlValue.year());
	    this.dateE.setValue(ctrlValue);
	}

	chosenMonthHandler(normlizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
	    const ctrlValue = this.dateE.value;
	    ctrlValue.month(normlizedMonth.month());
	    this.date.setMonth(ctrlValue.month());
	    this.dateE.setValue(ctrlValue);
	    datepicker.close();
	    this.getMeses(this.date);
	}
}
