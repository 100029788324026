<admin-header></admin-header>
<admin-menu></admin-menu>

<notifier-container></notifier-container>

<div class="content-wrapper">
	<div class="content-header">
	  <h1>
	    {{catalogo.nombre}} 
	    <small>Control panel</small>
	  </h1>
	  <ol class="breadcrumb">
	    <li><a [routerLink]="['/admin']"><i class="fa fa-home"></i> Home</a></li>
	    <li class=""><a [routerLink]="['/catalogoslist']">Catálogos</a></li>
	    <li class="">{{catalogo.nombre}} </li>
	  </ol>
	</div>
	<section class="content">
		<form #formAdd="ngForm">
			<!--Titulos-->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Slogan <span class="obligatorio">*</span></label><span class="Error" *ngIf="slogan.touched && !slogan.valid">Error en el campo</span>
					    <input type="text" class="form-control" placeholder="Slogan" name="slogan" #slogan="ngModel" [(ngModel)]="catalogo.slogan" required>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Información banner <span class="obligatorio">*</span></label><span class="Error" *ngIf="info_imagen.touched && !info_imagen.valid">Error en el campo</span>
					    <textarea type="text" class="form-control" placeholder="Información banner" name="info_imagen" #info_imagen="ngModel" [(ngModel)]="catalogo.info_imagen" required></textarea>
					</div>
				</div>
			</div>
			<!--Subtitulos-->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Subtitulo <span class="obligatorio">*</span></label><span class="Error" *ngIf="subtitulo.touched && !subtitulo.valid">Error en el campo</span>
					    <input type="text" class="form-control" placeholder="Subtitulo" name="subtitulo" #subtitulo="ngModel" [(ngModel)]="catalogo.subtitulo" required>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Información subtitulo <span class="obligatorio">*</span></label><span class="Error" *ngIf="info_subtitulo.touched && !info_subtitulo.valid">Error en el campo</span>
					    <textarea type="text" class="form-control" placeholder="Información subtitulo" name="info_subtitulo" #info_subtitulo="ngModel" [(ngModel)]="catalogo.info_subtitulo" required></textarea>
					</div>
				</div>
			</div>
			<!-- Color-->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
						<label for="nombre">Color de texto<span class="obligatorio">*</span></label>
						<input class="form-control" [style.background]="catalogo.color"
					       [cpAlphaChannel]="'always'"
					       [cpOutputFormat]="'rgba'"
					       [cpPresetColors]="['#fff', '#2889e9']"
					       [cpAddColorButton]="true"
					       [(colorPicker)]="catalogo.color"/>
					</div>
				</div>
			</div>
			<!-- Imagenes-->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
						<label>Fondo</label>
						<input #myInput accept="image/png, image/jpeg, image/jpg" type="file" placeholder="File Name" name="filename" (change)="onChangeDesk($event)" class="form-control">
						<div *ngIf="bandDesk">
							<img src="{{catalogo.img_desk}}" style="width: 100%;">
						</div>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
						<label>Imagen</label>
						<input #myInput accept="image/png, image/jpeg, image/jpg" type="file" placeholder="File Name" name="filename" (change)="onChangeTablet($event)" class="form-control">
						<div *ngIf="bandTablet">
							<img src="{{catalogo.img_tablet}}" style="width: 100%;">
						</div>
					</div>
				</div>
				<!--<div class="col-md-3">
					<div class="form-group">
						<label>Movil</label>
						<input #myInput accept="image/png, image/jpeg, image/jpg" type="file" placeholder="File Name" name="filename" (change)="onChangeMovil($event)" class="form-control">
						<div *ngIf="bandMovil">
							<img src="{{catalogo.img_movil}}" style="width: 100%;">
						</div>
					</div>
				</div>-->
			</div>
			<!--SEO-->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Page Title <span class="obligatorio">*</span></label><span class="Error" *ngIf="page_title.touched && !page_title.valid">Error en el campo</span>
					    <input type="text" class="form-control" placeholder="Page Title" name="page_title" #page_title="ngModel" [(ngModel)]="catalogo.page_title" required>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Meta description <span class="obligatorio">*</span></label><span class="Error" *ngIf="meta_description.touched && !meta_description.valid">Error en el campo</span>
					    <input type="text" class="form-control" placeholder="Meta description" name="meta_description" #meta_description="ngModel" [(ngModel)]="catalogo.meta_description" required>
					</div>
				</div>
			</div>
			<div class="row espForms">
				<div class="col-md-3">
					<button class="btn btnOrganicBlack" (click)="saveCatalogo();">Guardar</button>
				</div>
			</div>
		</form>
	</section>

