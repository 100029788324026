<notifier-container></notifier-container>

<div class="container">
    <div class="card card-container">
        <img id="profile-img" class="profile-img-card" src="../../assets/logo.png" />
        <p id="profile-name" class="profile-name-card"></p>
        <form class="form-signin" #formLogin="ngForm">
            <span id="reauth-email" class="reauth-email"></span>
            <input type="text" class="form-control" placeholder="Código" name="codigo" #codigo="ngModel" [(ngModel)]="user.codigo" required>
            <input type="password" class="form-control" placeholder="Contraseña" name="password" #password="ngModel" [(ngModel)]="user.password" required>
            <input class="btn btn-lg btn-block btn-signin" type="submit"  value="Ingresar" (click)="LogIn();">
        </form>
        <a data-toggle="modal" data-target="#modalAgregar" class="forgot-password">
            ¿Olvidaste tu contraseña?
        </a>
    </div>
</div>

<div class="modal fade" id="modalAgregar" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
            <form #formAdd="ngForm">
            <div class="modal-header">
                <h5 class="modal-title">Recuperar contraseña</h5>
                <button (click)="formAdd.reset();" type="button" class="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-lg-12 col-xs-12">
                        <div class="form-group">
                            <label for="codigo">Código <span class="obligatorio">*</span></label><span class="Error" *ngIf="cod.touched && !cod.valid">Error en el campo</span>
                            <input type="email" class="form-control" placeholder="Código" required name="cod" #cod ="ngModel" [(ngModel)]="restart.codigo">
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btnOrganicBlack" data-dismiss="modal" (click)="enviar();" [disabled]="!formAdd.form.valid" >Guardar</button>
            </div>
        </form>
        </div>
    </div>
</div>