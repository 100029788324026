import { Component, OnInit } from "@angular/core";
import { Md5 } from "ts-md5/dist/md5";
import { Title } from "@angular/platform-browser";
import { Router, ActivatedRoute, Params } from "@angular/router";
import { AppGlobals } from "../app.global";
import { NgForm, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NotifierService } from "angular-notifier";

import { LoginService } from "../services/login.service";
import { PaisesService } from "../services/paises.service";

@Component({
  selector: "app-pais-cco",
  templateUrl: "./pais-cco.component.html",
  styleUrls: ["./pais-cco.component.css"],
  providers: [Md5, LoginService, PaisesService],
})
export class PaisCCoComponent implements OnInit {
  public dtOptions: any = {};
  public baseURL = AppGlobals.baseURL;
  public identificador;
  public saveForm: FormGroup;
  public editForm: FormGroup;
  public success: boolean;
  public submitted: boolean;
  public pais: any;
  private readonly notifier: NotifierService;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    private loginService: LoginService,
    private paisesService: PaisesService,
    private formBuilder: FormBuilder,
    notifierService: NotifierService
  ) {
    this.success = false;
    this.submitted = false;
    this.pais = {};
    this.notifier = notifierService;
  }

  ngOnInit() {
    this.validLogin();
    this.initForm();
    this.initEdit();
    this.dtOptions = {
      ajax: this.baseURL + "Paises/getPaisesCMS",
      columns: [
        { title: "Nombre", data: "nombre" },
        { title: "Abreviatura", data: "abreviatura" },
        {
          title: "Bandera",
          data: "bandera",
          mRender(data, type, full) {
            return '<img style="width: 75px" src="' + data + '">';
          },
        },
        {
          title: "Estatus",
          data: "status",
          mRender(data, type, full) {
            if (data == 1) {
              return '<span id="status"><i class="far fa-check-circle"></i></span>';
            } else {
              return '<span id="status"><i class="far fa-circle"></i></span>';
            }
          },
        },
        {
          title: "Acciones",
          data: "id",
          bSortable: false,
          mRender(data, type, full) {
            return '<span data-toggle="modal" data-target="#modalEditar" class="accion" id="editar"><i class="fa fa-pencil-alt"></i></span>';
          },
        },
      ],
      pagingType: "simple",
      stateSave: true,
      language: {
        sProcessing: "Procesando...",
        sLengthMenu: "Mostrar _MENU_ registros",
        sZeroRecords: "No se encontraron resultados",
        sEmptyTable: "Ningún dato disponible en esta tabla",
        sInfo:
          "Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros",
        sInfoEmpty: "Mostrando registros del 0 al 0 de un total de 0 registros",
        sInfoFiltered: "(filtrado de un total de _MAX_ registros)",
        sInfoPostFix: "",
        sSearch: "Buscar:",
        sUrl: "",
        sInfoThousands: ",",
        sLoadingRecords: "Cargando...",
        oPaginate: {
          sFirst: "Primero",
          sLast: "Último",
          sNext: "<i class='fa  fa-arrow-right'></i>",
          sPrevious: "<i class='fa fa-arrow-left'></i>",
        },
        oAria: {
          sSortAscending:
            ": Activar para ordenar la columna de manera ascendente",
          sSortDescending:
            ": Activar para ordenar la columna de manera descendente",
        },
      },
      rowCallback: (row: Node, data, index: number) => {
        const self = this;
        $("td>span#status", row).unbind("click");
        $("td>span#status", row).bind("click", () => {
          this.pais = data;
          this.status();
        });
        $("td>span#editar", row).unbind("click");
        $("td>span#editar", row).bind("click", () => {
          this.pais = data;
          this.setEdit(data);
        });
        return row;
      },
      infoCallback(settings, start, end, max, total, pre) {
        if (total == 0) {
          return "Sin registros disponibles";
        }
        return (
          "Mostrando registros del " +
          start +
          " al " +
          end +
          " de un total de " +
          total +
          " registros"
        );
      },
    };
  }

  get form() {
    return this.saveForm.controls;
  }

  get editform() {
    return this.editForm.controls;
  }

  validLogin() {
    this.titleService.setTitle("Paises CCO - Organic Nails");
    this.route.params.forEach((params: Params) => {
      const parametro = localStorage.getItem("token");
      this.identificador = parametro;
      this.loginService.valDistribuidor(parametro).subscribe(
        (result) => {
          if (result == "false") {
            this.router.navigate(["/ingreso"]);
          }
        },
        (error) => {
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
    });
  }

  initForm() {
    this.saveForm = this.formBuilder.group({
      nombre: ["", Validators.required],
      abreviatura: ["", Validators.required],
      imagen_bandera: ["", Validators.required],
    });
  }

  initEdit() {
    this.editForm = this.formBuilder.group({
      nombre: ["", Validators.required],
      abreviatura: ["", Validators.required],
      imagen_bandera: [""],
      id: ["", Validators.required],
    });
  }

  onFileChange(e) {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      this.saveForm.patchValue({
        imagen_bandera: file,
      });
    }
  }

  onFileChangeEdit(e) {
    if (e.target.files.length > 0) {
      const file = e.target.files[0];
      this.editForm.patchValue({
        imagen_bandera: file,
      });
    }
  }

  setEdit(data) {
    console.log(data.id);
    this.editForm.patchValue({
      nombre: data.nombre,
    });
    this.editForm.patchValue({
      abreviatura: data.abreviatura,
    });
    this.editForm.patchValue({
      id: parseInt(data.id),
    });
  }

  edit() {
    this.submitted = true;
    const formEdit = new FormData();
    const image = this.editForm.get("imagen_bandera").value;
    formEdit.append(
      "imagen_bandera",
      this.editForm.get("imagen_bandera").value
    );
    formEdit.append("nombre", this.editForm.get("nombre").value);
    formEdit.append("abreviatura", this.editForm.get("abreviatura").value);
    formEdit.append("identificador", this.identificador);
    formEdit.append("id", this.editForm.get('id').value);
    if (this.editForm.invalid) {
      return;
    }
    this.paisesService.editPaisCCO(formEdit).subscribe(
      (result) => {
        this.submitted = false;
        this.notifier.notify(
          "success",
          "¡Exito! La operación se realizo exitosamente"
        );
        this.recargarTable();
        window.location.reload();
      },
      (error) => {
        this.notifier.notify(
          "error",
          "¡Error! La operación no se realizo exitosamente"
        );
        const errorMessage = error as any;
        console.log(errorMessage);
      }
    );
  }

  save() {
    this.submitted = true;
    const formData = new FormData();
    formData.append(
      "imagen_bandera",
      this.saveForm.get("imagen_bandera").value
    );
    formData.append("nombre", this.saveForm.get("nombre").value);
    formData.append("abreviatura", this.saveForm.get("abreviatura").value);
    formData.append("identificador", this.identificador);
    if (this.saveForm.invalid) {
      return;
    }
    this.paisesService.savePaisCCO(formData).subscribe(
      (result) => {
        this.submitted = false;
        this.notifier.notify(
          "success",
          "¡Exito! La operación se realizo exitosamente"
        );
        this.recargarTable();
        window.location.reload();
      },
      (error) => {
        this.notifier.notify(
          "error",
          "¡Error! La operación no se realizo exitosamente"
        );
        const errorMessage = error as any;
        console.log(errorMessage);
      }
    );
  }

  recargarTable() {
    $("#tablePaises").DataTable().ajax.reload();
  }

  status() {
    if (this.pais.status == "1") {
      this.paisesService.desactive(this.pais.id).subscribe(
        (result) => {
          if (result) {
            this.pais = {};
            this.notifier.notify(
              "success",
              "¡Exito! La operación se realizo exitosamente"
            );
            this.recargarTable();
          }
        },
        (error) => {
          this.notifier.notify(
            "error",
            "¡Error! La operación no se realizo exitosamente"
          );
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
    } else {
      this.paisesService.active(this.pais.id).subscribe(
        (result) => {
          if (result) {
            this.pais = {};
            this.notifier.notify(
              "success",
              "¡Exito! La operación se realizo exitosamente"
            );
            this.recargarTable();
          }
        },
        (error) => {
          this.notifier.notify(
            "error",
            "¡Error! La operación no se realizo exitosamente"
          );
          const errorMessage = error as any;
          console.log(errorMessage);
        }
      );
    }
  }
}
