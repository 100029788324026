import { PipeTransform, Pipe } from '@angular/core';

@Pipe({name: 'pais'})
export class PaisPipe implements PipeTransform {
  transform(value) {
    const dom = value.split(', ');
    let conjunto = '';
    if ( dom[dom.length - 2] === undefined){
      conjunto = dom[dom.length - 1];
    } else {
      conjunto = dom[dom.length - 2] + ', ' + dom[dom.length - 1];
    }
    return conjunto;
  }
}
