import { Component, TemplateRef  } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { NotifierService } from 'angular-notifier';

import { LoginService } from '../services/login.service';
import { EventosService } from '../services/eventos.service';

import { Evento } from '../models/evento';
import { AppGlobals } from '../app.global';

@Component({
  selector: 'eventosAdmin',
  templateUrl: './eventosAdmin.component.html',
  styleUrls: ['./eventosAdmin.component.css'],
  providers: [LoginService, Md5, EventosService]

})
export class EventosAdminComponent {

	public dtOptions: any = {};
	public baseURL = AppGlobals.baseURL;
	public identificador;
	public evento: Evento;
	public base = AppGlobals.url;
	private readonly notifier: NotifierService;

	constructor(
		private _loginService: LoginService,
		private _route: ActivatedRoute,
		private _router: Router,
		private titleService: Title,
		private _eventosService: EventosService,
		notifierService: NotifierService
	) {
		this.evento = new Evento('', '', '', '', '', '', '', '', '', '', '', '', '', '', '');
		this.notifier = notifierService;
	}

	ngOnInit() {
		this.titleService.setTitle('Eventos - Organic Nails');
		this._route.params.forEach((params: Params) => {
			const parametro = localStorage.getItem('token');
			this.identificador = parametro;
			this._loginService.validate(parametro).subscribe(
				result => {
					if (result === 'false') {
						this._router.navigate(['/login']);
					}
				},
				error => {
					const errorMessage = error as any;
					console.log(errorMessage);
				}
			);
		});
		this.dtOptions = {
			ajax: this.baseURL + '/Evento/getEventosCMS',
	        columns: [
	        	{ title: 'Título', data: 'titulo' },
	        	{ title: 'Temas', data: 'tema' },
	        	{
					title: 'Lugar',
					data: 'id',
					mRender(data, type, full) {
							return full.estado + ', ' + full.pais;
					}
				},
				{
					title: 'Fecha',
					data: 'id',
					mRender(data, type, full) {
						const mes = parseInt(full.mes, 10) + 1;
						if (full.dia < 10) {
							full.dia = '0' + full.dia;
						}
						if (full.mes < 10) {
							return full.dia + '/0' + String(mes) + '/' + full.anio;
						} else {
							return full.dia + '/' + full.mes + '/' + full.anio;
						}
					}
				},
				{
					title: 'Estatus',
					data: 'status',
					mRender(data, type, full) {
						if (data == 1) {
								return '<span id="status"><i class="far fa-check-circle"></i></span>';
						} else {
							return '<span id="status"><i class="far fa-circle"></i></span>';
						}
					}
				},
				{
					title: 'Acciones',
					data: 'id',
					bSortable: false,
					mRender(data, type, full) {
						return '<span class="accion" id="editar"><i class="fa fa-pencil-alt"></i></span> <span class="accion" id="eliminar" data-toggle="modal" data-target="#modalDelete"><i class="fa fa-trash-alt"></i></span>';
					}
				}
	        ],
	        pagingType: 'simple',
			stateSave: true,
	        language: {
		        'sProcessing':    'Procesando...',
		        'sLengthMenu':    'Mostrar _MENU_ registros',
		        'sZeroRecords':   'No se encontraron resultados',
		        'sEmptyTable':    'Ningún dato disponible en esta tabla',
		        'sInfo':          'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
		        'sInfoEmpty':     'Mostrando registros del 0 al 0 de un total de 0 registros',
		        'sInfoFiltered':  '(filtrado de un total de _MAX_ registros)',
		        'sInfoPostFix':   '',
		        'sSearch':        'Buscar:',
		        'sUrl':           '',
		        'sInfoThousands':  ',',
		        'sLoadingRecords': 'Cargando...',
		        'oPaginate': {
		            'sFirst':    'Primero',
		            'sLast':    'Último',
		            'sNext': '<i class=\'fa  fa-arrow-right\'></i>',
					sPrevious: '<i class=\'fa fa-arrow-left\'></i>'
		        },
		        'oAria': {
		            'sSortAscending':  ': Activar para ordenar la columna de manera ascendente',
		            'sSortDescending': ': Activar para ordenar la columna de manera descendente'
		        }
		    },
		    rowCallback: (row: Node, data, index: number) => {
		        const self = this;
		        $('td>span#status', row).unbind('click');
				      $('td>span#status', row).bind('click', () => {
					this.evento = data;
					this.status();
				});
				      $('td>span#editar', row).unbind('click');
				      $('td>span#editar', row).bind('click', () => {
						localStorage.setItem('element', data.id);
						this._router.navigate(['/eventosEdit']);
				});
				      $('td>span#eliminar', row).unbind('click');
				      $('td>span#eliminar', row).bind('click', () => {
					this.evento = data;
				});
		        return row;
		    },
		    infoCallback( settings, start, end, max, total, pre ) {
		       if (total == 0) { return 'Sin registros disponibles'; }
		       return 'Mostrando registros del ' + start + ' al ' + end + ' de un total de ' + total + ' registros';
		    }
	    };
	}

	resetEvento() {
		this.evento = new Evento('', '', '', '', '', '', '', '', '', '', '', '', '', '', '');
	}
	recargarTable() {
		$('#tableEventos').DataTable().ajax.reload();
	}
	status() {
		if (this.evento.status === '1') {
			console.log(this.evento);
			this._eventosService.desactive(this.evento.id).subscribe(
				result => {
					if (result) {
						this.resetEvento();
						this.recargarTable();
					}
				},
				error => {
					const errorMessage = error as any;
					console.log(errorMessage);
				}
			);
		} else {
			this._eventosService.active(this.evento.id).subscribe(
				result => {
					if (result) {
						this.resetEvento();
						this.recargarTable();
					}
				},
				error => {
					const errorMessage = error as any;
					console.log(errorMessage);
				}
			);
		}
	}

	delete() {
		this._eventosService.deleteEvento(this.evento.id).subscribe(
			result => {
				if (result) {
					this.notifier.notify( 'success', '¡Exito! La operación se realizo exitosamente' );
					this.resetEvento();
					this.recargarTable();
				}
			},
			error => {
				this.notifier.notify( 'error', '¡Error! La operación no se realizo exitosamente' );
				const errorMessage = error as any;
				console.log(errorMessage);
			}
		);
	}
}
