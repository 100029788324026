import { Component, TemplateRef, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { NotifierService } from 'angular-notifier';

import { AppGlobals } from '../app.global';

import { LoginService } from '../services/login.service';
import { DocumentacionService } from '../services/documentacion.service';

import { Documentacion } from '../models/documentacion';

@Component({
	selector: 'documentacionAdmin',
	templateUrl: './documentacionAdmin.component.html',
	styleUrls: ['./documentacionAdmin.component.css'],
	providers: [LoginService, Md5, DocumentacionService]

})

export class DocumentacionAdminComponent {

	@ViewChild('myInput', { static: true }) static; 
	public dtOptions: any = {};
	public baseURL = AppGlobals.baseURL;
	public identificador;
	public base = AppGlobals.url;
	public documentacion: Documentacion;
	public formData = new FormData();
	private readonly notifier: NotifierService;

	constructor(
		private loginService: LoginService,
		private documentacionService: DocumentacionService,
		private route: ActivatedRoute,
		private router: Router,
		private titleService: Title,
		notifierService: NotifierService
	) {
		this.documentacion = new Documentacion('', '', '', '', '', '', '', '', '', '');
		this.notifier = notifierService;
	}

	ngOnInit() {
		this.titleService.setTitle('Documentación distribuidores - Organic Nails');
		this.route.params.forEach((params: Params) => {
			const parametro = localStorage.getItem('token');
			this.identificador = parametro;
			this.loginService.validate(parametro).subscribe(
				result => {
					if (result == 'false') {
						this.router.navigate(['/login']);
					}
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
		});
		this.dtOptions = {
			ajax: this.baseURL + '/Documentos_distribuidores/getDocumentosCMS',
			columns: [
				{ title: 'Nombre', data: 'nombre' },
				{
					title: 'Documento',
					data: 'archivo',
					mRender(data, type, full) {
						return '<a class="accion" href="' + data + '" target="_blank"><i class="fas fa-download"></i></a>';
					}
				},
				{
					title: 'Estatus',
					data: 'status',
					mRender(data, type, full) {
						if (data == 1) {
							return '<span id="status"><i class="far fa-check-circle"></i></span>';
						} else {
							return '<span id="status"><i class="far fa-circle"></i></span>';
						}
					}
				},
				{
					title: 'Acciones',
					data: 'id',
					bSortable: false,
					mRender(data, type, full) {
						return '<span class="accion" id="editar" data-toggle="modal" data-target="#modalEditar"><i class="fa fa-pencil-alt"></i></span> <span class="accion" id="eliminar" data-toggle="modal" data-target="#modalDelete"><i class="fa fa-trash-alt"></i></span>';
					}
				}
			],
			pagingType: 'simple',
			stateSave: true,
			language: {
				sProcessing: 'Procesando...',
				sLengthMenu: 'Mostrar _MENU_ registros',
				sZeroRecords: 'No se encontraron resultados',
				sEmptyTable: 'Ningún dato disponible en esta tabla',
				sInfo: 'Mostrando registros del _START_ al _END_ de un total de _TOTAL_ registros',
				sInfoEmpty: 'Mostrando registros del 0 al 0 de un total de 0 registros',
				sInfoFiltered: '(filtrado de un total de _MAX_ registros)',
				sInfoPostFix: '',
				sSearch: 'Buscar:',
				sUrl: '',
				sInfoThousands: ',',
				sLoadingRecords: 'Cargando...',
				oPaginate: {
					sFirst: 'Primero',
					sLast: 'Último',
					sNext: '<i class=\'fa  fa-arrow-right\'></i>',
					sPrevious: '<i class=\'fa fa-arrow-left\'></i>'
				},
				oAria: {
					sSortAscending: ': Activar para ordenar la columna de manera ascendente',
					sSortDescending: ': Activar para ordenar la columna de manera descendente'
				}
			},
			rowCallback: (row: Node, data, index: number) => {
				const self = this;
				$('td>span#status', row).unbind('click');
				$('td>span#status', row).bind('click', () => {
					this.documentacion = data;
					this.status();
				});
				$('td>span#editar', row).unbind('click');
				$('td>span#editar', row).bind('click', () => {
					this.documentacion = data;
					this.documentacion.documento = data.archivo;
				});
				$('td>span#eliminar', row).unbind('click');
				$('td>span#eliminar', row).bind('click', () => {
					this.documentacion = data;
				});
				return row;
			},
			infoCallback(settings, start, end, max, total, pre) {
				if (total == 0) { return 'Sin registros disponibles'; }
				return 'Mostrando registros del ' + start + ' al ' + end + ' de un total de ' + total + ' registros';
			}
		};
	}
	resetDocumentacion() {
		this.documentacion = new Documentacion('', '', '', '', '', '', '', '', '', '');
	}
	recargarTable() {
		$('#tableDocs').DataTable().ajax.reload();
	}
	onChangeDocumento(event) {
		const elem = event.target;
		if (elem.files.length > 0) {
			this.formData.append('documento', elem.files[0]);
			this.documentacion.documento = elem.files[0];
		}
	}
	save() {
		this.formData.append('identificador', this.identificador);
		this.formData.append('nombre', this.documentacion.nombre);
		this.formData.append('id_tipo_documento', '1');
		this.documentacionService.save(this.formData).subscribe(
			result => {
				if (result) {
					this.notifier.notify('success', '¡Exito! La operación se realizo exitosamente');
					this.resetDocumentacion();
					this.recargarTable();
				} else {
					this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				}
			},
			error => {
				this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				const errorMessage =  error as any;
				console.log(errorMessage);
			}
		);
	}
	editar() {
		this.formData.append('identificador', this.identificador);
		this.formData.append('nombre', this.documentacion.nombre);
		this.formData.append('id', this.documentacion.id);
		this.formData.append('id_tipo_documento', '1');
		this.documentacionService.editar(this.formData).subscribe(
			result => {
				if (result) {
					this.notifier.notify('success', '¡Exito! La operación se realizo exitosamente');
					this.resetDocumentacion();
					this.recargarTable();
				} else {
					this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				}
			},
			error => {
				this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				const errorMessage =  error as any;
				console.log(errorMessage);
			}
		);
	}
	delete() {
		this.documentacionService.delete(this.documentacion.id).subscribe(
			result => {
				if (result) {
					this.notifier.notify('success', '¡Exito! La operación se realizo exitosamente');
					this.resetDocumentacion();
					this.recargarTable();
				} else {
					this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				}
			},
			error => {
				this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				const errorMessage =  error as any;
				console.log(errorMessage);
			}
		);
	}

	status() {
		if (this.documentacion.status == 1) {
			this.documentacionService.desactive(this.documentacion.id).subscribe(
				result => {
					if (result) {
						this.resetDocumentacion();
						this.recargarTable();
					}
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
		} else {
			this.documentacionService.active(this.documentacion.id).subscribe(
				result => {
					if (result) {
						this.resetDocumentacion();
						this.recargarTable();
					}
				},
				error => {
					const errorMessage =  error as any;
					console.log(errorMessage);
				}
			);
		}
	}
}
