import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Md5 } from 'ts-md5/dist/md5';
import { NotifierService } from 'angular-notifier';

import { LoginService } from '../services/login.service';

import { adminDistribuidor } from '../models/adminDistribuidor';
import { RestartPass } from '../models/restartPass';


@Component({
	selector: 'loginDistribuidores',
	templateUrl: './loginDistribuidores.component.html',
	styleUrls: ['./loginDistribuidores.component.css'],
	providers: [LoginService, Md5]
})
export class LoginDistibuidoresComponent {

	public patternEmail = '/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/';
	public user: adminDistribuidor;
	public restart: RestartPass;
	private readonly notifier: NotifierService;
	public emailLost;

	constructor(
		private loginService: LoginService,
		private route: ActivatedRoute,
		private router: Router,
		private titleService: Title,
		private md5: Md5,
		notifierService: NotifierService
	) {
		this.user = new adminDistribuidor('', '');
		this.restart = new RestartPass('', '', '', '');
		this.notifier = notifierService;
	}

	ngOnInit() {
		this.titleService.setTitle('Login - Organic Nails');
	}

	LogIn() {
		const hash = String(Md5.hashStr(this.user.codigo));
		this.loginService.loginDistribuidor(this.user).subscribe(
			result => {
				if (result) {
					if (result != 'not-found') {
						localStorage.setItem('token', hash);
						this.router.navigate(['/adminDistribuidor']);
					} else {
					}
				}
			},
			error => {
				const errorMessage =  error as any;
				console.log(errorMessage);
			}
		);
	}
	resetRestart() {
		this.restart = new RestartPass('', '', '', '');
	}
	enviar() {
		this.loginService.olvide(this.restart.codigo).subscribe(
			result => {
				this.resetRestart();
				this.notifier.notify('success', '¡Exito! La operación se realizo exitosamente');
			},
			error => {
				this.notifier.notify('error', '¡Error! La operación no se realizo exitosamente');
				const errorMessage =  error as any;
				console.log(errorMessage);
			}
		);
	}

}
