import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppGlobals } from '../app.global';

@Injectable()
export class NewsletterService {
	public baseUrl: string;
	public urlSave: string;
	public urlDelete: string;
	public urlInactive: string;
	public urlActive: string;

	public headers = {
		headers: new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded',
		})
	};


	constructor(
		public http: HttpClient
	) {
		this.baseUrl = AppGlobals.baseURL;
		this.urlSave = 'Newsletter/addNewsletter';
		this.urlDelete = 'Newsletter/deleteNewsletter';
		this.urlInactive = 'Newsletter/inactiveNewsletter';
		this.urlActive = 'Newsletter/activeNewsletter';
	}

	save(newsletter) {
		const json = encodeURIComponent(JSON.stringify(newsletter));
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlSave, params, this.headers)
			.pipe(map(res => res));
	}
	deleteNewsletter(id) {
		const json = JSON.stringify(id);
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlDelete, params, this.headers)
			.pipe(map(res => res));
	}

	desactive(id) {
		const json = JSON.stringify(id);
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlInactive, params, this.headers)
			.pipe(map(res => res));
	}
	active(id) {
		const json = JSON.stringify(id);
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlActive, params, this.headers)
			.pipe(map(res => res));
	}

}
