export class Catalogo{
	constructor(
		public id,
		public id_usuario,
		public fecha_creacion,
		public fecha_edicion,
		public nombre,
		public img_desk,
		public img_movil,
		public img_tablet,
		public slogan,
		public info_imagen,
		public subtitulo,
		public info_subtitulo,
		public identificador,
		public color,
		public page_title,
		public meta_description,
		public status,
		public status_delete
	){}
}