import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { AppGlobals } from '../app.global';

@Injectable()
export class SubcategoriaService {
	public baseUrl: string;
	public urlSave: string;
	public urlGetSubategoria: string;
	public urlEdit: string;
	public urlInactive: string;
	public urlActive: string;
	public urlDelete: string;
	public urlGetSubcategorias: string;
	public urlOrdenamiento: string;

	public headers = {
		headers: new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded',
		})
	};

	constructor(
		public http: HttpClient
	) {
		this.baseUrl = AppGlobals.baseURL;
		this.urlSave = 'Subcategorias/addSubcategoria';
		this.urlGetSubategoria = 'Subcategorias/getSubcategoria';
		this.urlEdit = 'Subcategorias/editSubcategoria';
		this.urlActive = 'Subcategorias/activeSubcategoria';
		this.urlInactive = 'Subcategorias/inactiveSubcategoria';
		this.urlDelete = 'Subcategorias/deleteSubcategoria';
		this.urlGetSubcategorias = 'Producto/getSubcategoria';
		this.urlOrdenamiento = 'Subcategorias/updateOrder';
	}

	save(subcategoria) {
		const json = encodeURIComponent(JSON.stringify(subcategoria));
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlSave, params, this.headers)
			.pipe(map(res => res));
	}

	getSubcategoria(subcategoria) {
		const json = encodeURIComponent(JSON.stringify(subcategoria));
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlGetSubategoria, params, this.headers)
			.pipe(map(res => res));
	}

	edit(subcategoria) {
		const json = encodeURIComponent(JSON.stringify(subcategoria));
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlEdit, params, this.headers)
			.pipe(map(res => res));
	}

	ordenamiento(subcategoria) {
		const json = encodeURIComponent(JSON.stringify(subcategoria));
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlOrdenamiento, params, this.headers)
			.pipe(map(res => res));
	}

	desactive(id) {
		const json = JSON.stringify(id);
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlInactive, params, this.headers)
			.pipe(map(res => res));
	}

	active(id) {
		const json = JSON.stringify(id);
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlActive, params, this.headers)
			.pipe(map(res => res));
	}

	delete(subcategoria) {
		const json = encodeURIComponent(JSON.stringify(subcategoria));
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlDelete, params, this.headers)
			.pipe(map(res => res));
	}

	getSubcategorias(idCategoria) {
		const json = JSON.stringify(idCategoria);
		const params = 'json=' + json;

		return this.http.post(this.baseUrl + this.urlGetSubcategorias, params, this.headers)
			.pipe(map(res => res));
	}

}
