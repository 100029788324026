<div class="row">
	<div class="col-md-12 col-lg-2 offset-lg-5 text-center">
		<div class="form-group">
			<!--<mat-form-field>
			    <input matInput [min]="minDate" [matDatepicker]="dp3" disabled>
			    <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
			    <mat-datepicker #dp3 disabled="false"></mat-datepicker>
			</mat-form-field>-->
			<mat-form-field>
			  	<input matInput [matDatepicker]="dp3" placeholder="Selecciona un mes" disabled>
			  	<mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
			  	<mat-datepicker #dp3
			                  startView="multi-year"
			                  (yearSelected)="chosenYearHandler($event)"
			                  (monthSelected)="chosenMonthHandler($event, dp3)"
			                  panelClass="example-month-picker"
			                  disabled="false">
			  </mat-datepicker>
			</mat-form-field>
		</div>
	</div>
</div>
<div class="row esp rowSiguientes">
	<div class="col-lg-2 col-md-12">
		<p class="prev" (click)="prevMonth();">
			<i class="fas fa-angle-left fa-2x"></i> 
			<span>{{ prev | meses }} {{ prevYear }}</span>
		</p>
	</div>
	<div class="col-md-12 col-lg-6 offset-lg-1 text-center">
		<h3 class="mesActual">{{mes | meses}} {{ year }}</h3>
	</div>
	<div class="col-lg-2 col-md-12 offset-lg-1">
		<p class="next" (click)="nextMonth();">
			<span>{{ next | meses }} {{ nextYear }}</span> 
			<i class="fas fa-angle-right fa-2x"></i>
		</p>
	</div>
</div>
<div class="row" *ngIf="eventosAll.length > 0">
	<div class="text-center divEvento" *ngFor="let evento of eventosAll">
		<img class="imgCalendario" src="../../assets/calendar.png" alt="">
		<p class="mes">{{ evento.mes | meses }}</p>
		<h1 class="dia">{{evento.dia}}</h1>
		<p class="lugar">{{evento.titulo}}</p>
		<h3 class="estado">{{evento.estado}}</h3>
		<p class="municipio">{{evento.pais}}</p>
		<p class="temas">TEMAS:</p>
		<p class="tema">{{evento.tema}}</p>
		<a target="_blank" href="{{evento.url}}" class="btn btnEvento">Ir al evento</a>
	</div>
</div>

<div class="row" *ngIf="eventosAll.length == 0">
	<div class="col-lg-12 text-center">
		<h1 class="titulo">No hay eventos disponibles este mes.</h1>
	</div>
</div>