import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppGlobals } from '../app.global';

@Injectable()
export class CatalogoService {
	public baseUrl: string;
	public urlGetCatalogo: string;
	public urlEdit: string;
	public urlGetCatalogos: string;
	public headers = {
		headers: new HttpHeaders({
			'Content-Type': 'application/x-www-form-urlencoded',
		})
	};

	constructor(
		public http: HttpClient
	) {
		this.baseUrl = AppGlobals.baseURL;
		this.urlGetCatalogo = 'Catalogos/getCatalogo';
		this.urlEdit = 'Catalogos/editCatalogo';
		this.urlGetCatalogos = 'Catalogos/getCatalogosFront';
	}


	edit(banner) {
		const json = encodeURIComponent(JSON.stringify(banner));
		const params = 'json=' + json;
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.post(this.baseUrl + this.urlEdit, banner/*, {headers: headers}*/)
			.pipe(map(res => res));
	}
	getCatalogoEdit(id) {
		const json = JSON.stringify(id);
		const params = 'json=' + json;
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.post(this.baseUrl + this.urlGetCatalogo, params, this.headers)
			.pipe(map(res => res));
	}
	getCatalogos() {
		const headers = new Headers({ 'Content-Type': 'application/x-www-form-urlencoded' });

		return this.http.get(this.baseUrl + this.urlGetCatalogos, this.headers)
			.pipe(map(res => res));
	}
}

