<admin-header></admin-header>
<admin-menu></admin-menu>

<notifier-container></notifier-container>

<div class="content-wrapper">
	<div class="content-header">
	  <h1>
	    Editar Promasters
	    <small>Control panel</small>
	  </h1>
	  <ol class="breadcrumb">
	    <li><a [routerLink]="['/admin']"><i class="fa fa-home"></i> Home</a></li>
	    <li class=""><a  [routerLink]="['/adminpromaster']">Promasters</a></li>
	    <li class="">Editar Promasters</li>
	  </ol>
	</div>
	<section class="content">
		<form #formAdd="ngForm">
			<div class="row">
				<div class="col-lg-6">
					<div class="form-group">
					    <label for="exampleInputEmail1">Miniatura <span class="obligatorio">*</span></label>
					    <input type="file" accept="image/png, image/jpeg, image/jpg" class="form-control" name="fotografia_principal" required (change)="onChange($event)">
					    <div *ngIf="bandMiniatura">
							<img src="{{promaster.fotografia_principal}}" style="width: 20%;">
						</div>
					</div>
					<div class="form-group">
					    <label for="exampleInputEmail1">Banner <span class="obligatorio">*</span></label>
					    <input type="file" accept="image/png, image/jpeg, image/jpg" class="form-control" name="imagen_banner" required (change)="onChangeBanner($event)">
					    <div *ngIf="bandBanner">
							<img src="{{promaster.imagen_banner}}" style="width: 20%;">
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
					    <label for="nombre">Nombre <span class="obligatorio">*</span></label><span class="Error" *ngIf="nombre.touched && !nombre.valid">Error en el campo</span>
					    <input type="email" class="form-control" placeholder="Nombre" name="nombre" #nombre="ngModel" [(ngModel)]="promaster.nombre" required>
					</div>
					<div class="form-group">
					    <label for="identificador_promaster">URL <span class="obligatorio">*</span></label><span class="Error" *ngIf="identificador_promaster.touched && !identificador_promaster.valid">Error en el campo</span>
					    <input type="text" class="form-control" placeholder="URL" name="identificador_promaster" #identificador_promaster="ngModel" [(ngModel)]="promaster.identificador_promaster" required>
					</div>
				</div>
			</div>
			<div class="row esp">
				<div class="col-lg-6">
					<div class="form-group">
					    <label for="nombre">Puesto <span class="obligatorio">*</span></label><span class="Error" *ngIf="puesto.touched && !puesto.valid">Error en el campo</span>
					    <input class="form-control" placeholder="Puesto" name="puesto" #puesto="ngModel" [(ngModel)]="promaster.puesto" required > 
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
					    <label for="nombre">Tipo <span class="obligatorio">*</span></label><span class="Error" *ngIf="id_tipo.touched && !id_tipo.valid">Error en el campo</span>
					    <select class="form-control" name="id_tipo" #id_tipo="ngModel" [(ngModel)]="promaster.id_tipo" required >
					    	<option value="0">Selecciona una opción</option>
					    	<option value="1">Promaster</option>
					    	<option value="2">Proeducator</option>
					    </select> 
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
					    <label for="nombre">Descripción del puesto <span class="obligatorio">*</span></label><span class="Error" *ngIf="descripcion_corta.touched && !descripcion_corta.valid">Error en el campo</span>
					    <textarea autosize class="form-control" placeholder="Descripción del puesto" name="descripcion_corta" #descripcion_corta="ngModel" [(ngModel)]="promaster.descripcion_corta" required ></textarea>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
					    <label for="nombre">Título Frase <span class="obligatorio">*</span></label><span class="Error" *ngIf="frase.touched && !frase.valid">Error en el campo</span>
					    <input class="form-control" name="frase" #frase="ngModel" [(ngModel)]="promaster.frase" required placeholder="Título frase">
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
					    <label for="detalle_frase">Frase representativa <span class="obligatorio">*</span></label><span class="Error" *ngIf="detalle_frase.touched && !detalle_frase.valid">Error en el campo</span>
					    <textarea autosize class="form-control" name="detalle_frase" #detalle_frase="ngModel" [(ngModel)]="promaster.detalle_frase" required placeholder="Frase representativa"></textarea>
					</div>
				</div>
			</div>
			<div class="row esp">
				<div class="col-lg-6">
					<div class="form-group">
					    <label for="nombre">Facebook</label>
					    <input type="email" class="form-control" placeholder="Facebook" name="facebook" #facebook="ngModel" [(ngModel)]="promaster.facebook">
					</div>
					<div class="form-group">
					    <label for="pinterest">Pinterest</label>
					    <input type="email" class="form-control" placeholder="Pinterest" name="pinterest" #pinterest="ngModel" [(ngModel)]="promaster.pinterest">
					</div>
				</div>
				<div class="col-lg-6">
					<div class="form-group">
					    <label for="instagram">Instagram</label>
					    <input type="email" class="form-control" placeholder="Instagram" name="instagram" #instagram="ngModel" [(ngModel)]="promaster.instagram">
					</div>
				</div>
			</div>
			<div class="row esp">
				<div class="col-lg-4">
					<div class="form-group">
					    <label for="detalle_frase">Imagen Biografia<span class="obligatorio">*</span></label>
					    <input type="file" accept="image/png, image/jpeg, image/jpg" class="form-control" name="fotografia_biografia" required (change)="onChangeBio($event)">
					    <div *ngIf="bandBio">
							<img src="{{promaster.fotografia_biografia}}" style="width: 100%;">
						</div>
					</div>
				</div>
				<div class="col-lg-8">
					<label for="detalle_frase">Biografia<span class="obligatorio">*</span></label><span class="Error" *ngIf="biografia.touched && !biografia.valid">Error en el campo</span>
					<ck-editor name="biografia" #biografia="ngModel" [(ngModel)]="promaster.biografia" [config]="config" skin="moono-lisa" language="es" [fullPage]="true" ></ck-editor>
				</div>
			</div>
			<!--SEO-->
			<div class="row espForms">
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Page Title <span class="obligatorio">*</span></label><span class="Error" *ngIf="page_title.touched && !page_title.valid">Error en el campo</span>
					    <input type="text" class="form-control" placeholder="Page Title" name="page_title" #page_title="ngModel" [(ngModel)]="promaster.page_title" required>
					</div>
				</div>
				<div class="col-md-3">
					<div class="form-group">
					    <label for="nombre">Meta description <span class="obligatorio">*</span></label><span class="Error" *ngIf="meta_description.touched && !meta_description.valid">Error en el campo</span>
					    <input type="text" class="form-control" placeholder="Meta description" name="meta_description" #meta_description="ngModel" [(ngModel)]="promaster.meta_description" required>
					</div>
				</div>
			</div>
			<div class="row esp">
				<div class="col lg-12">
					<h3>Videos</h3>
					<img class="addVideo"  src="../../assets/img/circle-add.svg" alt="" mdbTooltip="Agregar nuevo registro" data-toggle="modal" data-target="#modalVideo">
				</div>
			</div>
			<div class="row esp tabVideos">
				<div class="col-lg-3">
					<p><b>Nombre</b></p>
				</div>
				<div class="col-lg-3">
					<p><b>Descripción</b></p>
				</div>
				<div class="col-lg-3">
					<p><b>URL</b></p>
				</div>
			</div>
			<div class="row esp" *ngFor="let video of promaster.videos; index as i">
				<div class="col-lg-3">
					<p>{{video.nombre}}</p>
				</div>
				<div class="col-lg-3">
					<p>{{video.descripcion}}</p>
				</div>
				<div class="col-lg-3">
					<p><a href="{{video.url}}">{{video.url}}</a></p>
				</div>
				<div class="col-lg-3">
					<p (click)="removeVideo(i,video.id);"><span class="accion"><i class="fa fa-trash-alt"></i></span></p>
				</div>
			</div>
			<div class="row esp">
				<div class="col-lg-12">
					<button type="submit" class="btn btnOrganicBlack" data-dismiss="modal" (click)="savePromaster();" [disabled]="!formAdd.form.valid" >Guardar</button>
				</div>
			</div>
		</form>
	</section>
</div>


<div class="modal fade" id="modalAgregarImg" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Subir imagen promaster</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				  <span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row">
			    	<div class="col-lg-12 col-xs-12">
			    		<div class="form-group">
						    <label for="exampleInputEmail1">Imagen promaster <span class="obligatorio">*</span></label>
						    <br>
						    <input type="file" accept="image/png, image/jpeg, image/jpg" class="form-control" name="fotografia_principal" required (change)="onChange($event)">
						</div>
			    	</div>
			    </div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn btnOrganicBlack" data-dismiss="modal">Guardar</button>
			</div>
        </div>
    </div>
</div>

<div class="modal fade" id="modalVideo" tabindex="-1" role="dialog" aria-labelledby="" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog modal-md" role="document">
        <div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title">Agregar videos</h5>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				  <span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<div class="row">
			    	<div class="col-lg-12 col-xs-12">
			    		<div class="form-group">
						    <label for="nombre">Nombre <span class="obligatorio">*</span></label>
						    <br>
						    <input type="text" class="form-control" placeholder="Nombre" name="nombre" #nombre="ngModel" [(ngModel)]="video.nombre">
						</div>
			    	</div>
			    </div>
			    <div class="row">
			    	<div class="col-lg-12 col-xs-12">
			    		<div class="form-group">
						    <label for="url">URL (youtube)<span class="obligatorio">*</span></label>
						    <br>
						    <input type="text" class="form-control" placeholder="URL" name="url" #url="ngModel" [(ngModel)]="video.url">
						</div>
			    	</div>
			    </div>
			    <div class="row">
			    	<div class="col-lg-12 col-xs-12">
			    		<div class="form-group">
						    <label for="descripcion">Descripción <span class="obligatorio">*</span></label>
						    <br>
						    <textarea type="text" class="form-control" placeholder="Descripción" name="descripcion" #descripcion="ngModel" [(ngModel)]="video.descripcion"></textarea>
						</div>
			    	</div>
			    </div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="btn btnOrganicBlack" data-dismiss="modal" (click)="addVideo();">Guardar</button>
			</div>
        </div>
    </div>
</div>