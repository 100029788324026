import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { map } from "rxjs/operators";
import { AppGlobals } from "../app.global";

@Injectable()
export class PaisesService {
  public baseUrl: string;
  public urlGetPaises: string;

  public headers = {
    headers: new HttpHeaders({
      "Content-Type": "application/x-www-form-urlencoded",
    }),
  };

  constructor(public http: HttpClient) {
    this.baseUrl = AppGlobals.baseURL;
    this.urlGetPaises = "Paises/getPaises";
  }

  getPaises() {
    return this.http
      .get(this.baseUrl + this.urlGetPaises, this.headers)
      .pipe(map((res) => res));
  }

  savePaisCCO(data) {
    const json = encodeURIComponent(JSON.stringify(data));
		const params = 'json=' + data;

    return this.http
      .post(this.baseUrl + "Paises/addPais", data)
      .pipe(map((res) => res));
  }

  editPaisCCO(data) {
    return this.http
      .post(this.baseUrl + "Paises/editPais", data)
      .pipe(map((res) => res));
  }

  getPaisesActive() {
    return this.http
      .get(this.baseUrl + 'Paises/getPaisesActivos')
      .pipe(map((res) => res));
  }

  active(id) {
    const json = JSON.stringify(id);
    const params = 'json=' + json;
    return this.http
      .post(this.baseUrl + "Paises/activePais", params, this.headers)
      .pipe(map((res) => res));
  }

  desactive(id) {
    const json = JSON.stringify(id);
    const params = 'json=' + json;
    return this.http
      .post(this.baseUrl + "Paises/inactivePais", params, this.headers)
      .pipe(map((res) => res));
  }
}
